import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  createProjectData = new BehaviorSubject({});
  currentProjectId = new BehaviorSubject('');
  currentProjectData = new BehaviorSubject('');
  constructor(private http:HttpClient) { }

  getAllProjects(){
    return this.http.get(`${environment.apiUrl}/project`);
  }

  getAllProjectsOfUser(archive:boolean){
    return this.http.get(`${environment.apiUrl}/user/project?archive_status=${archive}`);
  }

  getProjectDetails(id:any){
    return this.http.get(`${environment.apiUrl}/project/${id}`);
  }

  getSearchResults(searchText: String, archiveStatus: boolean){
    return this.http.get(`${environment.apiUrl}/project/search?search=${searchText}&archive_status=${archiveStatus}`);
  }

  setFilterCriteria(projectId:any, data:any){
    return this.http.patch(`${environment.apiUrl}/project/${projectId}`, data);
  }

  createProject(data:any){
    return this.http.post(`${environment.apiUrl}/project`, data);
  }

  getProjectUsers(id:any){
    return this.http.get(`${environment.apiUrl}/project/userDetails/${id}`);
  }

  updateProjectDetails(data:any, id:any){
    return this.http.patch(`${environment.apiUrl}/project/${id}`, data);
  }

  updateUsers(data: any, id:any,){
    return this.http.patch(`${environment.apiUrl}/user/${id}`, data);
  }

  updateProjectUserRole(data: any, userId: any, projectId: any){
    return this.http.patch(`${environment.apiUrl}/project/userRole/${userId}/${projectId}`, data);
  }

  reopenProject(property:any){    
    return this.http.patch(`${environment.apiUrl}/properties/reopen`, {property_id : property.property_id});
  }

  archiveProject(data:any){
    return this.http.patch(`${environment.apiUrl}/project/archive`, data)
  }
}
