import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { QuestionnairesService } from './questionnaires.service';
@Injectable({
  providedIn: 'root'
})
export class CreateTemplateService {
  workbook = new Workbook();
  questionnaireData:any;
  questionnaireResidentialData:any
  tableDisplay:any = [];
  informationDisplay:any = [];
  tableDisplayResidential: any = [];
  constructor(private questionnaireService: QuestionnairesService) {}

  generateExcel() {    
    this.getAllQuestionnaires()
  }

  generateSheetQuestionnaire(data:any){
    const titles = ['Information to be provided by the user','Asset reference Name', 'Country', 'City', 'Zip Code', 'Address', 'Property Type',
    'Size of property (sq m)', 'Currency', 'Current market value'];
    const qHeaders = ["ID",	"Category",	"Question",	"Answers", "Marked", "Marked"];
    let worksheet2 = this.workbook.addWorksheet('Breeam-Commercial');
    titles.forEach((item:any, index:number)=>{
    worksheet2.getCell('A'+(index+1)).value = item;
    });

    titles.forEach((item:any, index:number)=>{
      if(index !== 0){
      worksheet2.getCell('E'+(index+1)).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'ffe5b7' },
          bgColor: { argb: '000000' }
        }
      worksheet2.getCell('F'+(index+1)).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'ffe5b7' },
          bgColor: { argb: '000000' }
        }
      }
    });
    worksheet2.getCell('E9').dataValidation = {
      type: 'list',
      allowBlank: true,
      formulae: ['"EUR,BGN,CHF,CZK,DKK,GBP,HRK,GEL,RUB,TRY,UAH,USD,INR,CNY,YEN"']
    };
    worksheet2.getCell('E8').dataValidation = {
      type: 'whole',
      showErrorMessage: true,
      formulae: [],
      errorStyle: 'error',
      errorTitle: 'Data Type',
      error: 'The value must be integer'
    };
    worksheet2.getCell('E10').dataValidation = {
      type: 'whole',
      showErrorMessage: true,
      formulae: [],
      errorStyle: 'error',
      errorTitle: 'Data Type',
      error: 'The value must be integer'
    };
    worksheet2.getCell('E1').value = 'Property 1';
    worksheet2.getCell('F1').value = 'Property 2';
    worksheet2.getCell('E7').value = 'Commercial';
    worksheet2.getCell('F7').value = 'Commercial';
    worksheet2.getCell('E11').value = 'Please mark the relevant answer with an "x" - please note that each question requires only one answer';
    worksheet2.getCell('F11').value = 'Please mark the relevant answer with an "x" - please note that each question requires only one answer';
    let titleRow = worksheet2.getRow(1);
    titleRow.font ={bold:true, color: { argb: 'FFA500' }}
    // worksheet2.addRow([])
    let headerRow = worksheet2.addRow(qHeaders);    
    headerRow.font= {bold : true}
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF' },
        bgColor: { argb: '000000' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    });
    worksheet2.getColumn(1).width = 10;
    worksheet2.getColumn(2).width = 20;
    worksheet2.getColumn(3).width = 30;
    worksheet2.getColumn(4).width = 30;
    worksheet2.getColumn(5).width = 30;
    worksheet2.getColumn(6).width = 30;
    worksheet2.getColumn(3).alignment = { wrapText: true };
    worksheet2.getColumn(4).alignment = { wrapText: true };
    worksheet2.getColumn(5).alignment = { wrapText: true };
    worksheet2.getColumn(6).alignment = { wrapText: true };
    let singleItem:any = [];
    data.forEach((cat:any)=>{
      cat.questions.forEach((question:any, index:number) => {
        question.answers.forEach((answer:any, index:number) => {
          if(index!==0)
            question.question_item = ''
          singleItem= [question.question_code, cat.category_name, question.question_item, answer.answer, ''];
          this.tableDisplay.push(singleItem)
        });
      });
    });
    let d1:number = 12;
    let d2:number;
    this.tableDisplay.forEach((d:any, index:number) => {
      

      let row = worksheet2.addRow(d);
      // if(d[2]){
      //   console.log(d[6].length, "lengthing")
      //   d2 = d1+(d[6].length-1)
      //   console.log("D1", d1, "D2", d2 )
      //   let finala = worksheet2.getCell('E'+(index+12)).dataValidation = {
      //     type: 'list',          
      //     formulae: ['$D$'+d1+':$D$'+d2]
      //   };
      //   d1 = d2 + 1;
      // }
    });
  }

  generateNextSheet(data:any){
    const titles = ['Information to be provided by the user','Asset reference Name', 'Country', 'City', 'Zip Code', 'Address', 'Property Type',
                'Size of property (sq m)', 'Currency', 'Current market value'];
    const qHeaders = ["ID",	"Category",	"Question",	"Answers", "Marked", "Marked"];
    let worksheet2 = this.workbook.addWorksheet('Breeam-Residential');
    titles.forEach((item:any, index:number)=>{
      worksheet2.getCell('A'+(index+1)).value = item;
    });

    titles.forEach((item:any, index:number)=>{
      if(index !== 0){
        worksheet2.getCell('E'+(index+1)).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'ffe5b7' },
          bgColor: { argb: '000000' }
        }
        worksheet2.getCell('F'+(index+1)).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'ffe5b7' },
          bgColor: { argb: '000000' }
        }
      }
    });

    let titleRow = worksheet2.getRow(1);
    titleRow.font ={bold:true, color: { argb: 'FFA500' }}
    worksheet2.addRow([])
    let infoItem = [];
    data.forEach((cat:any)=>{
      cat.questions.forEach((question:any, index:number) => {
        question.answers.forEach((answer:any, index:number) => {
          infoItem= [question.question_code, cat.category_name, question.question_item, answer.answer, ''];
          if(cat.category_name == 'Information')
            this.informationDisplay.push(infoItem)
        });
      });
    });
    this.informationDisplay.forEach((d:any, index:number) => {      
      let row = worksheet2.addRow(d);
    })
    worksheet2.getCell('E9').dataValidation = {
      type: 'list',
      allowBlank: true,
      formulae: ['"EUR,BGN,CHF,CZK,DKK,GBP,HRK,GEL,RUB,TRY,UAH,USD,INR,CNY,YEN"']
    };
    worksheet2.getCell('E8').dataValidation = {
      type: 'whole',
      showErrorMessage: true,
      formulae: [],
      errorStyle: 'error',
      errorTitle: 'Data Type',
      error: 'The value must be integer'
    };
    worksheet2.getCell('E10').dataValidation = {
      type: 'whole',
      showErrorMessage: true,
      formulae: [],
      errorStyle: 'error',
      errorTitle: 'Data Type',
      error: 'The value must be integer'
    };
    worksheet2.getCell('E1').value = 'Property 1';
    worksheet2.getCell('F1').value = 'Property 2';
    worksheet2.getCell('E7').value = 'Residential';
    worksheet2.getCell('F7').value = 'Residential';
    worksheet2.getCell('E25').value = 'Please mark the relevant answer with an "x" - please note that each question requires only one answer';
    worksheet2.getCell('F25').value = 'Please mark the relevant answer with an "x" - please note that each question requires only one answer';
    let headerRow = worksheet2.addRow(qHeaders);    
    headerRow.font= {bold : true}
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF' },
        bgColor: { argb: '000000' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    });
    worksheet2.getColumn(1).width = 10;
    worksheet2.getColumn(2).width = 20;
    worksheet2.getColumn(3).width = 30;
    worksheet2.getColumn(4).width = 30;
    worksheet2.getColumn(5).width = 30;
    worksheet2.getColumn(6).width = 30;
    worksheet2.getColumn(3).alignment = { wrapText: true };
    worksheet2.getColumn(4).alignment = { wrapText: true };
    worksheet2.getColumn(5).alignment = { wrapText: true };
    worksheet2.getColumn(6).alignment = { wrapText: true };
    let singleItem:any = [];
    data.forEach((cat:any)=>{
      cat.questions.forEach((question:any, index:number) => {
        question.answers.forEach((answer:any, index:number) => {
          if(index!==0)
            question.question_item = ''
          singleItem= [question.question_code, cat.category_name, question.question_item, answer.answer, ''];
          if(cat.category_name !== 'Information')
            this.tableDisplayResidential.push(singleItem)
        });
      });
    });
    let d1:number = 12;
    let d2:number;
    this.tableDisplayResidential.forEach((d:any, index:number) => {
      

      let row = worksheet2.addRow(d);
      // if(d[2]){
      //   console.log(d[6].length, "lengthing")
      //   d2 = d1+(d[6].length-1)
      //   console.log("D1", d1, "D2", d2 )
      //   let finala = worksheet2.getCell('E'+(index+12)).dataValidation = {
      //     type: 'list',          
      //     formulae: ['$D$'+d1+':$D$'+d2]
      //   };
      //   d1 = d2 + 1;
      // }
    });
    // const hiddenCol = worksheet2.getColumn('G');
    // hiddenCol.hidden = true;
    this.downloadTrigger();
  }

  downloadTrigger(){
    this.workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Template.xlsx');
    });
  }

  getAllQuestionnaires(){
    this.questionnaireService.getAllQuestionnaires("BREEAM", "Commercial",  false).subscribe((res:any) =>{  
      this.questionnaireData = res;
      this.generateSheetQuestionnaire(res)
      this.getResidentialQuestionnaire()
    })
  }

  getResidentialQuestionnaire(){
    this.questionnaireService.getAllQuestionnaires("BREEAM", "Residential",  false).subscribe((res:any) =>{  
      this.questionnaireResidentialData = res;
      this.generateNextSheet(res)
    })
  }
}
