<div class="submission-overlay" [hidden]="!submissionView">
    <div class="content-wrapper">
        <i class="fa fa-info-circle" aria-hidden="true"></i>
        <h1>Final Submission</h1>
        <p>Before continuing, please confirm that the answers given are complete as they cannot be changed later.</p>

        <div class="action-wrapper">
            <button class="button-back" (click)="backClick()">Back</button>
            <button class="confirm-button" (click)="submitAPICall()">Confirm</button>
        </div>
    </div>
</div>