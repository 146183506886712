<div class="dialog-wrapper">
    <h2>Privacy Statement</h2>
    <mat-dialog-content>
        <p>Ergänzende Datenschutzhinweise nach Art. 13, 14 EU-Datenschutzgrundverordnung (DSGVO) für ESG Real Estate Tool</p>
        <p>In Ergänzung zu den Datenschutzhinweisen, welche Ihnen vor unserer Beauftragung ausgehändigt wurden, 
            sollen Ihnen die nachfolgenden ergänzenden Datenschutzhinweise verständlich, transparent und übersichtlich erläutern, 
            wie Ihre personenbezogenen Daten bei der Nutzung unseres Services in Verbindung mit dem ESG Real Estate Tool verarbeitet werden. 
            Sollten Sie dennoch Verständnisfragen oder sonstige Rückfragen zum Datenschutz bei PwC haben, können Sie sich gerne über die untenstehenden Kontaktdaten an uns wenden. </p>
        <p>Das ESG Real Estate Tool ermöglicht die Ermittlung des ESG Ratings von Immobilien auf Einzelobjektebene und differenziert dabei zwischen Wohnimmobilien und Gewerbeimmobilien. 
            Darüber hinaus stellt das ESG Real Estate Tool Maßnahmen zur Verbesserung des Ratings dar und ist dabei angelehnt an das etablierte BREEAM in Use-Gebäudezertifikat.</p>
        <h2>1. Verantwortlicher</h2>
        <p>Verantwortlicher im Sinne des Art. 4 Nr. 7 DSGVO für die Verarbeitung Ihrer personenbezogenen Daten ist die:</p>
        <div class="address">
            <p>PricewaterhouseCoopers GmbH Wirtschaftsprüfungsgesellschaft</p>
            <p>Friedrich-Ebert-Anlage 35-37</p>
            <p>60327 Frankfurt am Main</p>
            <p><b>E-Mail:</b> DE_Kontakt@pwc.com</p>
            <p><b>Telefonzentrale:</b> +49 69 9585-0</p>
            <p><b>Fax:</b> +49 69 9585-1000</p>
        </div>

        <h2>2. Datenschutzbeauftragter</h2>
        <p>PwC hat einen Datenschutzbeauftragten gemäß Art. 37 DSGVO benannt. Sie können den Datenschutzbeauftragten der PwC, Dr. Tobias Gräber, 
            unter den folgenden Kontaktdaten erreichen:</p>
        <p><b>E-Mail-Kontakt:</b> DE_Datenschutz@pwc.com</p>    
        <p><b>Telefon:</b>  +49 69 9585-0</p>
        <p>Adresse für postalische Kontaktaufnahme:</p>
        <div class="address">
            <p>PricewaterhouseCoopers GmbH Wirtschaftsprüfungsgesellschaft</p>
            <p>Friedrich-Ebert-Anlage 35-37</p>
            <p>60327 Frankfurt am Main</p>
            <p><b>E-Mail:</b> DE_Kontakt@pwc.com</p>
            <p><b>Telefonzentrale:</b> +49 69 9585-0</p>
            <p><b>Fax:</b> +49 69 9585-1000</p>
        </div>

        <h2>3. Betroffenenrechte/Ihre Rechte aus dem Datenschutzrecht</h2>
        <p>Sie haben folgende Rechte nach dem geltenden Datenschutzrecht hinsichtlich der Sie betreffenden personenbezogenen Daten.</p>
        <p><b>Recht auf Auskunft:</b> Sie können jederzeit von uns Auskunft darüber verlangen, ob und welche personenbezogenen Daten wir über Sie speichern. Die Auskunftserteilung ist für Sie kostenfrei.</p>
        <p>Das Recht auf Auskunft besteht nicht oder nur eingeschränkt, wenn und soweit durch die Auskunft geheimhaltungsbedürftige Informationen offenbart würden, 
            bspw. Informationen, die einem Berufsgeheimnis unterliegen.</p>
        <p><b>Recht auf Berichtigung:</b> Wenn Ihre personenbezogenen Daten, die bei uns gespeichert sind, unrichtig oder unvollständig sind, haben Sie das Recht, von uns jederzeit die Berichtigung dieser Daten zu verlangen.</p> 
        <p><b>Recht auf Löschung:</b> Sie haben das Recht, von uns die Löschung Ihrer personenbezogenen Daten zu verlangen, wenn und soweit die Daten für die Zwecke, für die sie erhoben wurden, nicht mehr benötigt werden oder, 
            wenn die Verarbeitung auf Ihrer Einwilligung beruht, Sie Ihre Einwilligung widerrufen haben. In diesem Fall müssen wir die Verarbeitung Ihrer personenbezogenen Daten einstellen und diese aus unseren IT-Systemen und Datenbanken entfernen.</p>   
        <p>Ein Recht auf Löschung besteht nicht, soweit</p>
        <ul>
            <li>die Daten aufgrund einer gesetzlichen Pflicht nicht gelöscht werden dürfen oder aufgrund einer gesetzlichen Pflicht verarbeitet werden müssen;</li>
            <li>die Datenverarbeitung erforderlich ist zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</li>
        </ul>
        <p><b>Recht auf Einschränkung der Verarbeitung:</b> Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten von uns zu verlangen.</p>
        <p><b>Recht auf Datenübertragbarkeit:</b> Sie haben das Recht, von uns die von Ihnen bereitgestellten Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten sowie das Recht, 
            dass diese Daten an einen anderen Verantwortlichen übermittelt werden. Dieses Recht besteht nur, wenn</p>
        <ul>
            <li>Sie uns die Daten auf der Grundlage einer Einwilligung oder aufgrund eines mit Ihnen abgeschlossenen Vertrages zur Verfügung gestellt haben;</li>
            <li>die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</li>
        </ul>  
        <p><b>Recht auf Widerspruch gegen die Verarbeitung:</b> Wenn die Verarbeitung Ihrer Daten auf der Grundlage des Art. 6 Abs. 1 lit. f) DSGVO erfolgt, können Sie jederzeit Widerspruch gegen die Verarbeitung einlegen.</p> 
        <p>Alle der oben beschriebenen Betroffenenrechte können Sie gegenüber PwC geltend machen, wenn Sie Ihr konkretes Begehren an die folgenden Kontaktdaten richten:</p> 
        <p><b>Per E-Mail:</b> DE_Datenschutz@pwc.com </p> 
        <p><b>Per Post:</b></p>
        <div class="address">
            <p>PricewaterhouseCoopers GmbH WPG</p>
            <p>Dr. Tobias Gräber, Datenschutzbeauftragter</p>
            <p>Friedrich-Ebert-Anlage 35-37</p>
            <p>60327 Frankfurt am Main</p>
        </div>

        <h2>4. Beschwerderecht bei einer Datenschutzaufsichtsbehörde</h2>
        <p>Sie haben nach Maßgabe des Art. 77 DSGVO das Recht auf Beschwerde bei einer Datenschutzaufsichtsbehörde, wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten gegen das Datenschutzrecht verstößt.</p>

        <h3>5. Bereitstellung der Daten</h3>
        <p>Auch wenn teilweise eine automatische Übermittlung von Daten beim Aufruf unserer Anwendung stattfindet, sind Sie nicht gesetzlich oder vertraglich verpflichtet, Daten im Zusammenhang mit der Nutzung unserer Anwendung bereitzustellen.</p>

        <h2>6. Beschreibung der Datenverarbeitungen und Rechtsgrundlagen für die Verarbeitung</h2>
        <h2>6.1 Empfänger der Daten</h2>
        <p>Zur Erfüllung der nachstehend aufgezählten Verarbeitungszwecke findet auch ein Datentransfer an Dritte statt. Dies kann auch die Übermittlung von personenbezogenen Daten in das europäische und außereuropäische Ausland beinhalten und die Speicherung von Daten auch außerhalb der EU bzw. des Europäischen Wirtschaftsraums (EWR).</p>
        <h3>Weisungsgebundene Empfänger</h3>
        <p>Wir geben Ihre Daten an weisungsgebundene Dienstleister sowohl innerhalb des PwC-Netzwerks als auch gegenüber sonstigen Dritten, wie z.B. IT-Dienstleistern weiter, die uns bei unserem Tätigwerden, z.B. im Rahmen der Verwaltung und Pflege der Webseiten und den damit verbundenen Systemen und/oder für andere interne oder administrative Zwecke unterstützen.</p>
        <p>PwC ist Mitglied des globalen PwC-Netzwerks, das aus den einzelnen rechtlich selbständigen und unabhängigen PwC-Gesellschaften besteht. Wir nutzen im Rahmen unserer Tätigkeit weitere deutsche oder ausländische PwC-Netzwerkgesellschaften als weisungsgebundene netzwerkinterne IT-Dienstleister, die Leistungen des Betriebs, der Wartung und Pflege der von den PwC-Netzwerkgesellschaften genutzten IT-Systeme und Applikationen erbringen. Dies ist insbesondere die PwC IT Services Ltd. mit Sitz im Vereinigten Königreich (UK).</p>
        <p>Wenn wir Daten an weisungsgebundene Dienstleister weitergeben, benötigen wir dafür keine gesonderte Rechtsgrundlage.</p>
        <p>Nähere Informationen zu den von PwC eingesetzten Cloud-Dienste-Anbietern können Sie unter folgendem Link abfragen: www.pwc.de/externe-dienstleister.</p>
        
        <h3>Eigenverantwortliche Empfänger</h3>
        <p>Darüber hinaus geben wir Ihre Daten im Einzelfall sowohl innerhalb des PwC-Netzwerks, als auch an sonstige Dritten weiter, welche Ihre Daten eigenverantwortlich nutzen. Bspw. übermitteln wir im Einzelfall personenbezogene Daten zur Unterstützung und Effektivierung unserer Geschäftsprozesse (einschließlich koordinierter Marketingaktivität) auch an andere Unternehmen aus dem PwC-Netzwerk.</p>
        <p>Darüber hinaus geben wir Ihre Daten im Einzelfall auch an andere Dritte weiter, wie z.B. Behörden, Gerichte oder andere Stellen, wenn wir gesetzlich oder aufgrund behördlicher oder gerichtlicher Anordnung eines EU-Mitgliedsstaates zur Herausgabe von personenbezogenen Daten an diese Stellen verpflichtet sind. Diese Stellen nutzen die Daten ebenfalls eigenverantwortlich.</p>
        <p>Soweit Sie explizit eingewilligt haben, ist Art. 6 Abs. 1 lit. a) DSGVO Rechtsgrundlage für die Datenübermittlung. Soweit eine gesetzliche Verpflichtung zur Offenlegung der Daten besteht, ist Art. 6 Abs. 1 lit. c) DSGVO Rechtsgrundlage für die Datenübermittlung. Wenn die Offenlegung dagegen zur Erfüllung einer vertraglichen- oder vorvertraglichen Maßnahme mit Ihnen als natürliche Person erforderlich ist, 
            ist Art. 6 Abs. 1 lit. b) DSGVO Rechtsgrundlage für die Datenübermittlung. Andernfalls basiert die Übermittlung auf unseren berechtigten Interessen und die Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO; wir und die anderen Unternehmen des PwC-Netzwerks haben ein Interesse daran, unsere Arbeitsprozesse effizient zu gestalten und Geschäftsprozesse dazu innerhalb des PwC-Netzwerks aufzuteilen.</p>
        
        <h3>Datentransfer an Empfänger in Drittländern außerhalb EU/EWR</h3>
        <p>Soweit einer der o.g. Datentransfers an einen Empfänger außerhalb des EWR erfolgt (in sog. „Drittländer“), wird ein angemessenes Datenschutzniveau für die Auslandsübermittlung durch geeignete Sicherheitsmaßnahmen gewährleistet.</p>
        <p>Für Datentransfers innerhalb des PwC-Netzwerks haben die PwC-Netzwerkgesellschaften unter anderem einen internen Datenschutzvertrag abgeschlossen, der für den Datentransfer von personenbezogenen Daten aus EU/EWR-Staaten zu PwC-Netzwerkgesellschaften außerhalb EU/EWR die Einhaltung der EU-Standardvertragsklauseln der EU-Kommission im Sinne des Art. 46 Abs. 2 lit. c) DSGVO vorsieht.</p>
        <p>Soweit Sie Fragen zu solchen Datenschutzverträgen auf Basis der EU-Standardvertragsklauseln haben oder Sie gerne mehr Informationen zu weiteren Sicherheitsmechanismen und Sicherheitsmaßnahmen für den Datentransfer in Drittländer hätten, können Sie sich gerne an unseren Datenschutzbeauftragten wenden und diesen z.B. unter DE_Datenschutz@pwc.com kontaktieren.</p>
        
        <h2>6.2 Verarbeitung personenbezogener Daten bei Nutzung unseres Services mit dem ESG Real Estate Tool </h2>
        <p>Beim Aufrufen der Website werden die folgenden personenbezogene Daten erhebt, die technisch notwendig sind, um Ihnen das ESG Real Estate Tool anzuzeigen. Es handelt sich dabei z.B. um die folgenden personenbezogenen Daten, die von Ihrem Browser automatisch übermittelt werden:</p>
        <ul>
            <li>Datum und Uhrzeit Ihrer Anfrage/Ihres Aufrufs der Webseite (der App)</li>
            <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
            <li>Inhalt der Anforderung (Information, welche konkrete Seite von Ihnen aufgerufen wurde)</li>
            <li>Zugriffsstatus/http-Statuscode</li>
            <li>Jeweils übertragene Datenmenge</li>
            <li>Website, von der die Anforderungen kommt</li>
            <li>Browser (Informationen zu dem von Ihnen genutzten Browser)</li>
            <li>Betriebssystem und dessen Oberfläche (Betriebssystem des von Ihnen zum Aufruf der Webseite bzw. der App genutzten Rechners)</li>
            <li>Sprache und Version der Browsersoftware</li>
        </ul>

        <h2>6.2.2 Registrierung und Anmeldung </h2>
        <p>Die Nutzung des ESG Real Estate Tool ist ohne Registrierung und Anmeldung nicht möglich.</p>
        <p>Folgende personenbezogene Daten werden hierbei verarbeitet:</p>
        <ul>
            <li>E-Mail-Adresse</li>
            <li>Vorname</li>
            <li>Nachname</li>
        </ul>
        <p>Die Verarbeitung dieser personenbezogenen Daten im Rahmen der Anlage durch einen PwC Mitarbeiter erfolgt auf der Grundlage des Art. 6 Abs. 1 lit. f) DSGVO zur Durchführung des Vertragsverhältnisses. PwC hat ein legitimes Interesse daran, Ihnen das ESG Real Estate Tool zur Verfügung zu stellen. </p>
        <p>Sämtliche Registrierungs- und Anmeldedaten werden von uns nach Beendigung des Mandats gelöscht. Soweit gesetzliche Aufbewahrungspflichten bestehen, werden die Daten für die Dauer der gesetzlich vorgeschriebenen Aufbewahrungspflicht gespeichert.</p>

        <h2>6.3 Einsatz von Cookies</h2>
        <p>Wir setzen in unserer Anwendung sogenannte Cookies ein. Cookies sind kleine Textdateien mit Konfigurationsinformationen, die von unseren Webservern an Ihren Browser gesendet werden, wenn Sie unsere Anwendung aufrufen, und von diesem auf Ihrem Computer für einen späteren Abruf gespeichert werden. </p>
        <p>Cookies ermöglichen es unserer Anwendung, Informationen von Ihrem Browser abzurufen oder zu speichern. Dies können Informationen über Sie, Ihre Präferenzen oder Ihr Gerät sein. In den meisten Fällen werden sie verwendet, um sicherzustellen, dass die Anwendung wie erwartet funktioniert. Normalerweise werden Sie durch diese Informationen nicht direkt identifiziert. Sie können Ihnen jedoch ein personalisierteres Erlebnis bieten. </p>
        <p>Einige der von uns verwendeten Cookies werden nach dem Ende der Sitzung, also nach Schließen der Anwendung, wieder gelöscht (sog. Sitzungs-Cookies bzw. Session Cookies). Andere Cookies verbleiben auf Ihrem Endgerät und ermöglichen uns oder unseren Partnerunternehmen, bspw. Ihren Browser beim nächsten Besuch wiederzuerkennen (sog. persistente Cookies bzw. dauerhafte Cookies).</p>
        <p>Es wird ausschließlich die nachfolgende Cookie-Kategorie verwendet: </p>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close (click)="closeDialog()">Close</button>
      </mat-dialog-actions>
</div>
