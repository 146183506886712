import { Component, EventEmitter, OnInit } from '@angular/core';
import { CreateTemplateService } from 'src/app/core/services/create-template.service';
import { environment } from 'src/environments/environment';
import { PropertiesListingService } from 'src/app/core/services/properties-listing.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog/';
import { ProjectService } from 'src/app/core/services/project.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
@Component({
  selector: 'app-import-alert',
  templateUrl: './import-alert.component.html',
  styleUrls: ['./import-alert.component.scss']
})
export class ImportAlertComponent implements OnInit {
  response:string = '';
  errorMsg:string ='';
  breeamProjectOnly:boolean = false;
  constructor(private createTemplateService: CreateTemplateService, private propertiesListing:PropertiesListingService,
    public dialogRef: MatDialogRef<ImportAlertComponent>, private projectService: ProjectService, private http:HttpClient) {     
  }

  ngOnInit(): void {
    this.projectService.currentProjectData.subscribe((res:any)=>{
      if(res.breeam_status == false || res.project_role=='Viewer')
        this.breeamProjectOnly = true
      if(res.archive_status)
        this.breeamProjectOnly = true
    })
  }

  triggerUpload(){    
    let uploadBtn = document.getElementById('upload-trigger') as HTMLElement;
    uploadBtn.click(); 
  }

  download(){
   this.createTemplateService.generateExcel();
  }

  processTheUploadedFile(fileName:string, projectId:any, skip:boolean){
    this.propertiesListing.processUploadedFile(fileName, projectId, skip).subscribe(res=> {
      this.dialogRef.close();
    }, error =>{      
      if(error.error.errorCode == 'Outdated'){
        let text = "The uploaded file is Outdated. Do you wish to continue?";
        if (confirm(text) == true) {
          this.processTheUploadedFile(fileName, sessionStorage.getItem('project_id'), true);      
        } else {
          return;
        }
      }       
    })   
  }

  upload(){
    alert("File uploaded");
  }

  onFileSelected(event: any) {
    let fileList: FileList = event.target.files;
    if(fileList.length > 0) {
        let file: File = fileList[0];
        let formData:FormData = new FormData();
        formData.append('file', file, file.name);             
        const options = {
          headers: new HttpHeaders().append('Content-Type', 'multipart/form-data'),          
        }
        this.http.post(`${environment.apiUrl+'/files'}`, formData, options)
            .subscribe(
                (data:any) => {                  
                  this.processTheUploadedFile(data.fileInfo.fileName, sessionStorage.getItem('project_id'), false);
                },
                error => console.log(error)
            )
    }
  }  
}