<div class="progress-wrapper">
    <div class="progress-inner-wrapper">
        <div class="progress-label-wrapper row">            
            <label style="width: 17%;"  *ngFor="let item of stages"><span class="item-label" (click)="progressNavigation(item)" [style.color]="activeStatus(item)" *ngIf="item.visible">{{item.name}}</span></label>
            <div class="col"></div>
        </div>
        <div class="row custom-progress-bar ">
            <div *ngFor="let item of stages" class="progress-indicator" [style.width]="item.leftVal" [style.background]="item.status == 'active'? 'red':'grey'  ">
                <i class="fa fa-circle" *ngIf="item.visible" [style.color]="item.status == 'active'? 'red':'grey'  " aria-hidden="true"></i>
            </div>
            <div class="progress-fill-end"></div>
        </div>
    </div>
</div>