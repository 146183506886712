import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent implements OnInit, AfterViewInit {

  loading: boolean = false;
  constructor(private loaderService: LoaderService, private cdr: ChangeDetectorRef) {

  }
  ngAfterViewInit(): void {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
      this.cdr.detectChanges();
    });
  }

  ngOnInit() {
  }

}
