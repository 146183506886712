<div class="row">
  <div class="col-3 search-wrapper">
    <input type="text" class="search-input" matInput placeholder="Search" (keyup)="filterProjects($event,false)">
    <i class="fa fa-search text-danger"></i>
  </div>
  
  <div class="col-9 action-btn-wrapper"> 
    <button class="edit-quest-buttton" *ngIf="userRoleAccess?.user?.role == 'SuperAdmin'" (click)="openEditQuestDialog()">Edit questionnaires</button>
    <button class="new-project-buttton" *ngIf="userRoleAccess?.user?.role == 'SuperAdmin'" (click)="navigateToNewProject()">Create new project</button>
    <button class="archive-project-buttton" *ngIf="selectedProject.length > 0" (click)="archiveProperty()">Archive</button>
  </div>
</div>

<div class="main-wrapper">
<table mat-table [dataSource]="dataSource?.projects | paginate: { itemsPerPage: 8, currentPage: p }" class="project-dash-table-wrapper">
  <ng-container matColumnDef="archive">
    <th *matHeaderCellDef class="header header-border-bottom">      
    </th>

    <td *matCellDef="let element" class="font-w-600">
        <mat-checkbox class="example-margin" (change)="selectProject($event,element)"></mat-checkbox>
    </td>
</ng-container>
    <ng-container matColumnDef="name">
      <th *matHeaderCellDef class="header header-border-bottom">Name 
        <i class="fa fa-ellipsis-v"></i>
      </th>

      <td *matCellDef="let element" class="font-w-600"> {{element.project_name}} </td>
    </ng-container>
  
    <ng-container matColumnDef="owner">
      <th *matHeaderCellDef class="header header-border-bottom">Owner
        <i class="fa fa-ellipsis-v"></i>
      </th>
      <td  *matCellDef="let element"> {{element.owner?element.owner: '-'}} </td>
    </ng-container>

    <ng-container matColumnDef="engagementPartner">
      <th *matHeaderCellDef class="header header-border-bottom">Engagement Partner
        <i class="fa fa-ellipsis-v"></i>
      </th>
      <td  *matCellDef="let element"> {{element.engagement_partner?element.engagement_partner:'-'}} </td>
    </ng-container>
    
    <ng-container matColumnDef="engagementManager">
      <th *matHeaderCellDef class="header header-border-bottom">Engagement Manager
        <i class="fa fa-ellipsis-v"></i>
      </th>
      <td  *matCellDef="let element"> {{element.engagement_manager?element.engagement_manager:'-'}} </td>
    </ng-container>
  
    <ng-container matColumnDef="startDate">
      <th  *matHeaderCellDef class="header header-border-bottom">Start Date
        <i class="fa fa-ellipsis-v"></i>
      </th>
      <td  *matCellDef="let element"> {{element.expected_start_date | date : 'dd/MM/YYYY'}} </td>
    </ng-container>

    <ng-container matColumnDef="endDate">
      <th  *matHeaderCellDef class="header header-border-bottom">End Date
        <i class="fa fa-ellipsis-v"></i>
      </th>
      <td  *matCellDef="let element"> {{element.expected_end_date | date : 'dd/MM/YYYY'}} </td>
    </ng-container>

    <ng-container matColumnDef="lastSaved">
        <th  *matHeaderCellDef class="header header-border-bottom">Last saved
            <i class="fa fa-ellipsis-v"></i>
        </th>
        <td  *matCellDef="let element"> {{element.updated_date | date : 'dd/MM/YYYY'}} </td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th  *matHeaderCellDef class="header header-border-bottom">Status 
            <i class="fa fa-ellipsis-v"></i>
        </th>
        <td  *matCellDef="let element" class="font-w-600" [style.color]="element.project_status === 'Finished' ? '#299D8F' : '#E0301E'"> {{element.project_status}} </td>
    </ng-container>

    <ng-container matColumnDef="open">
      <th></th>
      <td></td>
        <th  *matHeaderCellDef class="header header-border-bottom">Open 
            <i class="fa fa-ellipsis-v open-manage-header-icon"></i>
        </th>
        <td  class="col-open"  *matCellDef="let element"> <a class="fa fa-external-link" style="text-decoration: none;
          color: #25B4E8; padding-left: 11px;" (click)="navigateToProperties(element)"></a> </td>
    </ng-container>
    <ng-container matColumnDef="manage">
        <th  *matHeaderCellDef class="header header-border-bottom">Manage Project 
            <i class="fa fa-ellipsis-v open-manage-header-icon"></i>
        </th>
        <td  class="col-open"  *matCellDef="let element" class="edit-button-wrapper">
          <button class="edit-button" *ngIf="element.edit_status" (click)="navigateToEdit(element)">Edit</button>
        </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
</div>
<div *ngIf="!dataSource?.projects?.length && noProjectFlag" class="no-data-display">No projects assigned</div>
<pagination-controls *ngIf="dataSource?.projects?.length" class="my-pagination" (pageChange)="p = $event" (pageChange)="pageChanged($event)" previousLabel="Previous" nextLabel="Next"></pagination-controls>