import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from 'src/app/core/services/project.service';

@Component({
  selector: 'app-edit-users',
  templateUrl: './edit-users.component.html',
  styleUrls: ['./edit-users.component.scss']
})
export class EditUsersComponent implements OnInit {
  disableAdmin: boolean = false;

  constructor(private fb:FormBuilder, private dialogRef:MatDialogRef<EditUsersComponent>, private projectService: ProjectService, 
    private activatedroute:ActivatedRoute, @Inject(MAT_DIALOG_DATA) public data: any) { }

  editForm: FormGroup = this.fb.group({
    user_name: ['', Validators.required],
    email: new FormControl('', Validators.pattern('^[a-z0-9._%+-]+@pwc\.com$')),
    user_type: ['', Validators.required],
    project_role: ['', Validators.required]
  });
  isInternal:boolean = false;
  ngOnInit(): void {
    console.log(this.data);
    
    this.editForm.get('user_name')?.setValue(this.data.user.user_name);
    this.editForm.get('email')?.setValue(this.data.user.email);
    this.editForm.get('user_type')?.setValue(this.data.user.user_type);
    this.editForm.get('project_role')?.setValue(this.data.user.project_role);
  }

  edit(event: any){
    this.editForm.get(event.currentTarget.attributes.formcontrolname.value)?.patchValue(event.target.value)
    this.editForm.get(event.currentTarget.attributes.formcontrolname.value)?.markAsDirty();
  }

  emailValidator(event:any){
    /*const regex = '^[a-z0-9._%+-]+@pwc\.com$';
    const emailVal = this.editForm.get('email')?.value;
    let res = emailVal.match(regex);
    if(res)
      this.isInternal = true;
    else
      this.isInternal = false;*/
    var mailformat = /^[a-z0-9._%+-]+@pwc\.com$/;

    if(!event.target.value.match(mailformat)){
      this.editForm.get('email')?.setErrors({externalPattern:true});
      this.isInternal = false;
    }
    if(event.target.value.match(mailformat)){
      this.editForm.get('email')?.setErrors({internalPattern:true});
      this.isInternal = true;
    }
    if(!event.target.value.match(mailformat))
      this.disableAdmin = true
    else
      this.disableAdmin = false
  }

  updateUsers(){
    let userData = {
      user_name : this.editForm.get('user_name')?.value,
      email : this.editForm.get('email')?.value,
      user_type : this.editForm.get('user_type')?.value,
      project_role : this.editForm.get('project_role')?.value,
    };
    this.projectService.updateUsers(userData, this.data.user.user_id).subscribe((res:any) => {
      let temp = {
        "project_role": userData.project_role
      }
      this.projectService.updateProjectUserRole(temp, this.data.user.user_id, this.data.projectDetails.project.project_id).subscribe((res:any) => {
        console.log("role modified");
        this.closeDialog();
      });
    });
  }

  closeDialog(){
    this.dialogRef.close();
  }
}