import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { PropertyBindingType } from '@angular/compiler';
import { QuestionnaireEditDialogComponent } from '../questionnaire-edit-dialog/questionnaire-edit-dialog.component';

@Component({
  selector: 'app-edit-questionnaire-alert',
  templateUrl: './edit-questionnaire-alert.component.html',
  styleUrls: ['./edit-questionnaire-alert.component.scss']
})

export class EditQuestionnaireAlertComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<EditQuestionnaireAlertComponent>,  public dialog: MatDialog) { }
  breeamFlag: boolean = true;
  euFlag: boolean = true;
  propertyType :string = '';  
  ngOnInit(): void {
  }

  closeDialog(){
    this.dialogRef.close();
  }

  breeamSelect(event: any){
    if(event.target.value){
      this.breeamFlag = false;
      this.propertyType = event.target.value
    } 
  }

  euSelect(event: any){
    if(event.target.value){
      this.euFlag = false;
      this.propertyType = event.target.value;
    } 
  }

  openQuestionnaireDialog(category_type:string){
    const dialogRef = this.dialog.open(QuestionnaireEditDialogComponent, {data: {propertyType : this.propertyType, categoryType : category_type}});
  }
}
