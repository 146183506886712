import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { ProjectService } from 'src/app/core/services/project.service';
import { Location } from '@angular/common';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public titleName = 'ESG Real Estate Tool';
  public subTitleName = "Properties";
  public logoutMenu = false;
  public primaryConfig = {
    isPrimary: true
  }
  adminLink:boolean = false;
  archievedProjects:boolean = false;
  public userName:any;
  public activeAccount: any;
  constructor(private projectService: ProjectService, private loaderService: LoaderService, private userService: UserService, private msalService: MsalService) { }

  ngOnInit(): void {
    this.roleValidation();
    this.loaderService.subTitle.subscribe((res: string) => {
      this.subTitleName = res;
    });
    this.userService.currentUserDetails.subscribe((res:any) =>{
      if(sessionStorage.getItem('role') == 'SuperAdmin')
        this.archievedProjects = true;
    
      this.userName = sessionStorage.getItem("user_name");
      const allActiveAccount = this.msalService.instance.getAllAccounts();
      this.activeAccount = allActiveAccount[0];
    });
  }

  roleValidation(){
    this.projectService.currentProjectData.subscribe((res:any) =>{
      console.log(res)
      if(res.project_role == 'Admin' || res.project_role == 'SuperAdmin')
        this.adminLink = true;
    })
  }

  logout() {
    this.msalService.logoutRedirect({
      account: this.activeAccount
    })
  }

  showLogoutMenu() {
    this.logoutMenu = !this.logoutMenu;
  }

  dashSaveAndContinueButton() {
    console.log('button is clicked')
  }

}
