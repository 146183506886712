import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from 'src/app/core/services/project.service';
import { MatDialog } from '@angular/material/dialog';
import { EditUsersComponent } from '../edit-users/edit-users.component';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.scss']
})
export class EditProjectComponent implements OnInit {
  currentUser: any;
  disableAdmin: boolean = false;
  constructor(private fb: FormBuilder, private projectService: ProjectService, private activatedroute:ActivatedRoute, private router: Router
    , public dialog: MatDialog, private loaderService: LoaderService, private userService: UserService) { }
  userArray:any=[];
  projectDetails:any;
  deletedUsers:any = [];
  newAddedUser:any = []
  usersToDisplay:any;
  maxExpectedStartDate:any;
  editProjectForm = this.fb.group({
    project_name :  new FormControl('', Validators.required),
    engagement_partner: new FormControl('', Validators.required),
    engagement_partner_email: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,5}$')]),  
    engagement_manager: new FormControl('', Validators.required),  
    engagement_manager_email: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,5}$')]),  
    expected_start_date: new FormControl(''),
    expected_end_date: new FormControl(''),
    breeam_status: new FormControl(false),
    eu_status: new FormControl(false)
  });

  userForm = this.fb.group({
    user_name : new FormControl('',Validators.required),
    email : new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,5}$')]),
    role: new FormControl('', Validators.required)
  })
  
  ngOnInit(): void {
    this.loaderService.subTitle.next('Admin');
    this.getProjectUsersDetails(this.activatedroute.snapshot.paramMap.get("id"));
    this.currentUserDetails();
  }

  currentUserDetails(){
    this.userService.currentUserDetails.subscribe((res:any) =>{
      this.currentUser = res;
      console.log(res, "User details")
    });
  }

  addUsers(){
    let newUser = this.userForm.value;
    newUser.user_type = "Internal";
    //if(newUser.role == "Viewer")
    // newUser.designation = "EngagementPartner";
    //if(newUser.role == "Admin")
    // newUser.designation = "EngagementManager";
    this.newAddedUser.push(newUser);
    //let partnerDetails = this.editProjectForm.value;
    //this.newAddedUser.push(partnerDetails);
    this.usersToDisplay.push(newUser);
    this.userForm.reset();
  }

  userInputValidation(event:any){
    let userFind = this.projectDetails.project.userArray.find((item:any) => item.email == this.userForm.get('email')?.value );
    if(userFind)
      this.userForm.get('email')?.setErrors({incorrect : true})
    if(event.target.value == this.currentUser.user.email)
      this.userForm.get('email')?.setErrors({incorrect:true})
    var mailformat = /^[a-z0-9._%+-]+@pwc\.com$/;
    if(!event.target.value.match(mailformat))
      this.disableAdmin = true
    else
      this.disableAdmin = false
  }

  partnerChangeEvent(event:any){
    if(event.target.value == this.currentUser.user.email)
      this.editProjectForm.get('engagement_partner_email')?.setErrors({admin:true})
    if(this.editProjectForm.get('engagement_manager_email')?.value == event.target.value)
      this.editProjectForm.get('engagement_partner_email')?.setErrors({duplicate:true})
  }

  managerChangeEvent(event:any){
    if(event.target.value == this.currentUser.user.email)
      this.editProjectForm.get('engagement_manager_email')?.setErrors({admin:true})
    if(this.editProjectForm.get('engagement_partner_email')?.value == event.target.value)
      this.editProjectForm.get('engagement_manager_email')?.setErrors({duplicate:true})
  }


  editUsers(user: any, projectDetails : any){
    const dialogRef = this.dialog.open(EditUsersComponent,{
      width: '450px',
      height: '200px',
      data : {
        user: user,
        projectDetails: projectDetails
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getProjectUsersDetails(this.activatedroute.snapshot.paramMap.get("id"));
    });
  }

  deleteUser(email:any, user_id:any){
    this.deletedUsers.push({user_id})
    this.usersToDisplay = this.usersToDisplay.filter(function(obj:any) {
      return obj.email !== email;
    });
  }

  getProjectUsersDetails(id:any){
    this.projectService.getProjectUsers(id).subscribe((res:any) =>{
      this.projectDetails = res;
      let engagementPartnerTemp = res.project.userArray.find((item:any) => item.designation == 'EngagementPartner');
      let engagementManagerTemp = res.project.userArray.find((item:any) => item.designation == 'EngagementManager');
      this.editProjectForm.get('project_name')?.patchValue(res.project.project_name); 
      this.editProjectForm.get('engagement_partner')?.patchValue(engagementPartnerTemp?.user_name);
      this.editProjectForm.get('engagement_partner_email')?.patchValue(engagementPartnerTemp?.email);
      this.editProjectForm.get('engagement_manager')?.patchValue(engagementManagerTemp?.user_name);
      this.editProjectForm.get('engagement_manager_email')?.patchValue(engagementManagerTemp?.email);
      this.editProjectForm.get('expected_start_date')?.patchValue(res.project.expected_start_date);
      this.editProjectForm.get('expected_end_date')?.patchValue(res.project.expected_end_date);
      this.editProjectForm.get('breeam_status')?.patchValue(res.project.breeam_status);
      this.editProjectForm.get('eu_status')?.patchValue(res.project.eu_status);
      /*let engPartner = res.project.userArray.filter((fil:any) => { fil.designation == "EngagementPartner"}).user_name;
      console.log(engPartner);
      this.editProjectForm.get('engagement_partner')?.patchValue(engPartner);*/
      console.log(this.projectDetails);
      this.usersToDisplay = this.projectDetails.project.userArray.filter((item:any)=> item.designation !== "EngagementPartner" && item.designation !== "EngagementManager")
    }, (err:any) =>{
      if(err.status == 401){
        alert("Unauthorized");
        this.router.navigate(['/project-dashboard']);
      }

    });
  }

  updateProjectDetails(){
    let partnerTemp = {user_name: this.editProjectForm.get('engagement_partner')?.value, email:  this.editProjectForm.get('engagement_partner_email')?.value, role: "Admin", user_type: "Internal", designation:"EngagementPartner"};
    let managerTemp = {user_name: this.editProjectForm.get('engagement_manager')?.value, email:  this.editProjectForm.get('engagement_manager_email')?.value, role: "Admin", user_type: "Internal", designation:"EngagementManager"}
    if(this.editProjectForm.get('engagement_partner_email')?.value && this.editProjectForm.get('engagement_partner_email')?.dirty){
      this.newAddedUser.push(partnerTemp)      
    }
    if(this.editProjectForm.get('engagement_manager_email')?.value && this.editProjectForm.get('engagement_manager_email')?.dirty){
      this.newAddedUser.push(managerTemp)      
    }
    let data = {
      project_name:
      this.editProjectForm.get('project_name')?.value !==
      this.projectDetails.project.project_name
        ? this.editProjectForm.get('project_name')?.value
        : undefined,
      expected_start_date: this.editProjectForm.get('expected_start_date')?.value,
      expected_end_date: this.editProjectForm.get('expected_end_date')?.value,
      breeam_status: this.editProjectForm.get('breeam_status')?.value,
      eu_status : this.editProjectForm.get('eu_status')?.value,
      userArray : this.newAddedUser,
      deletedUserArray: this.deletedUsers
    }
    this.projectService.updateProjectDetails(data,this.activatedroute.snapshot.paramMap.get("id") ).subscribe((res:any) =>{
      // alert("Success");
      console.log("project modified");
      console.log(data);
      this.router.navigate(['/project-dashboard']);
    });
  }

  openProjects(){
    this.router.navigate(['/project-dashboard']);
  }

  expectedEndDateEvent(event:any){
    this.maxExpectedStartDate = event.target.value
  }
}
