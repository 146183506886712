<div class="row questionnaire-dialog">
    <div class="col-3 left-pane">
        <ul class="prog-timeline">
            <li class="" *ngIf="currentObject?.category_type == 'EU_Taxonomy'">
                <label style="color: #25B4E8; font-weight: bolder; font-size: 15px;">
                    <i class="fa fa-check-circle-o fa-lg" style="margin-right: 7px;" [style.color]="!selectedDateFlag ? '#ADADAD' : '#25B4E8' " ></i>{{firstselectDate}}  <span> {{selectedDate}}</span>
                </label>
                <hr>
            </li>
            <li class="prog-item prog-done" *ngFor="let category of questionnairesList">
                <label [style.font-weight]="category.selectedItem ? 'bolder' : 'normal'" [style.color]="category.selectedItem ? 'black' : ''" href="#"
                       id="{{category.category_id}}" 
                       class="prog-label"                     
                       (click)="categoryClick($event)">
                       <i class="fa fa-check-circle-o fa-lg"  [style.color]="category.status == 'completed'? '#24987B':'#ADADAD'"></i>
                       <span style="margin-left: 7px;" [matBadgeHidden]="!category.conflictNo" matBadge="{{category.conflictNo}}" matBadgeOverlap="false">{{category.category_name}}</span>
                </label>
            </li>
        </ul>
    </div>

    <div class="col-9 right-pane">
        <div class="dialog-header row">
            <div class="col-6">
                <div class="mat-close"> 
                    <!--<button [mat-dialog-close]="true" class="fa fa-angle-left" style="font-size: 11px;" aria-hidden="true"></button>-->
                    <button [mat-dialog-close]="true" href="#" style="font-size: xx-small;"><i class="fa fa-angle-left back-icon"></i>Back to
                        questionnaires</button>    
                </div>
                <span class="property-name" (click)="disableQuestionnaireForm()">Property {{data.assetReferenceName}} | {{data.categoryType}} |
                    {{data.yearOfSubmission}}</span>
            </div>
            <div class="col-3">
                <label class="total-no-answers">{{currentAnswersCount}}/{{currentQuestion.length}}</label>
            </div>
            <div class="col-3">
                <button class="save-and-close-btn" (click)="saveFormData(true)">Save and close</button>
            </div>
        </div>
        <div class="row question-wrapper">
            <div class="questionnaire-card"  [style.display]="displayDateFlag ?'block':'none'">
                <label style="font-weight: 600; font-size: 19px;">Please select date of building</label>
                <br>
                <mat-radio-group aria-label="Select an option" class="row">
                    <mat-radio-button value="2020" class="col" [disabled]="filterDisable"  (change)="selectDate($event)" [checked]="data.euQuestionnaireDate == '2020'">Built <strong>before</strong> 31 December 2020</mat-radio-button>
                    <mat-radio-button value="2021" class="col"  [disabled]="filterDisable"  (change)="selectDate($event)" [checked]="data.euQuestionnaireDate == '2021'">Built <strong>after</strong> 31 December 2020</mat-radio-button>
                </mat-radio-group>
                <hr style="margin-top: 36px;">
                <button type="button" class="text-white" style="float: right; background-color: #25B4E8; border-style: none;" (click)="showQuestion()">Apply</button>
            </div>
            <form [formGroup]="questionnaireForm" class="row">
                <div class="questionnaire-card-wrapper col-md-6" *ngFor="let ques of currentQuestion">
                <div class="questionnaire-card">
                    <span style="font-size: 11px; color: #25B4E8;">{{ques.question_code}}</span>
                    <button type="button" mat-button [matMenuTriggerFor]="menu" *ngIf="ques.menu_icon">
                        <mat-icon class="warning-icon">warning</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu"> 
                        <div class="admin-answer-wrapper"><b>Admin Answer :</b> {{ques?.admin_answer?.answer}}</div>
                        <button type="button" [disabled]="projectData.project_role == 'Admin'" mat-menu-item (click)="approveQuestionnaire(ques.question_id, ques.admin_answer)">Approve</button>
                        <button type="button" [disabled]="projectData.project_role == 'Admin'" mat-menu-item (click)="revertQuestionnaire(ques.question_id,  ques.admin_answer)">Reject</button>
                    </mat-menu> 
                    <button 
                            matTooltip="{{ques?.question_info}}"
                            aria-label="Button that displays a tooltip when focused or hovered over"
                            matTooltipPosition="right"
                            style="background-color: white; border: none; margin-top: -7px; float: right; display: inline;">
                            <span class="fa-stack fa-lg" style="color: #25B4E8;">
                                <i class="fa fa-circle-thin fa-stack-1x"></i>
                                <i class="fa fa-question fa-stack" style="font-size: small; margin-bottom: 3px;"></i>
                            </span>
                    </button>
                    <div class="form-group">
                        <label style="font-size: 15px; font-weight: 500;">
                            {{ques.question_item}}
                        </label>
                        <br>
                        <mat-radio-group *ngIf="ques.control_type == 'radiobutton'" aria-label="Select an option" formControlName="question_{{ques.question_id}}">
                            <mat-radio-button [disabled]="disableFullForm" *ngFor="let answerOption of ques?.answers  | orderBy: 'answer'" (change)="patchFormValue($event, ques.question_id)"
                                value="{{answerOption.answer_id}}" [checked]="answerOption.checked">
                                <span class="wrap-mat-radio-label">
                                    {{answerOption.answer}}
                                </span>
                            </mat-radio-button>
                        </mat-radio-group>
                        <div  *ngIf="ques.control_type == 'input'">
                            <mat-form-field appearance="fill" style="width:100%" *ngFor="let answerOption of ques?.answers">
                                <textarea matInput formControlName="question_{{ques.question_id}}" value="{{answerOption.inputAnswer}}" (change)="patchFormValue($event, ques.question_id, answerOption.answer_id, $event)"></textarea>
                            </mat-form-field>
                        </div>
                        <div class="eu-taxonomy-filter-wrapper" *ngIf="currentObject.category_name == 'Taxonomy Compliance'">
                            <p *ngFor="let answerOption of ques?.answers"><mat-checkbox  [disabled]="filterDisable" *ngIf="answerOption.visible" (change)="filterForEU($event, ques.question_id, answerOption.answer_id, answerOption.answer)" value="{{answerOption.answer_id}}" [checked]="answerOption.checked"
                                 formControlName="question_{{ques.question_id}}_{{answerOption.answer_id}}">{{answerOption.answer}}</mat-checkbox></p>
                        </div>
                    </div>
                </div>
                </div>
            </form>
        </div>
    </div>
</div>