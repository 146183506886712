import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { FailedComponent } from './shared/components/login-failed/failed.component';
import { AuthGuard } from './shared/services/auth.guard';
import { AddPropertiesComponent } from './views/add-properties/add-properties.component';
import { AdminResultsComponent } from './views/admin-results/admin-results.component';
import { AdminProjectResultsVisibilityComponent } from './views/admin/admin-project-results-visibility/admin-project-results-visibility.component';
import { CreateProjectComponent } from './views/admin/create-project/create-project.component';
import { EditProjectComponent } from './views/admin/edit-project/edit-project.component';
import { ProjectArchiveComponent } from './views/admin/project-archive/project-archive.component';
import { ProjectDashboardComponent } from './views/admin/project-dashboard/project-dashboard.component';
import { PropertyResultsComponent } from './views/property-results/property-results.component';

const routes: Routes = [
  {
    path: "",
    redirectTo: "project-dashboard",
    pathMatch: "full"
  },
  {
    path: "dashboard/:projectId",
    component: AddPropertiesComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "project-dashboard",
    component: ProjectDashboardComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "property-results",
    component: PropertyResultsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "admin-results",
    component: AdminResultsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "admin-project-results",
    component: AdminProjectResultsVisibilityComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "create-project",
    component: CreateProjectComponent,
    canActivate: [MsalGuard, AuthGuard],
  },
  {
    path: "edit-project/:id",
    component: EditProjectComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'login-failed',
    component: FailedComponent,
  },
  {
    path: 'archieved-projects',
    component: ProjectArchiveComponent,
    canActivate: [MsalGuard, AuthGuard],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
