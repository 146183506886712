<div class="import-alert-wrapper">
    <div class="alert-header-wrapper">
        <p class="header-text">Import Properties<p>
    </div>
    <div class="alert-actions-wrapper row">
        <button class="download-upload-button col" (click)="download()">Download template</button>
        <button [disabled]="breeamProjectOnly" class="download-upload-button col" (click)="triggerUpload()">Upload 
            <input id="upload-trigger" type="file" (change)="onFileSelected($event)"/>
        </button>
        <p class="note-text"><b>Note :</b> Existing properties with same Assest Reference name will be overridden</p>
    </div>
</div>