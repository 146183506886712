<div class="edit-project-wrapper">
    <div class="edit-header">Manage Project | <span>Edit</span> </div>
    <div class="row">
        <div class="col">
            <label class="project-label">Project Details</label>
            <div class="project-details-section">
                <form [formGroup]="editProjectForm">
                    <div class="row">
                        <div class="col">
                            <label class="font-wt" for="Project Name">Project Name</label>
                            <input class="project-input"formControlName="project_name">
                        </div>
                    </div>
                    <div class="row">   
                        <div class="col">
                        <!--<div class="col" *ngIf="projectDetails?.project.userArray.length" >-->     
                            <label class="font-wt" for="Project Name">Engagement Partner</label>
                            <input class="project-input" formControlName="engagement_partner">
                        </div>
                        <div class="col">
                            <label class="font-wt" for="Project Name">Engagement Partner Email</label>
                            <input class="project-input" formControlName="engagement_partner_email" (change)="partnerChangeEvent($event)">
                            <div *ngIf="editProjectForm.controls['engagement_partner_email']?.touched" class="email-error">  
                                <small class="text-danger" *ngIf="editProjectForm.controls['engagement_partner_email'].hasError('required')">
                                    This field is required
                                </small>
                                <small class="text-danger" *ngIf="editProjectForm.controls['engagement_partner_email'].hasError('admin')">
                                    Admin cannot be partner
                                </small>
                                <small class="text-danger" *ngIf="editProjectForm.controls['engagement_partner_email'].hasError('pattern')">Please provide a valid email address</small>
                                <small class="text-danger" *ngIf="editProjectForm.controls['engagement_partner_email'].hasError('duplicate')">Duplicate email address</small>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label class="font-wt" for="Project Name">Engagement Manager</label>
                            <input class="project-input" formControlName="engagement_manager">
                        </div>
                        <div class="col">
                            <label class="font-wt" for="Project Name">Engagement Manager Email</label>
                            <input class="project-input" formControlName="engagement_manager_email" (change)="managerChangeEvent($event)">
                            <div *ngIf="editProjectForm.controls['engagement_manager_email']?.touched" class="email-error">  
                                <small class="text-danger" *ngIf="editProjectForm.controls['engagement_manager_email'].hasError('required')">
                                    This field is required
                                </small>
                                <small class="text-danger" *ngIf="editProjectForm.controls['engagement_manager_email'].hasError('admin')">
                                    Admin cannot be manager
                                </small>
                                <small class="text-danger" *ngIf="editProjectForm.controls['engagement_manager_email'].hasError('pattern')">Please provide a valid email address</small>
                                <small class="text-danger" *ngIf="editProjectForm.controls['engagement_manager_email'].hasError('duplicate')">Duplicate email address</small>
                            </div>
                        </div>
                    </div>
                    <div class="row date-section">
                        <div class="col">
                            <label class="font-wt" for="Expected Start Date">Expected Start Date</label>
                            <mat-form-field>
                                <input matInput [matDatepicker]="startdatepicker" [max]="maxExpectedStartDate" formControlName="expected_start_date">
                                <mat-datepicker-toggle matSuffix [for]="startdatepicker"></mat-datepicker-toggle>
                                <mat-datepicker #startdatepicker>
                                    <mat-datepicker-actions>
                                        <button mat-button matDatepickerCancel>Cancel</button>
                                        <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                                    </mat-datepicker-actions>
                                </mat-datepicker>
                            </mat-form-field>
                        </div>
            
                        <div class="col">
                            <label class="font-wt" for="Expected End Date">Expected End Date</label>
                            <mat-form-field>
                                <input matInput [matDatepicker]="enddatepicker" formControlName="expected_end_date" [min]="editProjectForm.controls['expected_start_date'].value" (dateChange)="expectedEndDateEvent($event)">
                                <mat-datepicker-toggle matSuffix [for]="enddatepicker"></mat-datepicker-toggle>
                                <mat-datepicker #enddatepicker>
                                    <mat-datepicker-actions>
                                        <button mat-button matDatepickerCancel>Cancel</button>
                                        <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                                    </mat-datepicker-actions>
                                </mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row select-querstionnaire-wrapper">
                        <label class="select-questionnaire-label">Select Questionnaire</label>            
                        <mat-checkbox class="col" [disabled]="projectDetails?.project.breeam_status" formControlName="breeam_status">BREEAM</mat-checkbox>                        
                        <mat-checkbox class="col" [disabled]="projectDetails?.project.eu_status" formControlName="eu_status">EU TAXONOMY</mat-checkbox>
                        <div class="col"></div>                   
                    </div>
                </form>
            </div>
        </div>

        <div class="col">
            <label class="project-label">Users & Roles</label>
            <div class="users-roles-section">
                <form class="row col" [formGroup]="userForm">
                <table width="100%">
                    <tr class="row table-header">
                        <th class="col-1"></th>
                        <th class="col font-wt" style="right: 12px;">User</th>
                        <th class="col font-wt" style="right: 10px;">Email Id</th>
                        <th class="col font-wt" style="left: 10px;">Role</th>
                        <th class="col-1"></th>
                    </tr>
                    <tr class="row form-items-wrapper">
                        <td class="col-1">
                            <button class="fa fa-plus-square add-button" [disabled]="userForm.invalid" [ngStyle]="{'color': (userForm.valid) ? 'var(--color-red)' : 'var(--color-grey)'}" (click)="addUsers()"></button>
                        </td>
                        <td class="col-3">
                            <input type="text" class="col user-name" formControlName="user_name">
                        </td>
                        <td class="col-4">
                                    <input type="email" class="user-email" formControlName="email" (change)="userInputValidation($event)">
                                    <div *ngIf="userForm.controls['email']?.touched" class="">  
                                        <small class="text-danger" *ngIf="userForm.controls['email'].hasError('required')">
                                            This field is required
                                        </small>
                                        <small class="text-danger" *ngIf="userForm.controls['email'].hasError('incorrect')">
                                            This user exists
                                        </small>
                                        <small class="text-danger" *ngIf="userForm.controls['email'].hasError('pattern')">Please provide a valid email address</small>
                                    </div>
                        </td>
                        <td class="col-3">
                            <select name="" id="role-select" class="user-select" formControlName="role">
                                <option value="Admin" [disabled]="disableAdmin">Admin</option>
                                <option value="Contributor">Contributor</option>
                                <option value="Viewer">Viewer</option>
                            </select>
                        </td>                                                        
                        <td class="col-1"></td>
                    </tr>
                    <!-- <hr> -->
                    <tr class="row user-table-wrapper">
                        <div class="col-1"></div>
                        <table class="col" *ngIf="projectDetails?.project.userArray.length">
                            <tr class="" *ngFor="let user of usersToDisplay">
                                <td class="user-label">{{user?.user_name}}</td>
                                <td class="user-label">{{user?.email}}</td>
                                <td class="user-label">{{user.project_role || user.role}}</td>
                                <td>
                                    <i class="fa fa-pencil" aria-hidden="true" (click)="editUsers(user, projectDetails)"></i>
                                </td>
                                <td class=""> 
                                    <i class="fa fa-trash" aria-hidden="true" (click)="deleteUser(user.email, user.user_id)"></i>
                                </td>
                            </tr>
                        </table>
                    </tr>
                </table>
            </form> 
            </div>
        </div>
    </div>
    <div class="row action-wrapper">
        <div class="col-10"></div>
        <div class="col">
            <button class="back-btn" (click)="openProjects()">Back</button>
        </div>
        <div class="col">
            <button class="save-btn" (click)="updateProjectDetails()">Save</button>        
        </div>        
    </div>
</div>