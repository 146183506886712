import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProjectService } from 'src/app/core/services/project.service';
import { PropertiesListingService } from 'src/app/core/services/properties-listing.service';
import { QuestionnairesService } from 'src/app/core/services/questionnaires.service';
import { EuQuestionnaireService } from '../../services/euQuestionnaire.service';
import { QuestionnaireDialogComponent } from '../questionnaire-dialog/questionnaire-dialog.component';
import { SelectQuestionnaireDialogComponent } from '../select-questionnaire-dialog/select-questionnaire-dialog.component';

@Component({
  selector: 'app-duplicate-dialog',
  templateUrl: './duplicate-dialog.component.html',
  styleUrls: ['./duplicate-dialog.component.scss']
})
export class DuplicateDialogComponent implements OnInit {

  duplicateFlag: boolean = false;
  copyCount: any = 0;
  breeamYearSelected:  any;
  euYearSelected: any;
  duplicateForm: FormGroup;
  assetReferenceName:any;
  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<DuplicateDialogComponent>, public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any, public propertiesListing: PropertiesListingService, private _snackBar: MatSnackBar) { 
      this.duplicateForm = this.fb.group({
        asset_reference_name: [''],
        breeam_select_year: [''],
        eu_select_year: ['']
      });
    }



  ngOnInit(): void {
    console.log(this.data, "Duplicate data")
    this.duplicateFlag = true;
    this.assetReferenceName = this.data.property.asset_reference_name
  }

  closeDialog(){
    this.dialogRef.close();
  }

  showNotification(){
    this._snackBar.open('Duplicate Successfully', 'Success', {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: ['success']
    });
  }

  selectBreeamYear(event: any){
    if(event.target.value){
      this.breeamYearSelected = event.target.value;
      if(this.breeamYearSelected && this.assetReferenceName!== this.data.property.asset_reference_name)
        this.duplicateFlag = false;
      else
        this.duplicateFlag = true;
    }
  }

  assetNameEvent(event:any){
    console.log(this.data.property.asset_reference_name , this.assetReferenceName)
    this.assetReferenceName = event.target.value;
    if(event.target.value !== this.data.property.asset_reference_name && ( this.breeamYearSelected || this.euYearSelected))
      this.duplicateFlag = false
    else
      this.duplicateFlag = true
  }
  selectEuYear(event: any){
    if(event.target.value){
      this.euYearSelected = event.target.value;
      if(this.euYearSelected  && this.assetReferenceName!== this.data.property.asset_reference_name)
        this.duplicateFlag = false;
      else
        this.duplicateFlag = true;
    }
  }



  addNewProperty(){
    this.data.property.asset_reference_name = this.assetReferenceName
      this.propertiesListing.addProperty(this.data.property).subscribe(res=>{        
        this.showNotification();
        this.closeDialog();
      });
  }
}