<app-custom-progress-navigation></app-custom-progress-navigation>
<div class="project-details-wrapper" *ngIf="selectedNavIndex == 1? true:false">
    <form [formGroup]="projectForm">
        <div class="row">
            <div class="col">
                <label class="font-wt" for="Project Name">Project Name</label>
                <input class="project-input" formControlName="project_name">
            </div>
            <div class="col">
                <!-- <label for="Project Name">Client Industry Classification</label>
                <input formControlName="industryClassification"> -->
            </div>
            <div class="col"></div>
        </div>
        <div class="row">
            <div class="col">
                <label class="font-wt" for="Engagement Partner">Engagement Partner</label>
                <input class="project-input" formControlName="engagement_partner" (change)="partnerValidation($event)">                
                    <small class="text-danger" *ngIf="projectForm.controls['engagement_partner'].hasError('required')">
                        Engagement Partner is also required
                    </small>                
            </div>
            <div class="col">
                <label class="font-wt" for="Engagement Partner Email">Engagement Partner email</label>
                <input class="project-input" formControlName="engagement_partner_email" type="email" (change)="partnerEmailValidation($event)">
                
                    <small class="text-danger" *ngIf="projectForm.controls['engagement_partner_email'].hasError('required')">
                        This field is required
                    </small>
                    <small class="text-danger" *ngIf="projectForm.controls['engagement_partner_email'].hasError('SuperAdmin')">
                        Super admin cannot be added
                    </small>
                    <small class="text-danger" *ngIf="projectForm.controls['engagement_partner_email'].hasError('pattern')">Please provide a valid email address</small>
        
            </div>
            <div class="col"></div>
        </div>
        <div class="row">
            <div class="col">
                <label class="font-wt" for="Engagement Partner">Engagement Manager</label>
                <input class="project-input" formControlName="engagement_manager" (change)="managerValidation($event)">
                <small class="text-danger" *ngIf="projectForm.controls['engagement_manager'].hasError('required')">
                    This field is required
                </small>
            </div>
            <div class="col">
                <label class="font-wt" for="Engagement Partner Email">Engagement Manager email</label>
                <input class="project-input" formControlName="engagement_manager_email" (change)="managerEmailValidation($event)">                
                    <small class="text-danger" *ngIf="projectForm.controls['engagement_manager_email'].hasError('required')">
                        This field is required
                    </small>
                    <small class="text-danger" *ngIf="projectForm.controls['engagement_manager_email'].hasError('SuperAdmin')">
                        Super admin cannot be added
                    </small>
                    <small class="text-danger" *ngIf="projectForm.controls['engagement_manager_email'].hasError('pattern')">Please provide a valid email address</small>                
            </div>
            <div class="col"></div>
        </div>
        <div class="row">
            <div class="col">
                <label class="font-wt" for="Expected Start Date">Expected Start Date</label>
            <mat-form-field>
                <!--<mat-label>Expected Start Date</mat-label>-->
                <input matInput [matDatepicker]="startdatepicker" [max]="maxExpectedStartDate" formControlName="expected_start_date"
                required>
                <mat-datepicker-toggle matSuffix [for]="startdatepicker"></mat-datepicker-toggle>
                <mat-datepicker #startdatepicker>
                    <mat-datepicker-actions>
                      <button mat-button matDatepickerCancel>Cancel</button>
                      <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                    </mat-datepicker-actions>
                  </mat-datepicker>
            </mat-form-field>
            </div>

            <div class="col">
                <label class="font-wt" for="Expected End Date">Expected End Date</label>
            <mat-form-field>
                <!--<mat-label>Expected End Date</mat-label>-->
                <input matInput [matDatepicker]="enddatepicker" formControlName="expected_end_date"
                [min]="projectForm.controls['expected_start_date'].value" required (dateChange)="expectedEndDateEvent($event)">
                <mat-datepicker-toggle matSuffix [for]="enddatepicker"></mat-datepicker-toggle>
                <mat-datepicker #enddatepicker>
                    <mat-datepicker-actions>
                      <button mat-button matDatepickerCancel>Cancel</button>
                      <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                    </mat-datepicker-actions>
                  </mat-datepicker>
            </mat-form-field>
            </div>
            <div class="col"></div>
        </div>
        <div class="row select-querstionnaire-wrapper">
            <label class="select-questionnaire-label">Select Questionnaire</label>            
            <mat-checkbox class="col" formControlName="breeam_status" (change)="breeamChange($event)">BREEAM</mat-checkbox>                        
            <mat-checkbox class="col" formControlName="eu_status" (change)="euChange($event)">EU TAXONOMY</mat-checkbox>
            <div class="col"></div>                       
        </div>
    </form>
    <div class="action-wrapper">
        <button (click)="openProjectDashboard()">Back</button>
        <button class="next-button" [disabled]="(projectForm.invalid && !questionnaireSelect)" [ngStyle]="{'background': (projectForm.valid && questionnaireSelect) ? 'var(--color-red)' : 'var(--color-grey)'}" (click)="projectDetailsHold()">Next</button>
    </div>
</div>
<div *ngIf="selectedNavIndex == 2? true:false" class="add-user-wrapper">
    <mat-tab-group animationDuration="0ms">
        <mat-tab label="Internal User">
            <table width="100%">
                <tr class="row table-header">
                    <th class="col-1"></th>
                    <th class="col" style="position: relative;left: -86px;">User</th>
                    <th class="col" style="position: relative;left: -61px;">Email Id</th>
                    <th class="col">Role</th>
                    <th class="col-1"></th>
                </tr>
                <tr class="row form-items-wrapper">
                    <td class="col-1">
                        <button class="fa fa-plus-square add-button" [disabled]="internalUserForm.invalid" [ngStyle]="{'color': (internalUserForm.valid) ? 'var(--color-red)' : 'var(--color-grey)'}" (click)="addInternalUsers()"></button>
                    </td>
                    <td class="row col"> 
                        <form [formGroup]="internalUserForm">
                            <td><input style="top: -3px;left: -87px; width: 411px;position: relative;" type="text" class="col" formControlName="user_name"></td>
                            <td>
                                <input style="top:-3px;width: 448px;position: relative;left: -69px;" type="email" class="col" formControlName="email" required (change)="internalUserEmailValidation($event)">
                                <div *ngIf="internalUserForm.controls['email']?.dirty && (internalUserForm.controls['email']?.invalid || internalUserForm.controls['email']?.touched)" class="email-error">  
                                    <small class="text-danger" *ngIf="internalUserForm.controls['email'].hasError('required')">
                                        This field is required
                                    </small>
                                    <small class="text-danger" *ngIf="internalUserForm.controls['email'].hasError('pattern')">Please provide internal email address</small>
                                    <small class="text-danger" *ngIf="internalUserForm.controls['email'].hasError('duplicate')">User already exists</small>
                                    <small class="text-danger" *ngIf="internalUserForm.controls['email'].hasError('SuperAdmin')">
                                        Super admin cannot be added
                                    </small>
                                </div>
                            </td>
                            <td>
                                <select style="top:-3px; position: relative;left: -51px;width: 384px;" name="" id="role-select" class="col" formControlName="role">
                                    <option value="Admin">Admin</option>
                                    <option value="Contributor">Contributor</option>
                                    <option value="Viewer">Viewer</option>
                                </select>
                            </td>
                        </form>
                    </td>
                    <td class="col-1"></td>
                </tr>
                <hr style="position: relative;bottom: 9px;">
                <tr class="row user-table-wrapper">
                    <div class="col-1"></div>
                    <table class="col">
                    <tr class="row" *ngFor="let user of internalUserArray">
                        <label style="position: relative;right: 89px;" class="col">{{user.user_name}}</label>
                        <label style="position: relative;right: 76px;" class="col">{{user.email}}</label>
                        <label class="col">{{user.role}}</label>
                        <div class="col-1">
                            <i class="fa fa-trash" style="color:red" aria-hidden="true" (click)="deleteInternalUser(user.email)"></i>
                        </div>
                    </tr>
                    </table>
                </tr>
            </table>
        </mat-tab>
        <mat-tab label="External User">
            <table style="width: 100%;">
                <tr class="row table-header">
                    <th class="col-1"></th>
                    <th class="col" style="position: relative;left: -86px;">User</th>
                    <th class="col" style="position: relative;left: -61px;">Email Id</th>
                    <th class="col">Role</th>
                    <th class="col-1"></th>
                </tr>
                <tr class="row form-items-wrapper">
                    <td class="col-1">
                        <button class="fa fa-plus-square add-button" [disabled]="clientUserForm.invalid" [ngStyle]="{'color': (clientUserForm.valid) ? 'var(--color-red)' : 'var(--color-grey)'}" (click)="addClientUsers()"></button>
                    </td>
                    <td class="row col"> 
                        <form [formGroup]="clientUserForm">
                            <td><input style="top: -3px;left: -87px; width: 411px;position: relative;" type="text" class="col" formControlName="user_name"></td>
                            <td>
                                <input style="top:-3px;width: 448px;position: relative;left: -69px;" type="email" class="col" formControlName="email" required (change)="externalUserEmailValidation($event)">
                                <div *ngIf="clientUserForm.controls['email']?.invalid && (clientUserForm.controls['email']?.dirty || clientUserForm.controls['email']?.touched)" class="email-error">  
                                    <small class="text-danger" *ngIf="clientUserForm.controls['email'].hasError('required')">
                                        This field is required
                                    </small>
                                    <small class="text-danger" *ngIf="clientUserForm.controls['email'].hasError('duplicate')">User already exists</small>
                                    <small class="text-danger" *ngIf="clientUserForm.controls['email'].hasError('pattern')">Please provide external email address</small>
                                    <small class="text-danger" *ngIf="internalUserForm.controls['email'].hasError('SuperAdmin')">
                                        Super admin cannot be added
                                    </small>
                                </div>
                            </td>
                            <td>
                                <select style="top:-3px; position: relative;left: -51px;width: 384px;" name="" id="role-select" class="col" formControlName="role">
                                    <option value="Contributor">Contributor</option>
                                    <option value="Viewer">Viewer</option>
                                </select>
                            </td>
                        </form>
                    </td>
                    <td class="col-1"></td>
                </tr>
                <hr style="position: relative;bottom: 9px;">
                <tr class="row user-table-wrapper">
                    <div class="col-1"></div>
                    <table class="col">
                        <tr class="row" *ngFor="let user of clientUserArray">
                            <label style="position: relative;right: 89px;"  class="col">{{user.user_name}}</label>
                            <label style="position: relative;right: 76px;" class="col">{{user.email}}</label>
                            <label class="col">{{user.role}}</label>
                            <div class="col-1">
                                <i class="fa fa-trash" style="color:red" aria-hidden="true" (click)="deleteClientUser(user.email)"></i>
                            </div>
                        </tr>
                    </table>
                </tr>
            </table>
        </mat-tab>
      </mat-tab-group>
    <div class="action-wrapper">
        <button (click)="openCreateProjectDetails()">Back</button>
        <button class="next-button" [disabled]="!userValidationFlag" [ngStyle]="{'background': (userValidationFlag) ? 'var(--color-red)' : 'var(--color-grey)'}" (click)="submitUsers()">Next</button>
    </div>
</div>

<div *ngIf="selectedNavIndex == 3? true:false" class="preview-wrapper">
    <div class="row">
        <div class="property-details-inner-wrapper col">
            <label>Project Details</label>
            <div class="property-details-inner">
                <div class="row heading-details">
                    <label class="col">Project Name</label>
                </div>
                <div class="row">
                    <label class="col">{{projectDetails.project_name}}</label>
                </div>
                <div class="row heading-details">
                    <label class="col">Engagement Partner</label>
                    <label class="col">Engagement Manager</label>
                </div>
                <div class="row">
                    <label class="col">{{projectDetails.engagement_partner}}</label>
                    <label class="col">{{projectDetails.engagement_manager}}</label>
                </div>
                <div class="row heading-details">
                    <label class="col">Expected Start Date</label>
                    <label class="col">Expected End Date</label>
                </div>
                <div class="row">
                    <label class="col">{{projectDetails.expected_start_date}}</label>
                    <label class="col">{{projectDetails.expected_end_date}}</label>
                </div>
                <div class="row heading-details">
                    <label class="col">Selected Questionnaire</label>
                </div>
                <div class="row">
                    <label class="col" *ngIf="projectDetails.breeam_status">BREEAM</label>
                    <label class="col" *ngIf="projectDetails.eu_status">EU TAXONOMY</label>
                </div>
            </div>
        </div>
        <div class="users-roles-inner-wrapper col">
            <label>Users & Roles</label>
            <div class="users-roles-inner">
                <table style="width:100%">
                    <tr class="header-border" >
                        <th>User</th>
                        <th>Email Id</th>
                        <th>Role</th>
                    </tr>
                    <tr class="content-border" *ngFor="let user of internalUserArray">
                        <td>{{user.user_name}}</td>
                        <td>{{user.email}}</td>
                        <td>{{user.role}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div class="action-wrapper">
        <button (click)="openUserRole()">Back</button>
        <button class="next-button" (click)="createProject()">Create Project</button>
    </div>
</div>