<div class="edit-wrapper">
    <form [formGroup]="editForm">
        <div>
            <div class="row">
                <label class="col">User: </label>
                <input class="col" type="text" formControlName="user_name" (change)="edit($event)">
            </div>
        </div>
        <div>
            <div class="row">
                <label class="col">Email Id:</label>
                <input class="col" type="text" formControlName="email" (change)="emailValidator($event)">
                <div *ngIf="editForm.controls['email']?.invalid && (editForm.controls['email']?.dirty || editForm.controls['email']?.touched)" class="email-error">  
                    <small class="text-danger warning-msg" *ngIf="editForm.controls['email'].hasError('required')">
                        This field is required
                    </small>
                    <!-- <small class="text-danger warning-msg" *ngIf="editForm.controls['email'].hasError('internalPattern')">Please provide internal email address</small>
                    <small class="text-danger warning-msg" *ngIf="editForm.controls['email'].hasError('externalPattern')">Please provide external email address</small> -->
                </div>
                <!--<div *ngIf="editForm.controls['email']?.dirty" class="email-error">  
                    <small class="text-danger" *ngIf="editForm.controls['email'].hasError('pattern')">
                        This  user is external
                    </small>
                </div>-->  
            </div>
        </div>
        <div>
            <div class="row">
                <label class="col">User Type:</label>   
                <select class="col" formControlName="user_type" change="edit($event)">
                    <!--<option *ngIf="isInternal">Internal</option>
                    <option *ngIf="!isInternal">External</option>-->
                    <option [disabled]="disableAdmin">Internal</option>
                    <option [disabled]="!disableAdmin">External</option>
                </select>
            </div>
        </div>
        <div>
            <div class="row">
                <label class="col">User Role:</label>   
                <select class="col" formControlName="project_role" change="edit($event)">
                    <option [disabled]="disableAdmin">Admin</option>
                    <option>Contributor</option>
                    <option>Viewer</option>
                </select>
            </div>
        </div>
    </form>   
    <div class="row">
        <div class="col"></div>
        <div class="col alert-actions-wrapper">
            <button (click)="closeDialog()">Cancel</button>
            <button class="apply-btn" (click)="updateUsers()">Update</button>
        </div>
    </div> 
</div>