import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { PropertiesListingService } from '../../services/properties-listing.service';

@Component({
  selector: 'app-property-submission-overlay',
  templateUrl: './property-submission-overlay.component.html',
  styleUrls: ['./property-submission-overlay.component.scss']
})

export class PropertySubmissionOverlayComponent implements OnInit {
  @Input() selectedPropertiesArray:any;
  constructor(public propertiesListingService: PropertiesListingService) { }
  submissionView:boolean = false;
  submitQuestionnaire(): void {
    this.propertiesListingService.submissionView.subscribe(res =>{
      this.submissionView = res;       
    })
  }

  submitAPICall(){
    this.propertiesListingService.calculateTheResultsOfProperties({properties : this.selectedPropertiesArray}).subscribe(res =>{
      console.log("Project submission Success");
      this.propertiesListingService.submissionView.next(false);
    })
  }

  backClick(){
    this.submissionView = false;
  }

  ngOnInit(): void {
    this.submitQuestionnaire();
  }

}
