import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-indication',
  templateUrl: './progress-indication.component.html',
  styleUrls: ['./progress-indication.component.scss']
})
export class ProgressIndicationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  dashSaveAndContinueButton() {
    console.log('button is clicked')
  }
}
