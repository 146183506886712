import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectService } from 'src/app/core/services/project.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-project-archive',
  templateUrl: './project-archive.component.html',
  styleUrls: ['./project-archive.component.scss']
})
export class ProjectArchiveComponent implements OnInit {
  displayedColumns: string[] = ['name', 'owner', 'engagementPartner', 'engagementManager', 'startDate', 'endDate', 'lastSaved', 'status' , 'open'];
  dataSource : any ;
  p: number = 1;
  userRoleAccess: any;
  noProjectFlag:boolean = false;
  constructor( private userService:UserService, private projectService: ProjectService, private route : Router) { }

  ngOnInit(): void {
    this.getAllProject();
    this.getUserDetails();
  }
  getUserDetails(){
    this.userService.currentUserDetails.subscribe((res:any) =>{
      this.userRoleAccess = res;   
      if(sessionStorage.getItem('role') === 'Admin' || sessionStorage.getItem('role') === 'SuperAdmin')
        this.displayedColumns = ['name', 'owner','engagementPartner', 'engagementManager', 'startDate', 'endDate', 'lastSaved', 'open', 'unarchive'];
      else
        this.displayedColumns = ['name', 'owner', 'engagementPartner', 'engagementManager', 'startDate', 'endDate', 'lastSaved', 'open', 'unarchive'];         
    });
  }

  getAllProject(){
    this.projectService.getAllProjectsOfUser(true).subscribe(res => {
      this.dataSource = res;
      this.noProjectFlag = true;
    })    
  }

  searchProject(event: any, archiveStatus: boolean){
    this.projectService.getSearchResults(event.target.value, archiveStatus).subscribe(res => {
      this.dataSource = res;
    })
  }


  navigateToProperties(element:any){
    sessionStorage.setItem('project_id', element.project_id);    
    this.projectService.currentProjectId.next(element.project_id);
    this.projectService.currentProjectData.next(element);
    this.route.navigate(['/dashboard', element.project_id])
  }

  navigateToEdit(project:any){
    this.route.navigate(['/edit-project' , project.project_id])
  }

  unarchiveProperty(item:any){
    item.archive_status = false;
    this.projectService.archiveProject(item).subscribe((res:any)=>{
      this.getAllProject();
    });
  }

  endDateExpired(element:any){    
    let endDate = new Date(element.expected_end_date)
    console.log(new Date(), endDate)
    if(endDate < new Date())
      return 'end-date-expired'
    else
      return ''      
  }
}
