<div class="edit-querstionnaire-wrapper">
    <div class="edit-q-header bg-dark text-white">
        <label class="h6"> Edit questionnaires</label>
        <button (click)="closeDialog()" class="fa fa-times fa-lg bg-dark text-white close-button"></button>
    </div>
    <div class="edit-q-content">
        <p class="h6 text-dark fw-bolder">Select questionnaire</p>
        <hr style="border: 1px solid; color: #25B4E8;">
        <div class="d-inline">
            <span style="margin-left: 20px; font-size: medium;">BREEAM</span>
        <select class="form-select" id="propertyId" (change)="breeamSelect($event)">
            <option value="" disabled selected hidden>Property type</option>
            <option value="Commercial">Commercial</option>
            <option value="Residential">Residential</option>
        </select>
            <button type="button" (click)="openQuestionnaireDialog('BREEAM')" class="text-white edit-button" [disabled]="breeamFlag" [style.background]="!breeamFlag ? 'var(--color-blue-light)' : '' ">Edit</button>
        </div>
        <hr>
        <div class="d-inline">
            <span style="margin-left: 20px; font-size: medium;">EU Taxonomy</span>
        <select class="form-select" style="margin-left: 32px;" (change)="euSelect($event)">
            <option value="" disabled selected hidden>Property type</option>
            <option value="Commercial">Commercial</option>
            <option value="Residential">Residential</option>
        </select>
        <button type="button" (click)="openQuestionnaireDialog('EU_Taxonomy')" class="text-white edit-button" [disabled]="euFlag" [style.background]="!euFlag ? 'var(--color-blue-light)': ''">Edit</button>
        </div>
        
    </div>
</div>