import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  currentUserDetails = new BehaviorSubject({});
  constructor(private http: HttpClient) { }

  getCurrentUserDetails(email:any){
    return this.http.get(`${environment.apiUrl}/user/email?email_id=${email}`)
  }
}
