import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PropertiesListingService } from 'src/app/core/services/properties-listing.service';
import { QuestionnairesService } from 'src/app/core/services/questionnaires.service';
import { EuQuestionnaireService } from '../../services/euQuestionnaire.service';
import { QuestionnaireDialogEuComponent } from '../questionnaire-dialog-eu/questionnaire-dialog-eu.component';
import { QuestionnaireDialogComponent } from '../questionnaire-dialog/questionnaire-dialog.component';

@Component({
  selector: 'app-select-questionnaire-dialog',
  templateUrl: './select-questionnaire-dialog.component.html',
  styleUrls: ['./select-questionnaire-dialog.component.scss']
})
export class SelectQuestionnaireDialogComponent implements OnInit {

  questionnairesList:any = [];
  currentQuestion:any;
  currentObject:any;
  breeamQuestionnaireFlag: boolean = false;
  euQuestionnaireFlag: boolean = false;
  propertyType :string = ''; 
  breeamPercentage:any = '0';
  euPercentage:any = '0';
  breeamActionText: string = 'Start';
  euActionText: string = 'Start';
  breeamTickMark: string = '#adadad';
  euTickMark: string = '#adadad';
  disableBreeamCheckbox:boolean = true;
  disableEUCheckbox:boolean = true;
  registerFlag:boolean = true;
  questionEditFlag: boolean = false;
  questionEditFlagEU: boolean = false;
  constructor(public dialogRef: MatDialogRef<SelectQuestionnaireDialogComponent>, public dialog: MatDialog, 
    private questionnaireService: QuestionnairesService, @Inject(MAT_DIALOG_DATA) public data: any, public euService : EuQuestionnaireService,
    public propertiesListing: PropertiesListingService, private _snackBar: MatSnackBar) {
    }

  ngOnInit(): void {
    if(this.data.property.breeam_answers != 0)
      this.breeamPercentage =((parseInt(this.data.property.breeam_answers)/parseInt(this.data.property.breeam_total_questions))*100).toFixed(2);;
    if(this.data.property.eu_answers != 0)
      this.euPercentage =((parseInt(this.data.property.eu_answers)/parseInt(this.data.property.eu_total_questions))*100).toFixed(2);    
    if(this.data.property.breeam_select_status)
      this.breeamQuestionnaireFlag = true;
    if(this.data.property.eu_select_status)
      this.euQuestionnaireFlag = true;
    if(this.data.project.breeam_status)
      this.disableBreeamCheckbox = false;
    if(this.data.project.eu_status)
      this.disableEUCheckbox = false;     
    if(this.data.property.questionnaire_status == "Submitted"){
      this.euQuestionnaireFlag = false;
      this.breeamQuestionnaireFlag = false;
      this.disableBreeamCheckbox = true;
      this.disableEUCheckbox = true;   
    }
    if(this.data.property.actionText == "Reviewed"){
      this.disableBreeamCheckbox = true;
      this.disableEUCheckbox = true;   
    }
    this.actionValue();
    console.log(this.data)
  }

  showNotification(){
    this._snackBar.open('Questionnaire Updated Successfully', 'Success', {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: ['success']
    });
}
  openDialog(category_type: string){
    if(this.data.registerSelection !== false  && this.data.property.questionnaire_status !== "Submitted")
      this.registerSelection({property_id : this.data.property.property_id, breeam_select_status : this.breeamQuestionnaireFlag, eu_select_status : this.euQuestionnaireFlag});
    const dialogRef = this.dialog.open(QuestionnaireDialogComponent, {data: {propertyType: this.data.property.property_type, categoryType: category_type, propertyId: this.data.property.property_id, assetReferenceName: this.data.property.asset_reference_name,
     yearOfSubmission: this.data.property.year_of_submission,questionnaire_status : this.data.property.questionnaire_status , euQuestionnaireDate: this.data.property.eu_questionnaire_date,
     review_status: this.data.property.review_status, actionText : this.data.property.actionText
    }});
    dialogRef.afterClosed().subscribe(result => {
      this.showNotification();
      this.dialogRef.close();
    });
    this.actionValue();
    //this.actionValueBreeam();
    //this.actionValueEu();
  }

  openDialogEu(category_type: string){
    if(this.data.registerSelection !== false && this.data.property.questionnaire_status !== "Submitted")
      this.registerSelection({property_id : this.data.property.property_id, breeam_select_status : this.breeamQuestionnaireFlag, eu_select_status : this.euQuestionnaireFlag});
    const dialogRef = this.dialog.open(QuestionnaireDialogEuComponent, {data: {propertyType: this.data.property.property_type, categoryType: category_type, propertyId: this.data.property.property_id, assetReferenceName: this.data.property.asset_reference_name,
     yearOfSubmission: this.data.property.year_of_submission,questionnaire_status : this.data.property.questionnaire_status , euQuestionnaireDate: this.data.property.eu_questionnaire_date,
     review_status: this.data.property.review_status, actionText : this.data.property.actionText
    }});
    dialogRef.afterClosed().subscribe(result => {
      this.showNotification();
      this.dialogRef.close();
    });
    this.actionValue();
    //this.actionValueBreeam();
    //this.actionValueEu();
  }

  breeamSelect(event: any){
    if(event.srcElement.checked){
      this.breeamQuestionnaireFlag = true;
      this.propertyType = event.target.value;
      this.questionEditFlag = false;
    } 
    else{
      this.breeamQuestionnaireFlag = false;
      this.questionEditFlag = true;
    }
  }

  euSelect(event: any){
    if(event.srcElement.checked){
      this.euQuestionnaireFlag = true;
      this.propertyType = event.target.value;
      this.euService.isEUChecked.next(true);
      this.questionEditFlagEU = false;
    } else{
      this.euQuestionnaireFlag = false;
      this.euService.isEUChecked.next(false);
      this.questionEditFlagEU = true;
    }
  }

  registerSelection(data:any){
    this.propertiesListing.questionnaireSelection(data).subscribe(res =>{

    })
  }

  actionValue(){
    if(this.data.property.breeam_select_status){
      this.questionEditFlag = false;
      if(this.data.property.breeam_answers == 0 && this.breeamPercentage == '0'){
        this.breeamActionText = "Start";
        this.breeamTickMark = "#adadad";
      }
      if(this.data.property.breeam_answers > 0 && this.data.property.breeam_answers < this.data.property.breeam_total_questions ){
        this.breeamActionText = "Continue";
        this.breeamTickMark = "#ffb600";
      }
      if(this.data.property.questionnaire_status == "Submitted" || this.breeamPercentage == '100.00'){
        this.breeamActionText = "View";
        this.breeamTickMark = "#24987B";
      }
    }
    else{
      this.questionEditFlag = true;
      if(this.data.property.breeam_answers == 0 && this.breeamPercentage == '0'){
        this.breeamActionText = "Start";
      }
      if(this.data.property.breeam_answers > 0 ){
        this.breeamActionText = "Continue";
        this.breeamTickMark = "#adadad";
      }
      if(this.data.property.questionnaire_status == "Submitted" && this.breeamPercentage == '100.00'){
        this.breeamActionText = "View";
        this.breeamTickMark = "#adadad";
      }
    }

    if(this.data.property.eu_select_status){
      this.questionEditFlagEU = false;
      if(this.data.property.eu_answers == 0 && this.euPercentage == '0'){
        this.euActionText = "Start";
        this.euTickMark = "#adadad";
      }
      if(this.data.property.eu_answers > 0 ){
        this.euActionText = "Continue";
        this.euTickMark = '#ffb600';
      }
      if(this.data.property.questionnaire_status == "Submitted" || this.euPercentage == '100.00'){
        this.euActionText = "View";
        this.euTickMark = "#24987B";
      }
    }
    else{
      this.questionEditFlagEU = true;
      if(this.data.property.eu_answers == 0 && this.euPercentage == '0'){
        this.euActionText = "Start";
      }
      if(this.data.property.eu_answers > 0 ){
        this.euActionText = "Continue";
        this.euTickMark = "#adadad";
      }
      if(this.data.property.questionnaire_status == "Submitted" && this.euPercentage == '100.00'){
        this.euActionText = "View";
        this.euTickMark = "#adadad";
      }
    }
  }
}