<app-custom-progress-navigation></app-custom-progress-navigation>
<div class="subfilter-summary-wrapper" *ngIf="currentTabSelected == 1">
  <div class="row">
    <label class="col" [ngClass]="highlightBuilding ? 'highlight-red' : 'disable-grey'" (click)="subFilterClickEvent($event)">Building</label>
    <label class="col" [ngClass]="highlightOccupier ? 'highlight-red' : 'disable-grey'" (click)="subFilterClickEvent($event)">Occupier</label>
    <label class="col" [ngClass]="highlightLocation ? 'highlight-red' : 'disable-grey'" (click)="subFilterClickEvent($event)">Location</label>
  </div>
</div>
<div class="filter-wrapper">
  <div class="breeam-label-year" *ngIf="breeamResults" [style.display]="currentTabSelected == 1? 'none' : 'inline-flex'">
    <div class="label-year-1"></div>
    <label style="position: relative; right: 11px" for="">{{yearOfSubmissionArray[1].year_of_submission}}</label>
    <div *ngIf="yearOfSubmission.value?.length > 1" class="label-year-2"></div>
    <label *ngIf="yearOfSubmission.value?.length > 1" for="">{{yearOfSubmissionArray[0].year_of_submission}}</label>
  </div>
  
  <mat-form-field appearance="fill">
    <mat-label>Property Type</mat-label>
    <mat-select [formControl]="propertyTypeSelect" multiple (selectionChange)="filterOnPrimarySelect()">
      <mat-select-trigger>
        {{propertyTypeSelect.value ? propertyTypeSelect.value[0]?.name : ''}}
        <span *ngIf="propertyTypeSelect.value?.length > 1">
          (+{{propertyTypeSelect.value?.length - 1}} {{propertyTypeSelect.value?.length === 2 ? 'other' : 'others'}})
        </span>
      </mat-select-trigger>
      <mat-option *ngFor="let propertyType of propertyTypeList" [value]="propertyType">{{propertyType?.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Country</mat-label>
    <mat-select [formControl]="countrySelect" multiple (selectionChange)="filterOnPrimarySelect()">
      <mat-select-trigger>
        {{countrySelect.value ? countrySelect.value[0]?.country : ''}}
        <span *ngIf="countrySelect.value?.length > 1">
          (+{{countrySelect.value?.length - 1}} {{countrySelect.value?.length === 2 ? 'other' : 'others'}})
        </span>
      </mat-select-trigger>
      <mat-option *ngFor="let country of allSelectedPropertiesResult?.properties.countries" [value]="country">
        {{country?.country}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Years</mat-label>
    <mat-select  [formControl]="yearOfSubmission" multiple (selectionChange)="filterOnPrimarySelect()">
      <mat-select-trigger>
        {{yearOfSubmission.value ? yearOfSubmission.value[0]?.year_of_submission : ''}}
        <span *ngIf="yearOfSubmission.value?.length > 1">
          (+{{yearOfSubmission.value?.length - 1}} {{yearOfSubmission.value?.length === 2 ? 'other' : 'others'}})
        </span>
      </mat-select-trigger>
      <mat-option *ngFor="let property of allSelectedPropertiesResult?.properties.yearOfSubmission" [disabled]="property.year_of_submission==projectData?.year_of_creation" [value]="property">
        {{property?.year_of_submission}}</mat-option>
    </mat-select>
  </mat-form-field>
  <button class="download-btn"  (click)="downloadEvent()">Download</button>
</div>
<div class="row tab-header-wrapper">
  <mat-tab-group animationDuration="0ms" class="col-12" style="min-height: 100vh;"
    (selectedTabChange)="onTabChanged($event);">
    <div *ngIf="assessmentResults">
      <mat-tab label="Assessment">
        <div class="row all-results-wrapper">
          <div class="all-properties-result-wrapper col-6">
            <label>All Properties</label>
            <div class="chart-wrapper" style="height: 300px" *ngIf="breeamResults">
              <canvas baseChart class="chart-canvas" [datasets]="allPropertyBarChartData"
                [labels]="allPropertyBarChartLabels" [options]="barChartOptions" [plugins]="barChartPlugins"
                [legend]="barChartLegend" [type]="barChartType">
              </canvas>

              <div class="average-score-wrapper">
                <!-- <mat-slider thumbLabel [displayWith]="formatLabel" min="0" max="100" step="1" aria-label="Score"
                [(value)]="allpropertyTotalAverage"></mat-slider> -->
                <app-custom-slider [sliderValue]="allpropertyTotalAverage"></app-custom-slider>
                <div class="assessment-level-wrapper row">
                  <button class="col">Un-classified</button>
                  <button class="col">Acceptable</button>
                  <button class="col">Pass</button>
                  <button class="col">Good</button>
                  <button class="col">Very Good</button>
                  <button class="col">Excellent</button>
                  <button class="col">Outstanding</button>
                </div>
              </div>
            </div>
            <div *ngIf="!breeamResults" style="position: relative; height:35vh; width:35vw">
              <div class="chart-wrapper" style="height: 200px;">
                <canvas baseChart [data]="doughnutChartData" [labels]="doughnutChartLabels" [type]="doughnutChartType"
                  [options]="donutOptions">
                </canvas>
                <label class="compliance-percentage-text">{{doughnutChartData?.datasets[0]?.data[0].toFixed(2)}}%</label>
                <label class="non-compliance-percentage-text">{{doughnutChartData?.datasets[0]?.data[1].toFixed(2)}}%</label>
              </div>
            </div>
            <div class="chart-index-wrapper" *ngIf="!breeamResults">
              <div><div class="red-box"></div><label>EU Taxonomy Compliance</label></div>
              <div><div class="grey-box"></div><label>Non-Compliance</label></div>                  
            </div>
          </div>
          <div class="chart-wrapper col-6">
            <div class="property-select-wrapper row">
              <div class="col-8">
                <label for="">Select Property</label>
                <span>(select one or more properties in order to compare individual assessment)</span>
              </div>
              <div class="col-4">
                <mat-form-field appearance="fill">
                  <mat-label>Property search</mat-label>
                  <mat-select [formControl]="propertySelect" multiple (selectionChange)="getSelectedPropertyResult()">
                    <mat-select-trigger>
                      {{propertySelect.value ? propertySelect.value[0]?.asset_reference_name : ''}}
                      <span *ngIf="propertySelect.value?.length > 1" class="example-additional-selection">
                        (+{{propertySelect.value?.length - 1}} {{propertySelect.value?.length === 2 ? 'other' :
                        'others'}})
                      </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let property of allSelectedPropertiesResult?.properties.propertyData"
                      [value]="property">{{property.asset_reference_name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="propertySelect.value?.length && breeamResults">
              <mat-card class="property-result-chart-wrapper" style="height: 350px"
                *ngFor="let propertyResult of allSelectedPropertiesResult?.properties.propertyResult">
                <mat-card-title-group>
                  <mat-card-title>{{propertyResult?.asset_reference_name}}</mat-card-title>
                </mat-card-title-group>
                <mat-card-content>
                  <canvas baseChart class="chart-canvas" [datasets]="propertyResult?.barChartData"
                    [labels]="propertyResult?.barChartLabels" [options]="barChartOptions" [plugins]="barChartPlugins"
                    [legend]="barChartLegend" [chartType]="barChartType">
                  </canvas>
                  
                  <div class="average-score-wrapper row">
                    <!-- <mat-slider thumbLabel [displayWith]="formatLabel" min="0" max="100" aria-label="Score"
                  [(value)]="propertyResult.sumOfCategories"></mat-slider> -->
                    <app-custom-slider [sliderValue]="propertyResult.sumOfCategories"></app-custom-slider>
                    <div class="assessment-level-wrapper row">
                      <button class="col">Un-classified</button>
                      <button class="col">Acceptable</button>
                      <button class="col">Pass</button>
                      <button class="col">Good</button>
                      <button class="col">Very Good</button>
                      <button class="col">Excellent</button>
                      <button class="col">Outstanding</button>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div *ngIf="propertySelect.value?.length && !breeamResults">
              <mat-card class="property-result-eu-chart-wrapper"
                *ngFor="let euResult of euSelectedPropertiesResult?.properties.propertyResult">
                <mat-card-title-group>
                  <mat-card-title>{{euResult?.asset_reference_name}}</mat-card-title>
                </mat-card-title-group>
                <mat-card-content>
                  <div class="chart-wrapper">
                    <canvas baseChart [data]="euResult.doughnutChart" [labels]="doughnutChartLabels"
                      [type]="doughnutChartType" [options]="donutOptions">
                    </canvas>
                      <label class="selected-compliance-percentage-text">{{euResult.doughnutChart?.datasets[0]?.data[0].toFixed(2)}}%</label>
                      <label class="selected-non-compliance-percentage-text">{{euResult.doughnutChart?.datasets[0]?.data[1].toFixed(2)}}%</label>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="selected-prop-chart-index-wrapper" *ngIf="propertySelect.value?.length && !breeamResults">
              <div><div class="red-box"></div><label>EU Taxonomy Compliance</label></div>
              <div><div class="grey-box"></div><label>Non-Compliance</label></div>                  
            </div>
          </div>
        </div>
      </mat-tab>
    </div>
    <div *ngIf="summaryResult">
      <mat-tab label="Summary" *ngIf="breeamResults">
        <div class="property-select-summary">
          <mat-form-field appearance="fill">
            <mat-label>property</mat-label>
            <mat-select (selectionChange)="selectPropertySummary($event)">
              <mat-option *ngFor="let property of breeamPropertiesListSummary" [value]="property">
                {{property.asset_reference_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <table *ngIf="summaryDataSource?.length" mat-table [dataSource]="summaryDataSource"
          class="mat-elevation-z8 result-summary-table">
          <ng-container matColumnDef="category">
            <th *matHeaderCellDef class="summary-header header-border-bottom"> Category </th>
            <td *matCellDef="let element" class="highlight-border-bottom">
              {{element.category_name}}
            </td>
          </ng-container>

          <ng-container matColumnDef="criteria">
            <th *matHeaderCellDef class="summary-header header-border-bottom highlight-red"> Criteria </th>
            <td *matCellDef="let element" class="highlight-red highlight-border-bottom">
              {{element.category_name}}
            </td>
          </ng-container>

          <ng-container matColumnDef="current_status">
            <th *matHeaderCellDef class="summary-header header-border-bottom"> Current Status </th>
            <td *matCellDef="let element" class="highlight-border-bottom">
              <ul>
                <li *ngFor="let answer of element.questions">{{answer.answers[0].answer}}</li>
              </ul>
            </td>
          </ng-container>

          <ng-container matColumnDef="potential">
            <th *matHeaderCellDef class="summary-header header-border-bottom"> Potential Improvement </th>
            <td *matCellDef="let element" class="highlight-border-bottom">
              <ul>
                <li *ngFor="let answer of element.questions">{{answer.answers[0].potential_improvement? answer.answers[0].potential_improvement : '-'}}</li>
              </ul>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </mat-tab>
    </div>
  </mat-tab-group>
</div>