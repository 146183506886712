import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { ProjectService } from 'src/app/core/services/project.service';
import { PropertiesListingService } from 'src/app/core/services/properties-listing.service';
import { SelectQuestionnaireDialogComponent } from 'src/app/shared/components/select-questionnaire-dialog/select-questionnaire-dialog.component';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { ReopenWarningComponent } from '../reopen-warning/reopen-warning.component';

@Component({
  selector: 'app-admin-project-results-visibility',
  templateUrl: './admin-project-results-visibility.component.html',
  styleUrls: ['./admin-project-results-visibility.component.scss']
})
export class AdminProjectResultsVisibilityComponent implements OnInit {

  task: any = {
    name: 'Breeam',
    checked: false,    
    subtasks: [
      {name: 'Assessment', checked: false},
      {name: 'Summary', checked: false}
    ]
  };
  progressData:any = [
    {name:"Properties", status:"active", leftVal:"4%", visible : true },
    {name:"Publish", status:"active", leftVal:"17%", visible : true },
    {name:"Results", status:"inactive", leftVal:"17%", visible : true },
    {name:"BREEAM", status:"inactive", leftVal:"17%", visible : false },
    {name:"EU Taxonomy", status:"inactive", leftVal:"17%", visible : false }];
  allComplete: boolean = false;
  filterCriteria:any = [];
  euCheckFlag:boolean = false;
  euFilterCriteria = { name : "EU Taxonomy", checked: false}
  resultsChecked:boolean = false;
  displayedColumns: string[] = ['property_id','asset_reference_name', 'country', 'city', 'zip_code', 'address', 'property_type' , 'size_of_property', 'current_market_value', 'breeam_select_status'];
  propertiesList:any;
  p: number = 1;
  selectedProperty:any;
  selectedPropertiesArray:any = [];
  projectId:any;
  projectDetails:any;
  constructor(private projectService: ProjectService, private propertiesListing: PropertiesListingService, private _snackBar: MatSnackBar, private loaderService: LoaderService, private route: Router,
    public dialog: MatDialog, private _liveAnnouncer: LiveAnnouncer) { }

  ngOnInit(): void {
    this.loaderService.subTitle.next('Admin');    
    this.loaderService.progressStage.next(this.progressData);
    this.projectService.currentProjectId.subscribe((res:any)=>{
      this.projectId = res;
      this.getProjectDetails();
      this.getAllProperties();
    })
  }

  /*@ViewChild(MatSort) sort: MatSort | any;

  ngAfterViewInit() {
    this.propertiesList.properties.sort = this.sort;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }*/

  updateAllComplete() {
    this.allComplete = this.task.subtasks != null && this.task.subtasks.every((t:any) => t.checked);
  }

  someComplete(): boolean {
    if (this.task.subtasks == null) {
      return false;
    }
    return this.task.subtasks.filter((t:any) => t.checked).length > 0 && !this.allComplete;
  }

  setAll(checked: boolean) {
      this.allComplete = checked;
        if (this.task.subtasks == null) {
          return;
        }
      this.task.checked = true;
      this.task.subtasks.forEach((t:any) => (t.checked = checked));

  }

  getProjectDetails(){
    this.projectService.getProjectDetails(this.projectId).subscribe((res:any) =>{
      this.projectDetails = res.projectDetails;
      this.task.subtasks[0].checked = res.projectDetails.result_filter.breeam_assessment;
      this.task.subtasks[1].checked = res.projectDetails.result_filter.breeam_summary;
      this.euCheckFlag = res.projectDetails.result_filter.eu_taxonomy;
      if(this.task.subtasks[0].checked || this.task.subtasks[1].checked  || this.euCheckFlag)
        this.resultsChecked = true;
      else
      this.resultsChecked = false;
    });
  }

  getAllProperties(){
    this.propertiesListing.getAllProperties(this.projectId).subscribe((res: any) =>{
      this.propertiesList = res;
      this.selectedPropertiesArray = this.propertiesList.properties.filter((prop:any) => prop.published_status == true);
      console.log(this,this.selectedPropertiesArray, "Array")
    })

    console.log(this.propertiesList);
  }

  resultsVisibility(event:any){
    this.selectedPropertiesArray = this.selectedPropertiesArray.map((prop:any)  => ({
      property_id: prop.property_id,
      published_status: prop.published_status
    })); 
    
    if(event.checked){ 
        this.filterCriteria = {
          breeam_assessment : this.task.subtasks[0].checked,
          breeam_summary : this.task.subtasks[1].checked,
          eu_taxonomy : this.euFilterCriteria.checked
        }
        this.setFilterCriteria();        
      }else{
        this.allComplete = false;
        this.selectedPropertiesArray.forEach((prop:any) =>{
          prop.published_status = false
        });
        this.filterCriteria = {
          breeam_assessment : false,
          breeam_summary : false,
          eu_taxonomy : false
        }
        this.setFilterCriteria();
        

      }
    
   }

   setFilterCriteria(){
    this.projectService.setFilterCriteria(this.projectId ,{result_filter : this.filterCriteria}).subscribe((res:any) =>{   
      this.publishTheResults({properties : this.selectedPropertiesArray});     
    })
   }

   euFilterEvent(event:any){
    this.euFilterCriteria.checked = event.checked;
    this.resultsChecked = false;
   }

   showNotification(){
    this._snackBar.open('Results updated', 'Success', {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: ['success']
    });
  }

  pageChanged(event:any){
    console.log(event)
  }

  propertySelect(property:any, event:any){
    this.resultsChecked = false;
    if(event.checked){
      this.selectedProperty = property;
      if(!this.selectedPropertiesArray.find((prop:any) => prop.property_id == property.property_id)){
        property.published_status = true;
        this.selectedPropertiesArray.push(property)
      }else{
        this.selectedPropertiesArray.find((prop:any) => prop.property_id == property.property_id).published_status = true;
      }
        
    } else{
      if(this.selectedPropertiesArray.find((prop:any) => prop.property_id == property.property_id)){
        let index = this.selectedPropertiesArray.findIndex((prop:any) => prop.property_id == property.property_id);
        this.selectedPropertiesArray[index].published_status = false;
      }
      this.selectedProperty = null;      
    }
    console.log(this.selectedPropertiesArray)
  }  

  publishTheResults(data:any){
    this.propertiesListing.publishResultsOfProperties(data).subscribe((res:any) =>{
      setTimeout(()=>{      
        this.getProjectDetails();
        this.getAllProperties();
        this.showNotification();
      },200)

    });
  }

  backNavigation(){
    this.route.navigate(['/project-dashboard']);
  }
  openSelectDialog(property:any){
    const dialogRef = this.dialog.open(SelectQuestionnaireDialogComponent, {
      width: '450px',
      height: '250px',
      data: {
        property
      }
    });
  }

  reopenAction(property:any) {
    this.dialog.open(ReopenWarningComponent, {
      width: '450px',
      data: {
        property
      }
    });
  }
}
