<div class="row">
    <div class="col-3 search-wrapper">
      <input type="text" class="search-input" matInput placeholder="Search" (keyup)="searchProject($event, true)">
      <i class="fa fa-search text-danger"></i>
    </div>
    <div class="col-9"></div>
<div class="main-wrapper">
<table mat-table [dataSource]="dataSource?.projects | paginate: { itemsPerPage: 8, currentPage: p }" class="project-dash-table-wrapper">    
    <ng-container matColumnDef="name">
      <th *matHeaderCellDef class="header header-border-bottom">Name 
        <i class="fa fa-ellipsis-v"></i>
      </th>

      <td *matCellDef="let element" class="font-w-600"> {{element.project_name}} </td>
    </ng-container>
  
    <ng-container matColumnDef="owner">
      <th *matHeaderCellDef class="header header-border-bottom">Owner
        <i class="fa fa-ellipsis-v"></i>
      </th>
      <td  *matCellDef="let element"> {{element.owner}} </td>
    </ng-container>

    <ng-container matColumnDef="engagementPartner">
      <th *matHeaderCellDef class="header header-border-bottom">Engagement Partner
        <i class="fa fa-ellipsis-v"></i>
      </th>
      <td  *matCellDef="let element"> {{element.engagement_partner}} </td>
    </ng-container>
    
    <ng-container matColumnDef="engagementManager">
      <th *matHeaderCellDef class="header header-border-bottom">Engagement Manager
        <i class="fa fa-ellipsis-v"></i>
      </th>
      <td  *matCellDef="let element"> {{element.engagement_manager}} </td>
    </ng-container>
  
    <ng-container matColumnDef="startDate">
      <th  *matHeaderCellDef class="header header-border-bottom">Start Date
        <i class="fa fa-ellipsis-v"></i>
      </th>
      <td  *matCellDef="let element"> {{element.expected_start_date | date : 'dd/MM/YYYY'}} </td>
    </ng-container>

    <ng-container matColumnDef="endDate">
      <th  *matHeaderCellDef class="header header-border-bottom">End Date
        <i class="fa fa-ellipsis-v"></i>
      </th>
      <td  *matCellDef="let element" [ngClass]="endDateExpired(element)"> {{element.expected_end_date | date : 'dd/MM/YYYY'}} </td>
    </ng-container>

    <ng-container matColumnDef="lastSaved">
        <th  *matHeaderCellDef class="header header-border-bottom">Last saved
            <i class="fa fa-ellipsis-v"></i>
        </th>
        <td  *matCellDef="let element"> {{element.updated_date | date : 'dd/MM/YYYY'}} </td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th  *matHeaderCellDef class="header header-border-bottom">Status 
            <i class="fa fa-ellipsis-v"></i>
        </th>
        <td  *matCellDef="let element" class="font-w-600" [style.color]="element.project_status === 'Finished' ? '#299D8F' : '#E0301E'"> {{element.project_status}} </td>
    </ng-container>

    <ng-container matColumnDef="open">
      <th></th>
      <td></td>
        <th  *matHeaderCellDef class="header header-border-bottom">Open 
            <i class="fa fa-ellipsis-v open-manage-header-icon"></i>
        </th>
        <td  class="col-open"  *matCellDef="let element"> <a class="fa fa-external-link" style="text-decoration: none;
          color: #25B4E8; padding-left: 11px;" (click)="navigateToProperties(element)"></a> </td>
    </ng-container>
    <ng-container matColumnDef="unarchive">
        <th  *matHeaderCellDef class="header header-border-bottom">Unarchive 
            <i class="fa fa-ellipsis-v open-manage-header-icon"></i>
        </th>
        <td  class="col-open"  *matCellDef="let element" class="edit-button-wrapper">
          <mat-icon *ngIf="!endDateExpired(element)" class="unarchive-icon" (click)="unarchiveProperty(element)">unarchive</mat-icon>
        </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
</div>
<div *ngIf="!dataSource?.projects?.length && noProjectFlag" class="no-data-display">No projects assigned</div>
<pagination-controls *ngIf="dataSource?.projects?.length" class="my-pagination" (pageChange)="p = $event" previousLabel="Previous" nextLabel="Next"></pagination-controls>