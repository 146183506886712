import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-slider',
  templateUrl: './custom-slider.component.html',
  styleUrls: ['./custom-slider.component.scss']
})
export class CustomSliderComponent implements OnInit {
  @Input() sliderValue?: any = 0;
  constructor() { }

  ngOnInit(): void {
  }
  
  mySliderValue(){
    if(this.sliderValue > 100)
      return '100%';
    return this.sliderValue + '%';
  }
}
