
<div class="row questionnaire-edit-dialog">
    <div class="col-md-3 left-pane">
        <form [formGroup]="categoryForm">
        <ul class="prog-timeline">
            <li class="prog-done row" [ngClass]="data.categoryType  == 'BREEAM' ? 'prog-item': '' " *ngFor="let category of questionnairesList">
                <div class="col-1">
                    <button type="button" style="width: 15px;height: 15px;margin-top: -10px;"  *ngIf="category.selectedItem" [disabled]="addCategoryFlag" mat-icon-button (click)="addNewCategory()"><mat-icon >add</mat-icon></button>
                </div>
                
                <label [style.color]="category.selectedItem? '#008387':''" 
                    [style.font-weight]="category.selectedItem ? 'bolder' : 'normal'" href="#"
                    id="{{category.category_id}}" class="prog-label col-7" (click)="categoryClick($event)">                    
                    <input [style.display]="data.categoryType == 'EU_Taxonomy'? 'none': 'block'" formControlName="category_weight_{{category.category_id}}" (change)="categoryEditEvent($event)" type="text" class="category-weightage-input" value="{{category.weightage}}%">
                    <input formControlName="category_name_{{category.category_id}}" (change)="categoryEditEvent($event)" class="new-catgeory-input" *ngIf="category.category_id == 'new'">    
                    <span>
                            {{category.category_name}} 
                   </span>
                </label>

                <select [style.display]="data.categoryType == 'EU_Taxonomy'? 'none': 'block' " class="col-4" formControlName="sub_category_{{category.category_id}}" (change)="categoryEditEvent($event)">
                    <option value="{{sub.name}}" [selected]="sub.selected" *ngFor="let sub of category.sub_categories">{{sub.name}}</option>                    
                </select>

            </li>
        </ul>
        </form>
    </div>

    <div class="col-md-9 right-pane">
        <div class="dialog-header row">
            <div class="col-6">
                <div class="mat-close"> 
                    <!--<button (click)="closeDialog()" class="fa fa-angle-left" style="font-size: 11px;" aria-hidden="true"></button>-->
                    <button (click)="closeDialog()" href="#" style="font-size: xx-small;"> <i class="fa fa-angle-left back-icon"></i>
                        Back to edit questionnaires
                    </button>
                </div>
                <span class="property-name">{{data.categoryType}} | {{data.propertyType}} |</span> <span [style.display]="data.categoryType == 'EU_Taxonomy'? 'none': 'inline'" [style.color]="total_weightage > 100 ? 'red' : 'white'">{{ total_weightage}}% </span>
            </div>
            <div class="col-6">
                <button class="save-and-close-btn" (click)="saveQuestionnaire(true)">Save and close</button>
            </div>
        </div>
        <mat-dialog-content class="mat-typography">
            <div class="questions-wrapper">
                <form [formGroup]="questionnaireForm" (ngSubmit)="onSubmit()">
                    <form [formGroup]="answerForm">
                    <table mat-table [dataSource]="dataSource" [fixedLayout]="false">
                        <ng-container matColumnDef="code">
                            <th mat-header-cell *matHeaderCellDef><button type="button" [disabled]="addQuestionFlag" mat-icon-button (click)="addNewQuestionnaire()" (click)="scrollToElement(scrollMe)"><mat-icon >add</mat-icon></button></th>
                            <td mat-cell *matCellDef="let element"><input [style.border]="element.question_code? 'none': '1px solid var(--color-black)'" class="question-code-input" type="text" value="{{element.question_code}}" 
                                (change)="questionEditEvent($event)" formControlName="question_code_{{element.question_id}}"/></td>
                        </ng-container>

                        <ng-container matColumnDef="question">
                            <th mat-header-cell *matHeaderCellDef> Questions </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="question-input-wrapper">
                                    <textarea [style.border]="element.question_item? 'none': '1px solid var(--color-black)'" (change)="questionEditEvent($event)" formControlName="question_item_{{element.question_id}}"  value="{{element.question_item}}"></textarea>                                    
                                </div>
                                <div>
                                    <label><b>Criteria :</b></label><input (change)="questionEditEvent($event)" formControlName="criteria_{{element.question_id}}" value="{{element.criteria}}" />                                    
                                </div>
                                <mat-checkbox (change)="optionalCheckEvent($event, element.question_id)" value=true [checked]="element.optional_status"
                                    formControlName="optional_status_{{element.question_id}}">Optional</mat-checkbox>
                                <div>
                                    <label><b>Help Text :</b> </label><input (change)="questionEditEvent($event)" formControlName="question_info_{{element.question_id}}" value="{{element.question_info}}" />
                                </div>
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="answers">
                            <th mat-header-cell *matHeaderCellDef> Answers </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="answer-border-spacing"></div>
                                <ul>
                                    <li *ngFor="let ans of element?.answers" class="answer-item-wrapper">
                                        <div class="row col" *ngIf="!ans.delete_status">
                                            <button (click)="addAnswersRow($event,element )" class="col-2" type="button" mat-icon-button><mat-icon >add</mat-icon></button>
                                            <input class="col-7" [style.border]="ans.answer? 'none': '1px solid var(--color-black)'" formControlName="answer_{{ans.answer_id}}" (change)="answerEditEvent($event, element, ans)" value={{ans.answer}} />
                                            <button (click)="removeAnswerRow(element, ans, $event )" formControlName="delete_status_{{ans.answer_id}}" class="col-2" type="button" mat-icon-button><mat-icon >remove</mat-icon></button>
                                        </div>

                                    </li>
                                </ul>
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="score">
                            <th mat-header-cell *matHeaderCellDef> Scoring</th>
                            <td mat-cell *matCellDef="let element">
                                <div class="question-scoring-wrapper row">
                                    <div class="col-6"><input (change)="questionEditEvent($event)" formControlName="question_score_{{element.question_id}}" value="{{element.score}}"/></div>                                   
                                </div>
                                <ul>
                                    <div *ngFor="let ans of element?.answers" class="score-item-parent-wrapper">
                                        <li  class="row score-item-wrapper" *ngIf="!ans.delete_status">
                                            <input class="score-input" (change)="answerEditEvent($event, element, ans)" formControlName="score_{{ans.answer_id}}" value={{ans.score}}/>                                            
                                        </li>
                                    </div>

                                </ul>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="sorting">
                            <th mat-header-cell *matHeaderCellDef> Order By</th>
                            <td mat-cell *matCellDef="let element">
                                <div class="answer-border-spacing"></div>
                                <ul>
                                    <div *ngFor="let ans of element?.answers" class="score-item-parent-wrapper">
                                        <li *ngIf="!ans.delete_status" class="row score-item-wrapper">
                                           <input type="number" class="score-input" (change)="answerEditEvent($event, element, ans)" formControlName="sorting_order_{{ans.answer_id}}" value={{ans.sorting_order}}/>
                                        </li>
                                    </div>
                                </ul>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="potential_improvement">
                            <th mat-header-cell *matHeaderCellDef> Potential Improvement</th>
                            <td mat-cell *matCellDef="let element">
                                <div class="answer-border-spacing">
                                    <mat-checkbox (change)="piCheckEvent($event, element.question_id)" value="true" [checked]="element.potential_improvement_status"
                                    formControlName="potential_improvement_status_{{element.question_id}}"></mat-checkbox>
                                </div>
                                <ul>
                                    <li *ngFor="let ans of element?.answers" class="row potential-improvement-wrapper">
                                        <div *ngIf="!ans.delete_status" class="col-12"><textarea class="potential-improvement-input" (change)="answerEditEvent($event, element, ans)" formControlName="potential_improvement_{{ans.answer_id}}" value={{ans.potential_improvement}}></textarea></div>
                                    </li>
                                </ul>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef> Action</th>
                            <td mat-cell *matCellDef="let element">
                                <button *ngIf="!element.question_delete_status" formControlName="question_delete_status_{{element.question_id}}" (click)="deleteQuestionEvent($event, true)"><i class="fa fa-trash" aria-hidden="true"></i></button>
                                <button *ngIf="element.question_delete_status" formControlName="question_delete_status_{{element.question_id}}" (click)="deleteQuestionEvent($event, false)"><i class="fa fa-undo" aria-hidden="true"></i></button>
                                
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    </form>
                </form>
                <div #scrollMe></div>
            </div>

        </mat-dialog-content>
            <div class="row question-pagination-wrapper">
                <div class="col-md-1"><button>
                        <i class="fa fa-arrow-circle-left" (click)="previousCategoryAction()"></i>
                    </button></div>
                <div class="col-md-10"></div>
                <div class="col-md-1">
                    <button>
                        <i class="fa fa-arrow-circle-right" (click)="nextCategoryAction()"></i>
                    </button>
                </div>
            </div>
    </div>
</div>
