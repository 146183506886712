<div class="edit-wrapper">
    <form formGroup="editForm">
        <div>
            <div class="row">
                <label class="col">Asset Reference Name: </label>
                <input class="col" type="text" formControlName="asset_reference_name" (change)="edit($event)" value="{{data.property.asset_reference_name}}">
            </div>
        </div>
        <div>
            <div class="row">
                <label class="col">Country:</label>
                <input class="col" type="text" formControlName="country" (change)="edit($event)" value="{{data.property.country}}">  
            </div>
        </div>
        <div>
            <div class="row">
                <label class="col">City:</label>
                <input class="col" type="text" formControlName="city" (change)="edit($event)" value="{{data.property.city}}">    
            </div>
        </div>
        <div>
            <div class="row">
                <label class="col">Zip Code:</label>
                <input class="col" type="text" formControlName="zip_code" (change)="edit($event)" value="{{data.property.zip_code}}">              
            </div>
        </div>
        <div>
            <div class="row">
                <label class="col">Address:</label>
                <input class="col" type="text" formControlName="address" (change)="edit($event)" value="{{data.property.address}}">        
            </div>
        </div>
        <div>
            <div class="row">
                <label class="col">Size of property(sq m):</label>
                <input class="col" type="text" formControlName="size_of_property" (change)="edit($event)" value="{{data.property.size_of_property}}">     
            </div>
        </div>
        <div>
            <div class="row">
                <label class="col">Currency:</label>
                <!--<input type="text" style="margin-left: 110px; border-radius: 4px;" formControlName="currency" (change)="edit($event)" value="{{data.property.currency}}">--> 
                <select formControlName="currency" [value]="data.property.currency" class="col" (change)="edit($event)">
                    <option value="" disabled selected>Select Currency</option>-->
                    <option class="text-black" value="EUR" label="EUR">Europe €</option>
                    <option class="text-black" value="BGN" label="BGN">Bulgaria лв</option>
                    <option class="text-black" value="CHF" label="CHF">Switzerland CHF</option>
                    <option class="text-black" value="CZK" label="CZK">Czechia Kč</option>
                    <option class="text-black" value="DKK" label="DKK">Denmark kr</option>
                    <option class="text-black" value="GBP" label="GBP">UK &#xA3;</option>
                    <option class="text-black" value="HRK" label="HRK">Croatia kn</option>
                    <option class="text-black" value="GEL" label="GEL">Georgia &#x20BE;</option>
                    <option class="text-black" value="RUB" label="RUB">Russia 	&#x20BD;</option>
                    <option class="text-black" value="TRY" label="TRY">Turkey 	&#x20BA;</option>
                    <option class="text-black" value="UAH" label="UAH">Ukraine 	&#x20B4;</option>
                    <option class="text-black" value="USD" label="USD">US dollar &#x24;</option>
                    <option class="text-black" value="INR" label="INR">India &#x20B9;</option>
                    <option class="text-black" value="CNY" label="CNY">China 	&#x5143;</option>
                    <option class="text-black" value="YEN" label="YEN">Japan &#xa5;</option>
                    <option class="text-black" value="PLN" label="PLN">Polish złoty</option>
                    <option class="text-black" value="HUF" label="HUF">HUF </option>
                    <option class="text-black" value="RON" label="RON">RON </option>
                    <option class="text-black" value="SKR" label="SKR">SKR </option>
                    <option class="text-black" value="NOK" label="NOK">NOK </option>
                    <option class="text-black" value="BAM" label="BAM">BAM </option>
                    <option class="text-black" value="TRL" label="TRL">TRL</option>
                    <option class="text-black" value="RSD" label="RSD">RSD</option>
                    <option class="text-black" value="RON" label="RON">RON </option>
                    <option class="text-black" value="MKD" label="MKD">MKD</option>
                </select>
            </div>
        </div>
        <div>
            <div class="row">
                <label class="col">Current Market Value:</label>
                <input class="col" type="number" min="0" formControlName="current_market_value" (change)="edit($event)" value="{{data.property.current_market_value}}">
            </div>
        </div>
    </form>
    <div class="row">
        <div class="col"></div>
        <div class="col alert-actions-wrapper">
            <button (click)="closeDialog()">Cancel</button>
            <button class="apply-btn" (click)="editDuplicateProperty()">Apply</button>
        </div>
    </div>
</div>