import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-footer-dialog-one',
  templateUrl: './footer-dialog-one.component.html',
  styleUrls: ['./footer-dialog-one.component.scss']
})
export class FooterDialogOneComponent implements OnInit {

  constructor(public footerDialog: MatDialogRef<FooterDialogOneComponent>) { }

  ngOnInit(): void {
  }

  closeDialog(){
    this.footerDialog.close();
  } 
}
