<footer>
  <div class="esg-footer-wrapper row">
    <div class="col-12">
      <label>© 2022 PricewaterhouseCoopers GmbH Wirtschaftsprüfungsgesellschaft. All rights reserved. PwC refers to the PwC network 
        and/or one or more of its member firms, each of which is a separate legal entity. Please see www.pwc.com/structure for further details. 
      </label> 
    </div>
    <div class="col-12 link">
      <label (click)="openPrivacy()"> Privacy Statement </label>
      <label><a href="https://www.pwc.de/de/impressum.html" target="_blank">Imprint (Legal) Notice </a></label>
      <label (click)="openTerm()"> Terms of License </label>
    </div> 
  </div>
</footer>

