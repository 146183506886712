import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectService } from 'src/app/core/services/project.service';
import { SelectQuestionnaireDialogComponent } from 'src/app/shared/components/select-questionnaire-dialog/select-questionnaire-dialog.component';

@Component({
  selector: 'app-reopen-warning',
  templateUrl: './reopen-warning.component.html',
  styleUrls: ['./reopen-warning.component.scss']
})
export class ReopenWarningComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ReopenWarningComponent>, public dialog: MatDialog, private projectService: ProjectService) { }

  ngOnInit(): void {
    console.log(this.data)
  }
  openSelectDialog(){
    this.dialogRef.close()
    this.data.property.questionnaire_status = 'Reopen'
    const dialogRef = this.dialog.open(SelectQuestionnaireDialogComponent, {
      width: '450px',
      height: '250px',
      data: {property : this.data.property, registerSelection : false}
    });
  }

  reopenProperty(){
    this.projectService.reopenProject(this.data.property).subscribe((res:any) => {
      this.openSelectDialog();
    });
  }
}
