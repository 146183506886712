<app-property-submission-overlay *ngIf="submitView" [selectedPropertiesArray]="selectedPropertiesArray"></app-property-submission-overlay>
<button class="send-for-review" [disabled]="reviewStatus" (click)="sendForReiview()">{{(projectDetails?.project_role =='Admin' || projectDetails?.project_role =='SuperAdmin')? 'Reviewed' : 'Send For Review'}}</button>
<button *ngIf="projectDetails?.project_role !== 'Viewer'" class="dash-save-continue-button" [disabled]= "!finalSubmissionBtn" (click)="submitAnswersForSelectedProperties()">
   Final Submission
</button>
<!-- <app-progress-indication></app-progress-indication> -->
<app-custom-progress-navigation></app-custom-progress-navigation>

<!--<div class="row" *ngIf="projectDetails?.project_role !== 'Viewer'">-->
<div class="row action-row-wrapper">
    <div class="col">
         <button class="import-btn" (click)="openImportPropertiesAlert()">Import Properties</button> 
    </div>
    <!--<div class="col-1 col-sm-1"></div>-->
    <div class="col search-wrapper">
            <input (keyup)="filterBase($event)" class="search-input" placeholder="Search"/>
            <i class="fa fa-search text-danger"></i>
    </div>
    <div class="col-3"></div>
    <div class="col-4 action-btn-wrapper">
        <button class="a-mr-10 duplicate-btn col"               
            [config]="secondaryConfig" 
            [disabled]="selectedPropertiesArray.length != 1"
            [ngStyle]="selectedPropertiesArray.length != 1 ? {'color': '#ADADAD', 'border-color': '#ADADAD' } : {}"
            (click)="downloadPropertyTemplate()">
            <span class="fa fa-clone"></span>Download
        </button>
        <button class="a-mr-10 duplicate-btn col"               
                [config]="secondaryConfig" 
                [disabled]="selectedPropertiesArray.length != 1"
                [ngStyle]="selectedPropertiesArray.length != 1 ? {'color': '#ADADAD', 'border-color': '#ADADAD' } : {}"
                (click)="openDuplicateDialog()">
            <span class="fa fa-clone"></span>Duplicate
        </button>
        
        <button class="a-mr-10 duplicate-btn col"               
                [config]="secondaryConfig"
                [disabled]="deleteStatusFlag"
                [ngStyle]="deleteStatusFlag? {'color': '#ADADAD', 'border-color': '#ADADAD' } : {}"
                (click)="openDeleteDialog()">
            <span class="fa fa-trash-o"></span>Delete
        </button>

        <button class="a-mr-10 duplicate-btn col"                
                [config]="secondaryConfig"
                [disabled]="editStatusFlag"
                [ngStyle]="editStatusFlag? {'color': '#ADADAD', 'border-color': '#ADADAD' } : {}"
                (click)="openEditDialog()">
            <span class="fa fa-pencil"></span>Edit
        </button>
    </div>
</div>

<form [formGroup]="propertyForm" class="property-form">
    <div class="list-properties-wrapper">
        <table class="dashboard-table" style="width:100%">
            <tr style="box-shadow: 0 4px 6px -6px #222;" *ngIf="projectDetails?.project_role !== 'Viewer'">
                <th></th>
                <th>
                    <span (click)="sortTableData('desc', 'property_id')" class="fa fa-arrow-up desc-hovr"></span>
                    <span (click)="sortTableData('asc', 'property_id')" class="fa fa-arrow-down asc-hovr"></span>
                    Asset Reference Name
                    <i class="fa fa-ellipsis-v asset-name-ellipsis"></i>
                </th>
                <th>
                    <span (click)="sortTableData('desc', 'country')" class="fa fa-arrow-up desc-hovr"></span>
                    <span (click)="sortTableData('asc', 'country')" class="fa fa-arrow-down asc-hovr"></span>
                    Country
                    <i class="fa fa-ellipsis-v country-ellipsis"></i>
                </th>
                <th>
                    <span (click)="sortTableData('desc', 'city')" class="fa fa-arrow-up desc-hovr"></span>
                    <span (click)="sortTableData('asc', 'city')" class="fa fa-arrow-down asc-hovr"></span>
                    City
                    <i class="fa fa-ellipsis-v city-ellipsis"></i>
                </th>
                <th>
                    <span (click)="sortTableData('desc', 'zip_code')" class="fa fa-arrow-up desc-hovr"></span>
                    <span (click)="sortTableData('asc', 'zip_code')" class="fa fa-arrow-down asc-hovr"></span>
                    Zip Code
                    <i class="fa fa-ellipsis-v zip-code-ellipsis"></i>
                </th>
                <th>
                    <span (click)="sortTableData('desc', 'address')" class="fa fa-arrow-up desc-hovr"></span>
                    <span (click)="sortTableData('asc', 'address')" class="fa fa-arrow-down asc-hovr"></span>
                    Address
                    <i class="fa fa-ellipsis-v address-ellipsis"></i>
                </th>
                <th>
                    <span (click)="sortTableData('desc', 'property_type')" class="fa fa-arrow-up desc-hovr"></span>
                    <span (click)="sortTableData('asc', 'property_type')" class="fa fa-arrow-down asc-hovr"></span>
                    Property Type
                    <i class="fa fa-ellipsis-v property-type-ellipsis"></i>
                </th>
                <th>
                    <span (click)="sortTableData('desc', 'size_of_property')" class="fa fa-arrow-up desc-hovr"></span>
                    <span (click)="sortTableData('asc', 'size_of_property')" class="fa fa-arrow-down asc-hovr"></span>
                    Size of property(sq m)
                    <i class="fa fa-ellipsis-v size-of-property-ellipsis"></i>
                </th>
                <th>
                    <span (click)="sortTableData('desc', 'current_market_value')" class="fa fa-arrow-up desc-hovr"></span>
                    <span (click)="sortTableData('asc', 'currency-market-value')" class="fa fa-arrow-down asc-hovr"></span>
                    Current Market Value
                    <i class="fa fa-ellipsis-v currency-market-value-ellipsis"></i>
                </th>
                <th>
                    Questionnaire<i class="fa fa-ellipsis-v questionnaire-ellipsis"></i>
                </th>
                <th>
                    Conflict<i class="fa fa-ellipsis-v archive-ellipsis"></i>
                </th>
            </tr>
            <tr style="box-shadow: 0 4px 6px -6px #222;" *ngIf="projectDetails?.project_role !== 'Viewer' && authorized">
                <td><button class="fa fa-plus-square add-property-btn" [disabled]="propertyForm.invalid" [ngStyle]="{'color': (propertyForm.valid) ? 'var(--color-red)' : 'var(--color-grey)'}" (click)="addNewProperty()"></button></td>
                <td><input class="row-2-input asset-reference-input" formControlName="asset_reference_name" placeholder="Add new property"></td>
                <td><input class="row-2-input" formControlName="country" placeholder="Country"></td>
                <td><input class="row-2-input" formControlName="city" placeholder="City"></td>
                <td><input class="row-2-input" formControlName="zip_code" placeholder="XXXXXX-XX"></td>
                <td><input class="row-2-input" formControlName="address" placeholder="Address"></td>
                <td>            
                    <select formControlName="property_type" placeholder="Type">
                        <option value="" disabled selected>Select Type</option>
                        <option class="text-black" value="Residential">Residential</option>
                        <option class="text-black" value="Commercial">Commercial</option>
                    </select>
                </td>
                <td><input class="row-2-input asset-reference-input" formControlName="size_of_property" (keydown)="numberCheck($event)"
                    (keyup)="commaFormattedArea($event)" placeholder="Size"></td>
                <td>
                    <div style="display: inline-flex;">
                        <select formControlName="currency" placeholder="Currency" class="add-prop-select-currency" [(ngModel)]="selectedProperty" (change)="currencySelect($event)">
                            <option value="" disabled selected>Select Currency</option>
                            <option class="text-black" value="EUR" label="EUR">Europe €</option>
                            <option class="text-black" value="BGN" label="BGN">Bulgaria лв</option>
                            <option class="text-black" value="CHF" label="CHF">Switzerland CHF</option>
                            <option class="text-black" value="CZK" label="CZK">Czechia Kč</option>
                            <option class="text-black" value="DKK" label="DKK">Denmark kr</option>
                            <option class="text-black" value="GBP" label="GBP">UK &#xA3;</option>
                            <option class="text-black" value="HRK" label="HRK">Croatia kn</option>
                            <option class="text-black" value="GEL" label="GEL">Georgia &#x20BE;</option>
                            <option class="text-black" value="RUB" label="RUB">Russia 	&#x20BD;</option>
                            <option class="text-black" value="TRY" label="TRY">Turkey 	&#x20BA;</option>
                            <option class="text-black" value="UAH" label="UAH">Ukraine 	&#x20B4;</option>
                            <option class="text-black" value="USD" label="USD">US dollar &#x24;</option>
                            <option class="text-black" value="INR" label="INR">India &#x20B9;</option>
                            <option class="text-black" value="CNY" label="CNY">China 	&#x5143;</option>
                            <option class="text-black" value="YEN" label="YEN">Japan &#xa5;</option>
                            <option class="text-black" value="PLN" label="PLN">Polish złoty</option>
                            <option class="text-black" value="HUF" label="HUF">HUF </option>
                            <option class="text-black" value="RON" label="RON">RON </option>
                            <option class="text-black" value="SKR" label="SKR">SKR </option>
                            <option class="text-black" value="NOK" label="NOK">NOK </option>
                            <option class="text-black" value="BAM" label="BAM">BAM </option>
                            <option class="text-black" value="TRL" label="TRL">TRL</option>
                            <option class="text-black" value="RSD" label="RSD">RSD</option>
                            <option class="text-black" value="RON" label="RON">RON </option>
                            <option class="text-black" value="MKD" label="MKD">MKD</option>
                        </select>
                        <input class="row-2-input" formControlName="current_market_value"  (keydown)="numberCheck($event)"
                        (keyup)="commaFormattedCurrency($event)" min="0" placeholder="Value" style="width: 100px; margin-left: 8px;">     
                    </div>
                </td>
                
                <td style="text-align: center;">
                    <!-- <button class="properties-open-btn">Open</button><button
                        class="icon-circle-checkmark-outline"></button> -->
                </td>
                <td style="text-align: center;">
                    <!-- <button class="icon-folder-closed-outline"></button> -->
                </td>
            </tr>
            <tr *ngFor="let property of propertiesList.properties | paginate: { itemsPerPage: 8, currentPage: p }" class="prop-content">
                <td style="text-align: center;"><label *ngIf="projectDetails?.project_role !== 'Viewer' && !projectDetails?.archive_status" class="label-red"><input type="checkbox" class="select-checkbox" (change)="propertySelect(property, $event)"><span class="checkmark"></span></label></td>
                <td><label class="prop-label">{{property.asset_reference_name}}</label></td>
                <td><label class="prop-label">{{property.country}}</label></td>
                <td><label class="prop-label">{{property.city}}</label></td>
                <td><label class="prop-label">{{property.zip_code}}</label></td>
                <td style="width: 10%;"><label>{{property.address}}</label></td>
                <td><label class="prop-label">{{property.property_type}}</label></td>
                <td><label class="size-of-pro-label">{{property.size_of_property | number}}</label></td>
                <td >
                    <div class="currency-market-value">
                        <label class="" style="padding: 0 2px;">{{property.currency}}</label>
                        <label class="" dir="rtl">{{property.current_market_value | number}}</label>
                    </div>
                </td>
                <!--<td>
                    <div style="display: inline-flex;">
                        <label>{{property.current_market_value | number}}</label>
                        <label>{{property.currency}}</label>
                    </div>
                </td>-->
                <td style="text-align: center;">
                    
                    <button class="properties-open-btn" [style.background]="property.actionColor" data-target="#exampleModalFive" data-toggle="modal" (click)="openSelectDialog(property)">
                        {{property.actionText}}</button>
                    <i class="fa fa-check-circle-o" aria-hidden="true" style="font-size: 20px;" [style.color]="property.tickMark"></i>                    
                </td>
                <td style="text-align: center;">
                    <mat-icon class="warning-icon" *ngIf="property.conflicts_pending" class="warning-icon">warning</mat-icon>
                    <!-- <i class="fa fa-folder-o archive-icon"></i> -->
                </td>
            </tr>
        </table>
        
    </div>
</form>
<div *ngIf="!propertiesList?.properties?.length && !noPropertyFlag" class="no-data-display">No properties added</div>
<pagination-controls *ngIf="propertiesList?.properties?.length" class="my-pagination" (pageChange)="p = $event" (pageChange)="pageChanged($event)" previousLabel="Previous" nextLabel="Next"></pagination-controls>