import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ProjectService } from 'src/app/core/services/project.service';
import { PropertiesListingService } from 'src/app/core/services/properties-listing.service';
import { LoaderService } from 'src/app/shared/services/loader.service';

@Component({
  selector: 'app-admin-results',
  templateUrl: './admin-results.component.html',
  styleUrls: ['./admin-results.component.scss']
})
export class AdminResultsComponent implements OnInit {
  task: any = {
    name: 'Breeam',
    checked: false,    
    subtasks: [
      {name: 'Assessment', checked: false},
      {name: 'Summary', checked: false}
    ]
  };
  // progressData:any = [
  //   {name:"Properties", status:"active", leftVal:"4%", visible : true },
  //   {name:"Publish", status:"active", leftVal:"17%", visible : true },
  //   {name:"Results", status:"active", leftVal:"17%", visible : true },
  //   {name:"BREEAM", status:"inactive", leftVal:"17%", visible : true },
  //   {name:"EU", status:"inactive", leftVal:"17%", visible : true }];
  allComplete: boolean = false;
  filterCriteria:any = [];
  euCheckFlag:boolean = false;
  euFilterCriteria = { name : "EU Taxonomy", checked: false}
  resultsChecked:boolean = false;
  selectedPropertiesArray:any = [];
  projectId: any;
  projectDetails:any;
  constructor(private projectService: ProjectService,private propertiesListing:PropertiesListingService,  private _snackBar: MatSnackBar, private loaderService: LoaderService, private route: Router) { }

  ngOnInit(): void {
    this.loaderService.subTitle.next('Admin');
    this.projectService.currentProjectId.subscribe((res:any)=>{
      this.projectId = res;
      this.getProjectDetails()
      // this.loaderService.progressStage.next(this.progressData);
    })
  }

  getProjectDetails(){
    this.projectService.getProjectDetails(this.projectId).subscribe((res:any) =>{
      this.projectDetails = res.projectDetails;
      this.task.subtasks[0].checked = res.projectDetails.result_filter.breeam_assessment;
      this.task.subtasks[1].checked = res.projectDetails.result_filter.breeam_summary;
      this.euCheckFlag = res.projectDetails.result_filter.eu_taxonomy;
      if(this.task.subtasks[0].checked || this.task.subtasks[1].checked  || this.euCheckFlag)
        this.resultsChecked = true;
      else
      this.resultsChecked = false;
    });
  }

   showNotification(){
    this._snackBar.open('Results updated', 'Success', {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: ['success']
    });
  }

  backNavigation(){
    this.route.navigate(['/project-dashboard']);
  }
}