import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PropertiesListingService {

  constructor(private http:HttpClient) { }
  public submissionView = new BehaviorSubject(false);
  public saveQuestionnaire = new BehaviorSubject(false);
  public propertResultsForUser = new BehaviorSubject('');
  public propertResultsForEU = new BehaviorSubject('');
  public propertyListResults = new BehaviorSubject('');
  public portfolioResults = new BehaviorSubject('')
  getAllProperties(project_id:any){
    return this.http.get(`${environment.apiUrl}/properties/${project_id}`);
  }

  addProperty(property:any){
    return this.http.post(`${environment.apiUrl}/properties`, property)
  }

  updateProperty(property:any){
    return this.http.patch(`${environment.apiUrl}/properties`, property)
  }

  searchProperty(searchText:any, projectId:any){
    return this.http.get(`${environment.apiUrl}/properties/search?search=${searchText}&project_id=${projectId}`);
  }

  deleteProperty(property:any){
    return this.http.delete(`${environment.apiUrl}/properties`, {body:property});
  }

  calculateTheResultsOfProperties(property:any){
    return this.http.post(`${environment.apiUrl}/properties/result`, property)
  }

  getPropertyResults(data:any){
    return this.http.post(`${environment.apiUrl}/properties/getResult`, data);
  }

  getResultsForPropertiesForUser(data:any){    
    return this.http.post(`${environment.apiUrl}/properties/submitted`, data);
  }

  getAllPropertiesResult(id:any, data:any){
    return this.http.post(`${environment.apiUrl}/properties/all-results/${id}`,data);
  }

  questionnaireSelection(data:any){
    return this.http.patch(`${environment.apiUrl}/properties/status`, data)
  }

  getResultSummary(id:any, sub_category:string){
    return this.http.get(`${environment.apiUrl}/properties/results/summary/${id}?sub_category=${sub_category}`);
  }

  publishResultsOfProperties(data:any){
    return this.http.patch(`${environment.apiUrl}/properties/publish`, data)
  }

  uploadProperties(file:any){
    return this.http.post(`${environment.apiUrl}/files`,file);
  }  

  processUploadedFile(fileName:string, projectId:any, skip:boolean){
    return this.http.get(`${environment.apiUrl}/files?fileName=${fileName}&project_id=${projectId}&skip=${skip}`)
  }
}
