export const environment = {
  development: true,
  production: false,
  apiUrl: 'https://wezovxnoadwa002.azurewebsites.net',
  //apiUrl: 'http://localhost:8080',
  azureADClientId: "9fa1d9a3-48da-410a-9973-3d08e05285ff",
  azureTenantId: "513294a0-3e20-41b2-a970-6d30bf1546fa",
  azureADAuthority: "https://login.microsoftonline.com",
  azureADGraphEndPoint: "https://graph.microsoft.com/v1.0",
  azureADServicePrincipal: "coe-de-esg-asset-tool",
  azureADRedirectUri: "https://wezovxnoadwa001.azurewebsites.net/",
  //azureADRedirectUri : 'http://localhost:4200',
  azureADServiceScope: "api://9fa1d9a3-48da-410a-9973-3d08e05285ff/access_as_user",
  userIdleTime: 120,
  idleTimeout: 60 * 15,
  maxSessionTimeout: 12 * 60 * 60
};



