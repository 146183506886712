import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { ProjectService } from '../../services/project.service';
import {ProgressStage} from '../../../shared/utility/progress-stage';
import { UserService } from '../../services/user.service';
@Component({
  selector: 'app-custom-progress-navigation',
  templateUrl: './custom-progress-navigation.component.html',
  styleUrls: ['./custom-progress-navigation.component.scss']
})
export class CustomProgressNavigationComponent implements OnInit {

  stages:any;
  @Input() stageData:any;
  role:any;
  project: any;
  userDetails: any;
  constructor(private loaderService: LoaderService, private route:Router, private authService: AuthService, private projectService: ProjectService,
    private userService:UserService) { }


  ngOnInit(): void {
    this.loaderService.progressStage.subscribe((res:any) =>{
      this.stages = res;
    });
    this.projectService.currentProjectData.subscribe((proj: any) => {
      this.project = proj;
    })
    this.role = sessionStorage.getItem('role');
    this.getUserDetails();
  }

  getUserDetails(){
    this.userService.currentUserDetails.subscribe((res:any) =>{
      this.userDetails = res;
    })
  }

  progressNavigation(item:any){
    if(item.visible && !item.disabled){
      switch(item.name){
        case 'Properties' :{
          if(!this.project)
            this.route.navigate(['/project-dashboard']);  
          if(this.project.project_role == 'Admin' || this.project.project_role == 'SuperAdmin'){
            this.route.navigate([`/dashboard/${this.project.project_id}`]);
          }
          if(this.project.project_role == 'Contributor' || this.project.project_role == 'Viewer'){
            this.route.navigate([`/dashboard/${this.project.project_id}`]);
          }          
          break;      
        }
        case 'Publish': { 
          if(this.project.project_role == 'Admin' || this.project.project_role == 'SuperAdmin' )
            this.route.navigate(['/admin-project-results']);        
          if(!this.project)
            this.route.navigate(['/project-dashboard']);       
        return;
        }
        case 'Results': { 
          if((this.project.project_role == 'Admin' || this.project.project_role == 'SuperAdmin')){
            this.validateProgressBarAdmin()
            this.route.navigate(['/admin-results']);  
          }                 
          else if(this.project.project_role == 'Contributor' || this.project.project_role == 'Viewer'){
            this.validateProgressBarContributor()
            this.route.navigate(['/property-results']); 
          }              
          if(!this.project)
            this.route.navigate(['/project-dashboard']);       
        return;
        }
        case 'BREEAM': {
          if(!this.project)
            this.route.navigate(['/project-dashboard']);
          if(this.project.project_role == 'Admin' || this.project.project_role == 'SuperAdmin'){
            this.validateProgressBarAdmin()
            this.route.navigate(['/admin-results']); 
          }            
          else{
            this.validateProgressBarContributor()
            this.route.navigate(['/property-results']);
          }
          break;
        }
        case 'EU Taxonomy': {
          if(!this.project)
            this.route.navigate(['/project-dashboard']);  
          if(this.project.project_role == 'Admin' || this.project.project_role == 'SuperAdmin'){
            this.validateProgressBarAdminEU()
            this.route.navigate(['/admin-results']);     
          }               
          else{
            this.validateProgressBarContributorEU()
            this.route.navigate(['/property-results']);
          }            
          break;
        }
        case 'Add Users & Assign Role':{
          this.loaderService.progressStage.next(ProgressStage.addUsersProject)
          break;
        }
        case 'Enter Project Details':{
          this.loaderService.progressStage.next(ProgressStage.progressProjectCreate)
          break;
        }
        case 'Preview':{
          this.loaderService.progressStage.next(ProgressStage.previewPorject)
          break;
        }
      }
  }
  }

  activeStatus(item: any) {
    if(item.completed == true){
      return 'red';
    }
    if(item.status == 'active'){
      return '#2d2d2d';
    }
    else{
      return 'grey';
    }
  } 

  validateProgressBarAdmin(){
    this.projectService.currentProjectData.subscribe((res:any)=>{
      if(res.breeam_status && res.eu_status == false){
        this.loaderService.progressStage.next(ProgressStage.progressDataBreeam);
      }
      if(res.breeam_status == false && res.eu_status){
        this.loaderService.progressStage.next(ProgressStage.progressDataEU);
      }
      if(res.breeam_status && res.eu_status)
        this.loaderService.progressStage.next(ProgressStage.breeamAdminProgress);
    })
  }

  validateProgressBarAdminEU(){
    this.projectService.currentProjectData.subscribe((res:any)=>{
      if(res.breeam_status && res.eu_status == false){
        this.loaderService.progressStage.next(ProgressStage.progressDataBreeam);
      }
      if(res.breeam_status == false && res.eu_status){
        this.loaderService.progressStage.next(ProgressStage.progressDataEU);
      }
      if(res.breeam_status && res.eu_status)
        this.loaderService.progressStage.next(ProgressStage.euTaxonomyAdminProgress);
    })
  }

  validateProgressBarContributor(){
    this.projectService.currentProjectData.subscribe((res:any)=>{
      if(res.breeam_status && res.eu_status == false){
        this.loaderService.progressStage.next(ProgressStage.contributorProgressDataBreeam);
      }
      if(res.breeam_status == false && res.eu_status){
        this.loaderService.progressStage.next(ProgressStage.contributorProgressDataEU);
      }
      if(res.breeam_status && res.eu_status)
        this.loaderService.progressStage.next(ProgressStage.contributorProgressDataEUBreeam);
      if(res.result_filter.breeam_assessment == false && res.result_filter.breeam_summary == false && res.result_filter.eu_taxonomy == false && this.userDetails.user.user_type == 'External')
        this.loaderService.progressStage.next(ProgressStage.contributorExternalNoResults);
      if((res.result_filter.breeam_assessment || res.result_filter.breeam_summary) && res.result_filter.eu_taxonomy == false && this.userDetails.user.user_type == 'External')
        this.loaderService.progressStage.next(ProgressStage.contributorProgressDataBreeam);
    })
  }
  validateProgressBarContributorEU(){
    this.projectService.currentProjectData.subscribe((res:any)=>{
      if(res.breeam_status && res.eu_status == false){
        this.loaderService.progressStage.next(ProgressStage.contributorProgressDataBreeam);
      }
      if(res.breeam_status == false && res.eu_status){
        this.loaderService.progressStage.next(ProgressStage.contributorProgressDataEU);
      }
      if(res.breeam_status && res.eu_status)
        this.loaderService.progressStage.next(ProgressStage.euTaxonomyContributorProgress);
    })
  }
}