<div class="slider-wrapper">
    <div class="slider-inner horizontal-gradient">
        <i class="fa fa-arrows-v" aria-hidden="true" [style.left]="mySliderValue()"></i>
        <label *ngIf="mySliderValue()" [style.left]="mySliderValue()">{{mySliderValue()}}</label>
    </div>
    <div class="slider-level-wrapper">
        <label>0%</label>
        <label class="level-25">25%</label>
        <label class="level-50">50%</label>
        <label class="level-75">75%</label>
        <label class="level-100">100%</label>
    </div>
</div>
