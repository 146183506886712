import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { ChartOptions, ChartType, ChartDataset } from 'chart.js';
import { DownloadService } from 'src/app/core/services/download.service';
import { UserService } from 'src/app/core/services/user.service';
import { ProjectService } from 'src/app/core/services/project.service';
import { PropertiesListingService } from 'src/app/core/services/properties-listing.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import * as htmlToImage from 'html-to-image';
import { AnyRecord } from 'dns';
import {ProgressStage} from '../../shared/utility/progress-stage';
import { DownloadEuResultsService } from 'src/app/core/services/download-eu-results.service';
@Component({
  selector: 'app-property-results',
  templateUrl: './property-results.component.html',
  styleUrls: ['./property-results.component.scss']
})
export class PropertyResultsComponent implements OnInit {
  public barChartOptions: ChartOptions = {
    responsive: true,
    indexAxis: 'y',
    color: "#000",
    backgroundColor: "#A9A9A9",
    borderColor: "transparent",
    maintainAspectRatio: false,
    elements: {
      bar: { 
        borderWidth: 2,
      }
    },
    scales: {
      x: {
        suggestedMin: 0,
        suggestedMax: 100
      }
    }
  };

  public barChartLabels: any[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = false;
  public barChartPlugins = [];

  public allPropertyBarChartLabels: any[] = [];
  public barChartData: ChartDataset[] = [];
  public allPropertyBarChartData: ChartDataset[] = [];
  resultsData: any;
  propertiesList: any = {properties : []};
  breeamPropertiesList: any = {properties : []};
  public allPropertyResultData: any;
  allPropertyResultDataEU:any = [];
  allpropertyTotalAverage: any;
  allSelectedPropertiesResult: any = {properties :{}};
  propertySelect = new FormControl();
  yearOfSubmission = new FormControl();
  countrySelect = new FormControl();
  propertyTypeSelect = new FormControl();
  displayedColumns: string[] = ['category', 'criteria', 'current_status', 'potential'];
  summaryDataSource: any;
  @ViewChild('stepper')
  stepper!: MatStepper;
  yearOfSubmissionArray = [{year_of_submission:2021},{year_of_submission:2022}];
  propertyTypeList = [{name: 'Residential'}, {name: 'Commercial'}];
  currentTabSelected : number = 0;
  breeamResults:boolean = false;
  assessmentResults:boolean = true;
  summaryResult : boolean = true;
  euSelectedPropertiesResult:any;
  currentResultSection:number = 0;
  doughnutChartLabels: any[] = ['EU Taxonomy'];
  donutOptions :any =  {cutout:190,              
    animation: false,
    responsive: true,
    legend: false}
  doughnutChartData: any ={
    radius: 40,
    datasets: [{
      label: 'My First Dataset',
      data: [90, 100],
      backgroundColor: [
        'red',
        'grey'
      ]
    },
    {
      label: 'My First Dataset',
      data: [300, 150],
      backgroundColor: [
        'red',
        'grey',
      ]
    }]
  };
  doughnutChartType: ChartType = 'doughnut';
  progressData:any = [
    {name:"Properties", status:"active", leftVal:"4%", visible : true },
    {name:"Publish", status:"active", leftVal:"17%", visible : true },
    {name:"Results", status:"active", leftVal:"17%", visible : true },
    {name:"BREEAM", status:"inactive", leftVal:"17%", visible : true },
    {name:"EU Taxonomy", status:"inactive", leftVal:"17%", visible : true }
  ];
  highlightBuilding:boolean = true;
  highlightOccupier:boolean = false;
  highlightLocation:boolean = false;
  summaryPropertySelectId:any;
  projectId:any;
  userRoleAccess: any;
  breeamPropertiesListSummary: any;

  projectData: any;
  currentProgressStatus: any;
  constructor(private propertyListing: PropertiesListingService, private cdr: ChangeDetectorRef, private loaderService: LoaderService, private projectService : ProjectService,
    private router: Router, private downloadService: DownloadService, private userService: UserService, private downloadEuResultsService:DownloadEuResultsService) { }

  ngOnInit(): void {
    this.loaderService.subTitle.next('Results');
    this.projectService.currentProjectId.subscribe((res:any)=>{
      this.projectId = res;
      this.getUserDetails();
    });
    // this.getAllPropertiesResult(4, 'BREEAM');
    //this.getPropertyResultsForUser({project_id: 4,client_id: 1,category_type: 'BREEAM'});

  }
  ngAfterViewInit() {
    this.cdr.detectChanges();
  }
  formatLabel(value: number) {
    return value;
  }

  getUserDetails(){
    this.userService.currentUserDetails.subscribe((res:any) =>{
      this.userRoleAccess = res && res.user;
      // this.loaderService.progressStage.next(this.progressData);
      this.getAllProperties();
      this.updateProgress();
      if(this.router.url == '/admin-results')
        this.getProjectDetails();            
    });
  }

  getProjectDetails(){
    this.projectService.getProjectDetails(this.projectId).subscribe((res:any) =>{
      this.progressData[3].visible = true;
      this.progressData[2].visible = true;
      this.assessmentResults = true;
      this.summaryResult = res.projectDetails.result_filter.breeam_summary;
      if(res.projectDetails.project_role == "Contributor" || res.projectDetails.project_role == "Viewer")
        this.validateProgressBarContributor()
      else
        this.validateProgressBarAdmin()
    }, (error:any) =>{
      this.router.navigate(['/project-dashboard'])
    })
  }

  validateProgressBarAdmin(){
    this.projectService.currentProjectData.subscribe((res:any)=>{
      if(res.breeam_status && res.eu_status == false){
        this.loaderService.progressStage.next(ProgressStage.progressDataBreeam);   
        this.currentProgressStatus = ProgressStage.progressDataBreeam
      }        
      if(res.breeam_status == false && res.eu_status){
        this.loaderService.progressStage.next(ProgressStage.progressDataEU);   
        this.currentProgressStatus = ProgressStage.progressDataEU 
      }          
    })
  }

  validateProgressBarContributor(){
    this.projectService.currentProjectData.subscribe((res:any)=>{
      if(res.breeam_status && res.eu_status == false){
        this.loaderService.progressStage.next(ProgressStage.contributorProgressDataBreeam);
        this.currentProgressStatus = ProgressStage.contributorProgressDataBreeam 
      }
      if(res.breeam_status == false && res.eu_status){
        this.loaderService.progressStage.next(ProgressStage.contributorProgressDataEU);
        this.currentProgressStatus = ProgressStage.contributorProgressDataEU 
      }
    })
  }

  getSelectedPropertyResult() {
    console.log(this.propertySelect.value);
    if(this.currentResultSection == 4){
      this.getEUResults();
    }else{
      this.getBreeamResults();
    }
  }

  getEUResults(){
    this.propertyListing.propertResultsForEU.subscribe((res:any)=>{
      let tempData = JSON.parse(res);
      if(this.propertySelect.value && this.propertySelect.value.length){
        const myArrayFiltered = tempData.properties.propertyResult.filter((el:any) => {
          return this.propertySelect.value.some((f:any) => {
            return f.asset_reference_name === el.asset_reference_name;
          });
        });
        this.euSelectedPropertiesResult.properties.propertyResult = myArrayFiltered; 
        console.log(this.euSelectedPropertiesResult.properties.propertyResult);  
        console.log(this.euSelectedPropertiesResult?.properties.propertyResult.doughnutChart.datasets[0].data[0]);       
      }
    })
  }

  getBreeamResults(){
    this.propertyListing.propertResultsForUser.subscribe((res:any)=>{
      console.log(this.yearOfSubmission.value,"this.yearOfSubmission.value" )
      let tempData = JSON.parse(res);
      if(this.propertySelect.value && this.propertySelect.value.length){
        const myArrayFiltered = tempData.properties.propertyResult.filter((el:any, index:any) => {
          return this.propertySelect.value.some((f:any) => {
            return f.asset_reference_name === el.asset_reference_name ;
          });
        });
        const myArrayFiltered2 = myArrayFiltered.filter((el:any, index:any) => {
          return this.yearOfSubmission.value.some((f:any) => {
            return f.year_of_submission === el.year_of_submission ;
          });
        });
        this.allSelectedPropertiesResult.properties.propertyResult = myArrayFiltered2;  
      }
      if(this.yearOfSubmission.value && this.yearOfSubmission.value.length > 1){
        this.multiYearFilterSelection()
      }
    })
  }

  filterOnPrimarySelect(){
    this.allPropertyBarChartData = [];
    this.allPropertyResultDataEU = [];
    this.allSelectedPropertiesResult.properties.propertyResult = [];
    this.getPortfolioResults();
    if(this.currentResultSection == 4){
      this.getEUPropertyData();
    }else{
      this.getBreeamPropertyData();
    }
  }

  getPortfolioResults(){
    let resulType=''
    this.currentResultSection == 4? resulType = 'EU_Taxonomy': resulType ='BREEAM';
    this.allPropertyBarChartData = [];
    this.allPropertyResultDataEU = [];
    //let colorsChart:any = ['grey', 'darkgrey', 'lightgrey']
    let colorsChart:any = ['#ADADAD', 'grey', 'darkgrey'];
    this.yearOfSubmission.value.forEach((element:any, index:number) => {
      this.getAllPropertiesResult(element, resulType, this.propertyTypeSelect.value?.map((val:any) => val.name),
        this.countrySelect.value?.map((val:any) => val.country), 
        this.yearOfSubmission.value?.map((val:any) => val.year_of_submission), colorsChart[index]);
    });
  }

  getEUPropertyData(){
    this.propertyListing.propertResultsForEU.subscribe((res:any) =>{
      let tempData = JSON.parse(res);
      if(this.propertyTypeSelect.value && this.propertyTypeSelect.value.length !== 0){
        tempData.properties.propertyData = tempData.properties.propertyData.filter((el:any) => {
          return this.propertyTypeSelect.value.some((f:any) => {
            return  f.name === el.property_type;
          });
        });
        this.allSelectedPropertiesResult.properties.propertyData = tempData.properties.propertyData
      }
      if(this.countrySelect.value && this.countrySelect.value.length !== 0){
        tempData.properties.propertyData = tempData.properties.propertyData.filter((el:any) => {
          return this.countrySelect.value.some((f:any) => {
            return f.country === el.country;
          });
        });
        this.allSelectedPropertiesResult.properties.propertyData = tempData.properties.propertyData
      }
      if(this.yearOfSubmission.value && this.yearOfSubmission.value.length !== 0){
        tempData.properties.propertyData = tempData.properties.propertyData.filter((el:any) => {
          return this.yearOfSubmission.value.some((f:any) => {
            return f.year_of_submission === el.year_of_submission;
          });
        });
        this.allSelectedPropertiesResult.properties.propertyData = tempData.properties.propertyData
      }
    })

  }

  getBreeamPropertyData(){
    this.propertyListing.propertResultsForUser.subscribe((res:any)=>{
      let tempData = JSON.parse(res);      
      if(this.propertyTypeSelect.value && this.propertyTypeSelect.value.length !== 0){
        tempData.properties.propertyData = tempData.properties.propertyData.filter((el:any) => {
          return this.propertyTypeSelect.value.some((f:any) => {
            return  f.name === el.property_type;
          });
        });
        this.allSelectedPropertiesResult.properties.propertyData = tempData.properties.propertyData
      }
      if(this.countrySelect.value && this.countrySelect.value.length !== 0){
        tempData.properties.propertyData  = tempData.properties.propertyData.filter((el:any) => {
          return this.countrySelect.value.some((f:any) => {
            return f.country === el.country;
          });
        });
        this.allSelectedPropertiesResult.properties.propertyData = tempData.properties.propertyData
      }
      if(this.yearOfSubmission.value && this.yearOfSubmission.value.length !== 0){
        tempData.properties.propertyData = tempData.properties.propertyData.filter((el:any) => {
          return this.yearOfSubmission.value.some((f:any) => {
            return f.year_of_submission === el.year_of_submission;
          });
        });
        this.allSelectedPropertiesResult.properties.propertyData = tempData.properties.propertyData
      }
      if(this.yearOfSubmission.value && this.yearOfSubmission.value.length > 1){
        this.multiYearFilterSelection();
      }
      console.log(this.allSelectedPropertiesResult.properties.propertyData, "this.allSelectedPropertiesResult.properties.propertyData")
      });
  }


  getPropertyResultsForUser(data: any) {
    this.propertyListing.getResultsForPropertiesForUser(data).subscribe((res: any) => {      
      this.allSelectedPropertiesResult = res;
      this.allSelectedPropertiesResult.properties.propertyResult.forEach((propertyRes: any) => {
        propertyRes.barChartData = [{
          data: propertyRes.results.map(({ result }: any) => Math.round(result)), label: 'Score', hoverBackgroundColor: 'red',
          hoverBorderColor: 'red'
        }]
        propertyRes.barChartLabels = propertyRes.results.map(({ category_name }: any) => category_name);
        propertyRes.sumOfCategories = Math.round(propertyRes.sumOfCategories);        
      });
      this.allSelectedPropertiesResult.properties.countries  = res.properties.propertyData.filter((tag: { country: any}, index: any, array: any[]):any => array.findIndex(t => t.country == tag.country) == index);
      this.allSelectedPropertiesResult.properties.yearOfSubmission  = res.properties.propertyResult.filter((tag: { year_of_submission: any}, index: any, array: any[]):any => array.findIndex(t => t.year_of_submission == tag.year_of_submission) == index && tag.year_of_submission);
      console.log(this.allSelectedPropertiesResult, "allSelectedPropertiesResult");
      this.propertyListing.propertResultsForUser.next(JSON.stringify(this.allSelectedPropertiesResult));    
      let currentYear = []; currentYear.push(this.allSelectedPropertiesResult.properties.yearOfSubmission[0])
      this.propertyTypeSelect.setValue([]);
      this.yearOfSubmission.setValue(currentYear);
      this.countrySelect.setValue([]); 
    })
  }
  getPropertyResultsEU(data:any){
    this.propertyListing.getResultsForPropertiesForUser(data).subscribe((res: any) => {      
      this.euSelectedPropertiesResult = res;
      this.euSelectedPropertiesResult.properties.propertyResult.forEach((propRes:any)=>{
        propRes.doughnutChart = {
          radius: 40,
          options: {cutout:50,              
            animation: false,
            responsive: true,
            legend: false,
            plugins: {
              legend: {
                position: 'right'
              }
            }},
          datasets: [{
            label: 'My First Dataset',
            data: [propRes.avgSumOfResults, 100 - propRes.avgSumOfResults],
            backgroundColor: ['red','grey'],
            color: [{
              fillColor:'rgba(255, 0, 0, 0.2)'
            }],
            hoverOffset: 2,
            options: {
              cutout:50
            }
          }]
        }
      });
      this.allSelectedPropertiesResult.properties.propertyData = this.euSelectedPropertiesResult.properties.propertyData;
      this.allSelectedPropertiesResult.properties.yearOfSubmission = res.properties.propertyResult.filter((tag: { year_of_submission: any}, index: any, array: any[]):any => array.findIndex(t => t.year_of_submission == tag.year_of_submission) == index && tag.year_of_submission);
      this.propertyListing.propertResultsForEU.next(JSON.stringify(this.euSelectedPropertiesResult));
      let currentYear = []; 
      currentYear.push(this.allSelectedPropertiesResult.properties.yearOfSubmission[0]);    
      this.breeamResults = false;
      this.propertyTypeSelect.setValue([]);
      this.yearOfSubmission.setValue(currentYear);
      this.countrySelect.setValue([]);
    });
  }

  multiYearFilterSelection(){
    const newData = [...new Set(this.allSelectedPropertiesResult.properties.propertyResult.map(({...rest}:any) => rest))].map((prop:any) => {
      return {
        asset_reference_name : prop.asset_reference_name,
        year_of_submission : prop.year_of_submission,
        data: this.allSelectedPropertiesResult.properties.propertyResult.filter((d:any) => d.asset_reference_name === prop.asset_reference_name).map((d:any) => d.results)
      }
    });
    let colorsChart:any = ['grey', 'darkgrey', 'lightgrey']
    this.allSelectedPropertiesResult.properties.propertyResult.forEach((propertyRes: any) => {
      newData.forEach((mergedData:any)=>{
        if(propertyRes.asset_reference_name === mergedData.asset_reference_name && mergedData.data.length > 1){
          propertyRes.barChartData = [];
          mergedData.data.forEach((results:any, index:number) =>{
            propertyRes.barChartData.push({
              data:results.map(({ result}: any) => Math.round(result)), label: results[0].year_of_submission, hoverBackgroundColor: 'red',
              hoverBorderColor: 'red',
              backgroundColor : colorsChart[index]
            })
          })
        }
      })
    });
    this.allSelectedPropertiesResult.properties.propertyResult = this.allSelectedPropertiesResult.properties.propertyResult.filter((v: { asset_reference_name: any; },i: any,a: any[])=>a.findIndex(t=>(t.asset_reference_name===v.asset_reference_name))===i)
  }
  getPropertyResults(data: any) {
    this.propertyListing.getPropertyResults(data).subscribe((res: any) => {
      this.allSelectedPropertiesResult = res;
      this.allSelectedPropertiesResult.propertyResults.forEach((propertyRes: any) => {
        propertyRes.barChartData = [{
          data: propertyRes.results.map(({ result }: any) => Math.round(result)), label: 'Score', hoverBackgroundColor: 'red',
          hoverBorderColor: 'red'
        }],
          propertyRes.barChartLabels = propertyRes.results.map(({ category_name }: any) => category_name);
        propertyRes.sumOfCategories = Math.round(propertyRes.sumOfCategories)
      });
      console.log(this.allSelectedPropertiesResult);
    });
  }

  getAllPropertiesResult(item: any, type:any, propertType?:any,country?:any, yearOfSubmission?:any, color?:any) {
    let data = {
      project_id:item.project_id,
      category_type: type,
      property_type: propertType?.length?propertType:undefined,
      country: country?.length?country:undefined,
      year_of_submission: yearOfSubmission?.length?yearOfSubmission:[this.projectData?.year_of_creation],
    };
    let colorChart:any = [];
    colorChart.push(color);
    this.propertyListing.getAllPropertiesResult(item.project_id, data).subscribe((res: any) => {
      if(this.currentResultSection == 4){
        let diffVal = 100 - res.results.averageSumOfResults;
        this.allPropertyResultDataEU.push({
            label: 'All property average',
            data: [res.results.averageSumOfResults, diffVal],
            backgroundColor: [
              'red',
              'grey'
            ]
        })
        console.log( this.allPropertyResultDataEU, " this.allPropertyResultDataEU")
        this.doughnutChartData ={
          radius: 40,
          datasets: this.allPropertyResultDataEU,
          responsive: true,
          plugins: {
            legend: {
              position: 'right'
            }
          }
        };
      }else{
        this.propertyListing.portfolioResults.next(JSON.stringify( res.results));
      this.allPropertyResultData = res.results.averageResults.map(({ total_results }: any) => total_results);
      this.allPropertyBarChartLabels = res.results.averageResults.map(({ category_name }: any) => category_name);
      if(!this.yearOfSubmission.value || this.yearOfSubmission.value.length == 1)
        this.allPropertyBarChartData = [{
          data: this.allPropertyResultData, label: item.year_of_submission?item.year_of_submission : this.projectData?.year_of_creation, hoverBackgroundColor: 'red',
              hoverBorderColor: 'red',
              backgroundColor: ['#ADADAD']
          }]
      else if(this.yearOfSubmission.value.length > 1)
        this.allPropertyBarChartData.push({
          data: this.allPropertyResultData, label: item.year_of_submission?item.year_of_submission : this.projectData?.year_of_creation, hoverBackgroundColor: 'red',
          hoverBorderColor: 'red', backgroundColor: colorChart
      });
      this.allpropertyTotalAverage = Math.round(res.results.sumOfCategories);
      }
    })
    
  }

  getSummaryResults(id: any, sub_category:string) {
    this.propertyListing.getResultSummary(id,sub_category).subscribe((res: any) => {
      this.summaryDataSource = res.results;
    })
  }
  getAllProperties(){
    this.propertyListing.getAllProperties(this.projectId).subscribe((res: any) =>{      
      this.propertiesList.properties = res.properties.filter((d:any) => d.questionnaire_status === "Submitted");
      this.breeamPropertiesList.properties = res.properties.filter((item:any) => item.breeam_select_status == true)
      this.breeamPropertiesListSummary = this.breeamPropertiesList.properties.filter((d:any) => d.questionnaire_status === "Submitted");
      this.propertyListing.propertyListResults.next(JSON.stringify(this.propertiesList));
    },(error:any) =>{
      this.router.navigate(['/project-dashboard'])
    })
  }

  selectPropertySummary(event: any, sub_category:string= "Building") {
    this.summaryPropertySelectId = event.value.property_id
    this.getSummaryResults(event.value.property_id, sub_category);
  }

  onTabChanged(event:any){
    this.currentTabSelected = event.index;
    this.propertyTypeSelect.setValue([]);
    this.yearOfSubmission.setValue([])
    this.countrySelect.setValue([])    
  }

  switchResults(resultIndex:any){
    this.downloadService.downloadSubscription?.unsubscribe();
    this.currentResultSection = resultIndex
    if(resultIndex == 4){
      this.allPropertyResultDataEU = [];
      this.allPropertyBarChartData = [];
      this.getAllPropertiesResult({project_id : this.projectId}, 'EU_Taxonomy');
      this.getPropertyResultsEU({project_id: this.projectId,client_id: this.userRoleAccess.user_id, category_type: 'EU_Taxonomy'});      
    }else{
      this.breeamResults = true;      
      this.allPropertyBarChartData = [];
      this.allPropertyResultDataEU = [];      
      this.getAllPropertiesResult({project_id : this.projectId}, 'BREEAM');
      this.getPropertyResultsForUser({project_id: this.projectId,client_id: this.userRoleAccess.user_id ,category_type: 'BREEAM', year_of_submission:[2022]});
    }    
  }

  updateProgress(){
    this.projectService.currentProjectData.subscribe((res:any)=>{
      this.projectData = res;
      this.yearOfSubmissionArray[1].year_of_submission = this.projectData?.year_of_creation;
      this.yearOfSubmissionArray[0].year_of_submission = this.projectData?.year_of_creation - 1
    });
    this.loaderService.progressStage.subscribe((res:any)=>{
      this.currentProgressStatus = res;
      if(res.length)
        if(res[4].status == 'active' || (this.projectData.eu_status && this.projectData.breeam_status == false) )
          this.switchResults(4);
        else 
          this.switchResults(3);
    })
  }

  subFilterClickEvent(event:any){
    console.log(event, "subFilterClickEvent")
    switch(event.srcElement.innerHTML){
      case 'Building': {
        this.highlightBuilding = true;
        this.highlightOccupier = false;
        this.highlightLocation = false;
        this.getSummaryResults(this.summaryPropertySelectId, event.srcElement.innerHTML);
        return;
      }
      case 'Occupier': {
        this.highlightBuilding = false;
        this.highlightOccupier = true;
        this.highlightLocation = false;
        this.getSummaryResults(this.summaryPropertySelectId, event.srcElement.innerHTML);
        return;
      }
      case 'Location':{
        this.highlightBuilding = false;
        this.highlightOccupier = false;
        this.highlightLocation = true;
        this.getSummaryResults(this.summaryPropertySelectId, event.srcElement.innerHTML);
        return;
      }
    }
  }
  
  downloadResults(){
    var node:any = document.getElementsByClassName('all-properties-result-wrapper')[0];
    let me = this;
    htmlToImage.toPng(node)
      .then(function (dataUrl) {
        me.downloadService.getResultsDataToDownload(dataUrl, me.propertySelect.value, me.projectData.project_name);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }

  downloadEUResults(){
    var node:any = document.getElementsByClassName('all-properties-result-wrapper')[0];
    let me = this;
    htmlToImage.toPng(node)
      .then(function (dataUrl) {
        me.downloadEuResultsService.getResultsDataToDownload(dataUrl, me.propertySelect.value, me.projectData.project_name);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }

  downloadEvent(){    
    let stage = this.currentProgressStatus.find((item:any) => item.name == "EU Taxonomy")
    let euOnly = this.currentProgressStatus.find((item:any) => item.name == "BREEAM")
      if(stage.status == 'active' || !euOnly.visible){
        this.downloadEUResults()
      }else{
        this.downloadResults()
      }
  }
}
