import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ProjectService } from 'src/app/core/services/project.service';
import { PropertiesListingService } from 'src/app/core/services/properties-listing.service';
import { QuestionnairesService } from 'src/app/core/services/questionnaires.service';
import { UserService } from 'src/app/core/services/user.service';
import { EuQuestionnaireService } from '../../services/euQuestionnaire.service';
import { SelectQuestionnaireDialogComponent } from '../select-questionnaire-dialog/select-questionnaire-dialog.component';
@Component({
  selector: 'app-questionnaire-dialog-eu',
  templateUrl: './questionnaire-dialog-eu.component.html',
  styleUrls: ['./questionnaire-dialog-eu.component.scss']
})
export class QuestionnaireDialogEuComponent implements OnInit {
  private _snackBar: any;
  userRoleAccess: any;
  projectData: any;
  filterDisable: boolean = false;
  currentAnswersCount: number = 0;
  filterSaveDone:boolean = false;

  constructor(private fb: FormBuilder, private questionnaireService: QuestionnairesService, 
    @Inject(MAT_DIALOG_DATA) public data:any, public dialog: MatDialog, public quesDialogRef: MatDialogRef<QuestionnaireDialogEuComponent>,
    public selectdialogRef: MatDialogRef<SelectQuestionnaireDialogComponent>, public euService: EuQuestionnaireService,
    private propertiesListing: PropertiesListingService, private userService: UserService,  private projectService: ProjectService) { }

  questionnairesList: any = [];
  currentObject:any;
  currentQuestion:any = [];
  public firstselectDate: string = "Select date";
  displayDateFlag: boolean = true;
  selectedDateFlag: boolean = false;
  selectedDate: any;
  finalSaveObject:any = [];
  euResult: boolean = false;
  answersOfQuestionnaire:any = [];
  disableFullForm:boolean=false;
  questionnaireForm = this.fb.group({
  });
  eufilters:any = [];
  euFilterUnchecked:any = [];
  uncheckedQuestions:any = []
  uncheckedQuestionId:any = []
  applyClick:boolean = false;
  ngOnInit(): void {
    this.quesDialogRef.updateSize('90%', '90%');
    this.getProjectRole();
    this.getAllQuestionnaires();
    this.getUserDetails();
    if(this.data.euQuestionnaireDate)
      this.selectedDate = this.data.euQuestionnaireDate;
    this.disableQuestionnaireForm();
  }

  getProjectRole(){
    this.projectService.currentProjectData.subscribe((res:any)=>{
      this.projectData = res
      console.log(res, "ROLE ROLE")
    });
  }

  updatePosition(){
    document.getElementsByClassName('question-wrapper')[0].scroll(0,0)
  }
  getAllQuestionnaires(){
    this.questionnaireService.getAllQuestionnaires(this.data.categoryType, this.data.propertyType, false).subscribe(res =>{  
      this.questionnairesList = res;      
      this.questionnairesList[0].selectedItem = true;
      this.questionnairesList[0].visited = true;
      this.currentObject = this.questionnairesList[0];
      this.currentQuestion = this.questionnairesList[0].questions;
      if(this.currentObject.category_type == "EU_Taxonomy"){
        this.currentQuestion = [];
      }
      this.getAnswersOfQuestionnaire();      
    })
  }

  menuIconVisibility(category:any){
    this.projectService.currentProjectData.subscribe((res:any)=>{
      if(res.project_role !== 'Admin' || res.project_role !=='SuperAdmin')
        category.questions.forEach((question:any) =>{
          this.answersOfQuestionnaire.questionnaires.forEach((answerObj:any) =>{
            if(question.question_id ==answerObj.question_id && answerObj.resolved_status == false){
              if(question.question_code !== '1 TAXONOMY COMPLIANCE')
                question.menu_icon = true;
              question.admin_answer = question.answers.find((item:any)=>item.answer_id == answerObj.admin_answer_id)
            }
          });
        });
  });
    console.log(this.currentQuestion, "Icon visisbility")
    this.categoryStatusMapping()
  }

  initilizeForm(){
    this.currentQuestion.forEach((question:any) =>{
      if(!this.questionnaireForm.get('question_'+question.question_id)){
        this.questionnaireForm.addControl('question_'+question.question_id, new FormControl('', Validators.required))
      }else{
        question.answers.forEach((answer:any)=>{
          if(answer.answer_id == this.questionnaireForm.value['question_'+question.question_id].answer_id)  
            answer.checked=true
        })
      }
    if(this.currentObject.category_name == 'Taxonomy Compliance'){
      this.currentQuestion[0].answers.forEach((answer:any) =>{
        if(!this.questionnaireForm.get('question_'+question.question_id+'_'+answer.answer_id)){
          this.questionnaireForm.addControl('question_'+question.question_id+'_'+answer.answer_id, new FormControl('', Validators.required))
        }
      });
      }
    })
  }

  getAnswersOfQuestionnaire(){
    this.questionnaireService.getAnswersOfQuestionnaire(this.data.propertyId).subscribe((res:any) =>{
      if(res.questionnaires.find((item:any)=> item.question_id == 88 || item.question_id == 89))
        this.filterSaveDone = true;
      this.answersOfQuestionnaire = res;
      this.initilizeForm();
      this.mapThePreselectedAnswers();
      this.categoryStatusMapping();               
    });
  }

  mapThePreselectedAnswers(){
    this.currentAnswersCount = 0;
    this.answersOfQuestionnaire.questionnaires.forEach((answerObj:any) =>{
      this.currentQuestion.forEach((question:any) =>{
        question.answers.forEach((answer:any) =>{
          if(question.question_id == answerObj.question_id){
            if(question.question_code !== '1 TAXONOMY COMPLIANCE'){
              if(answerObj.answer_id == answer.answer_id){
                answer.checked = true;
                this.currentAnswersCount++
              }                
              else
                answer.checked = false
            }
            else{
              if(answerObj.answer_id == answer.answer_id)
                answer.checked = true;
            }

          }
          if(answerObj.answer_id == answer.answer_id && question.question_id == answerObj.question_id && question.control_type == 'input')
            answer.inputAnswer = answerObj.answer
        })
      })
    });   
    console.log(this.currentQuestion,"this.currentQuestion") 
  }
  categoryStatusMapping(){    
    this.questionnairesList.forEach((category:any)=>{
      let answersForCategory= this.answersOfQuestionnaire.questionnaires.filter((ans:any) => ans.category_id == category.category_id)
      if(category.questions.length == answersForCategory.length)
        category.status = "completed";
      let questionWithConflicts = category.questions.filter((item:any)=> item.menu_icon == true)
      category.conflictNo = questionWithConflicts.length
    });
  }

  categoryStatusMappingFormLevel(){
    let transformObject = Object.entries(this.questionnaireForm.value).map(([key, value]:any) => ({
      question_id:parseInt(key.split('question_')[1]) , 
      answer_id:parseInt(value.answer_id),
      category_id: value.category_id,
      property_id : this.data.propertyId
    }));
    this.questionnairesList.forEach((category:any)=>{
      let answersForCategory= transformObject.filter((ans:any) => ans.category_id == category.category_id)
      if(category.questions.length == answersForCategory.length)
        category.status = "completed";
    });
  }
  categoryClick(event:any){
    if(this.applyClick){
    this.filterQuestionnaire(parseInt(event.currentTarget.id));
    this.questionnairesList.forEach((element:any) => {
      if(element.category_id == event.currentTarget.id){
        element.selectedItem = true;
      }else{
        element.selectedItem = false;
      }
      if(element.category_id == event.currentTarget.id){
        element.visited = true;
      }
    });
    //this.currentQuestion[0].answers.filter((item:any) => item.checked == true );
    this.currentQuestion = this.currentObject.questions;
    this.mapThePreselectedAnswers();    
    this.initilizeForm();
    this.categoryStatusMappingFormLevel();
    this.euEnvironmentalFilterVisibility();
    this.filterSelectOnInit();
    // this.categoryStatusMapping()
    this.updatePosition();
    this.questionnairesList.forEach((element:any) => {
      this.menuIconVisibility(element);
    })
    }
    if(event.target.innerText !== "Taxonomy Compliance")
      this.saveFormData(false);
  }

  filterQuestionnaire(id:Number){
     this.currentObject =  this.questionnairesList.filter(function (fil: any){
      return fil.category_id == id;
    })[0];
  }

  selectDate(event: any){
    if(event.source.checked){
      this.selectedDate = event.value;
    } else{
      this.selectedDate = null;
    }
  }

  showQuestion(){
    this.currentQuestion = [];
    if(parseInt(this.selectedDate) <= 2020){
      this.questionnairesList.forEach((cat:any)=>{
        cat.questions = cat.questions.filter((fil: any) =>fil.date_of_building <= parseInt(this.selectedDate))
      })
    }    
    if(parseInt(this.selectedDate) >= 2021){
      this.questionnairesList.forEach((cat:any)=>{
        cat.questions = cat.questions.filter((fil: any) =>fil.date_of_building >= parseInt(this.selectedDate))
      })
    }
    this.questionnairesList[0].questions.splice(1,1);
    console.log(this.questionnairesList, "this.questionnairesList")
    this.questionnaireService.allQuestionnaireList.next(JSON.stringify(this.questionnairesList));
    let payload: any = {};
    payload.property_id = this.data.propertyId;
    payload.eu_questionnaire_date = parseInt(this.selectedDate);
    if(this.data.euQuestionnaireDate !== this.selectedDate)
      this.propertiesListing.updateProperty(payload).subscribe(res=>{ });    
    this.selectedDateFlag = true;
    this.currentObject = this.questionnairesList[0];
    let firstCategory = document.getElementsByClassName('prog-label')[0] as HTMLElement;
    this.applyClick = true;
    firstCategory.click(); 
    this.displayDateFlag = false;
  }

  patchFormValue(event:any, id:any, answerId?:any, event2?:any){
    let hasAnswer = this.answersOfQuestionnaire.questionnaires.find((item:any) => id == item.question_id)
    if(this.currentAnswersCount < this.currentQuestion.length && !hasAnswer && !this.questionnaireForm.get('question_'+id)?.value)
      this.currentAnswersCount++;
    var tempAnswer;    
    if(this.data.review_status)
      tempAnswer=  this.answersOfQuestionnaire.questionnaires.find((item:any)=> item.question_id == id).answer_id;
    this.answersOfQuestionnaire.questionnaires.forEach((answerObj:any) =>{
      if(answerObj.question_id == id)
        answerObj.answer_id = parseInt(event.value);
    });
    if(answerId)
      this.questionnaireForm.get('question_'+id+'_'+answerId)?.patchValue({answer_id:event.source.value, category_id:this.currentObject.category_id});
    if((this.projectData.project_role == 'Admin' || this.projectData.project_role == 'SuperAdmin') && this.data.review_status == 'UnderReview')
      this.questionnaireForm.get('question_'+id)?.patchValue({answer_id:tempAnswer, admin_answer_id: event.value, category_id:this.currentObject.category_id});        
    else
    this.questionnaireForm.get('question_'+id)?.patchValue({answer_id:event.value, category_id:this.currentObject.category_id});
    if(event2)
      this.questionnaireForm.get('question_'+id)?.patchValue({answer_id:answerId, category_id:this.currentObject.category_id,
        answer:event2.target.value});
    this.questionnaireForm.get('question_'+id)?.markAsDirty();
  }

  filterForEU(event:any, id:any, answerId?:any, answerText?:any){   
    if(event.checked){
      this.eufilters.push({answer:answerText,answer_id:answerId});
      this.euFilterUnchecked = this.euFilterUnchecked.filter((item:any) => item.answer_id !== answerId);
      this.holdEUFilterValuesChecked(event);
      this.filterEUQuestionsOnEnviromental();       
      this.questionnaireForm.get('question_'+id+'_'+answerId)?.patchValue({answer_id:event.source.value, category_id:this.currentObject.category_id});      
      this.currentQuestion[0].answers.forEach((item:any) =>{
        let tempAns = {answer_id:item.answer_id, category_id:this.currentObject.category_id};
        if(item.checked)
          this.questionnaireForm.get('question_'+this.currentQuestion[0].question_id+'_'+item.answer_id)?.patchValue(tempAns);      
      });
    }else{
      this.eufilters = this.eufilters.filter((item:any) => item.answer_id !== answerId);
      this.euFilterUnchecked.push({answer:answerText,answer_id:answerId});
      this.holdEUFilterValuesUnchecked(event);
      this.filterEUQuestionsOnEnviromental();
      this.questionnaireForm.get('question_'+id+'_'+answerId)?.patchValue('');
      this.filterUncheckedQuestions();
      let payloadData:any = {property_id: this.data.propertyId}
      payloadData.questionIds = this.uncheckedQuestionId;
      //console.log(this.currentObject, "currentObject")
      this.flushAnswers(payloadData);           
    }
    this.questionnairesList = this.questionnairesList.filter((item:any) => item.questions.length > 0);   
    console.log(this.currentQuestion, "this.currentQuestion")    
    console.log(this.questionnaireForm.value, "this.questionnaireForm")
    this.filterSaveDone = false;
  }

  euEnvironmentalFilterVisibility(){
    if(this.currentObject.category_name == 'Taxonomy Compliance'){
      this.questionnaireService.allQuestionnaireList.subscribe((res:string)=>{
        this.questionnairesList = JSON.parse(res);
      });
      this.currentQuestion[0].answers.forEach((ansItem:any) =>{
        this.questionnairesList.forEach((cat : any) =>{
          let tempArr:any = cat.questions.filter((ques:any) => ansItem.answer == ques.question_tag)
          if(tempArr.length == 0 && !ansItem.visible)  
            ansItem.visible = false;
          else
            ansItem.visible = true;
        });
      });
    }
  }

  filterSelectOnInit(){
    if(this.currentObject.category_name == 'Taxonomy Compliance'){                    
      this.eufilters = this.currentQuestion[0].answers.filter((item:any) => item.checked == true );
      this.eufilters.forEach((item:any) => {
        let dataTemp= {source:{value:item.answer_id}, checked : true};
        this.filterForEU( dataTemp, item.answer_id, item.answer);
      });      
    }
  }

  filterEUQuestionsOnEnviromental(){
    this.questionnaireService.allQuestionnaireList.subscribe((res:string)=>{
      this.questionnairesList = JSON.parse(res);
      this.questionnairesList.forEach((category:any)=>{
        category.questions = category.questions.filter((el:any) => {
          return this.eufilters.some((f:any) => {
            return f.answer === el.question_tag || el.question_code == '1 TAXONOMY COMPLIANCE';
          });
        });
      })
      
    });
  }

  filterUncheckedQuestions(){
    this.questionnaireService.allQuestionnaireList.subscribe((res:string)=>{
      this.uncheckedQuestions = JSON.parse(res);
      this.uncheckedQuestions.forEach((category:any)=>{
        category.questions = category.questions.filter((el:any) => {
          return this.euFilterUnchecked.some((f:any) => {
            return f.answer === el.question_tag || el.question_code == '1 TAXONOMY COMPLIANCE';
          });
        });
        category.uncheckedQuestionId = category.questions.map((item:any) => item.question_id)
      })      
    });
    console.log(this.uncheckedQuestions, "uncheckedQuestions")
    this.uncheckedQuestions.forEach((category:any)=>{
      this.uncheckedQuestionId = this.uncheckedQuestionId.concat(category.uncheckedQuestionId)
    });
    console.log(this.uncheckedQuestionId, "uncheckedQuestionId")
    this.uncheckedQuestionId.forEach((item:any)=>{
      this.questionnaireForm.get('question_'+item)?.patchValue('')
    })
  }

  holdEUFilterValuesChecked(event:any){
    var allData
    this.questionnaireService.allQuestionnaireList.subscribe((res:string)=>{
      allData = JSON.parse(res);
      if(allData[0].questions[0].answers.filter((ans:any) => ans.answer_id == event.source.value).length)
        allData[0].questions[0].answers.filter((ans:any) => ans.answer_id == event.source.value)[0].checked = true;
    });
    this.questionnaireService.allQuestionnaireList.next(JSON.stringify(allData))
  }

  holdEUFilterValuesUnchecked(event:any){
    var allData
    this.questionnaireService.allQuestionnaireList.subscribe((res:string)=>{
      allData = JSON.parse(res);
      allData[0].questions[0].answers.filter((ans:any) => ans.answer_id == event.source.value)[0].checked = false;
    });
    this.questionnaireService.allQuestionnaireList.next(JSON.stringify(allData))
  }

  showNotification(){
    this._snackBar.open('Questionnaire Saved Successfully', 'Success', {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: ['success']
    });
}

  saveFormData(dialogClose:boolean){
    this.finalSaveObject = [];
    let transformObject;
    if((this.projectData.project_role == 'Admin' || this.projectData.project_role == 'SuperAdmin') && this.data.review_status == 'UnderReview'){
      transformObject = Object.entries(this.questionnaireForm.value).map(([key, value]:any) => ({
        question_id:parseInt(key.split('question_')[1]) , 
        answer_id:parseInt(value.answer_id),
        answer:value.answer,
        admin_answer_id: parseInt(value.admin_answer_id),
        resolved_status:false,
        category_id: value.category_id,
        property_id : this.data.propertyId
      }));
    }else{
      if(!this.data.review_status){
        transformObject = Object.entries(this.questionnaireForm.value).map(([key, value]:any) => ({
      question_id:parseInt(key.split('question_')[1]) , 
      answer_id:parseInt(value.answer_id),
      answer:value.answer,
      category_id: value.category_id,
      property_id : this.data.propertyId
    }));
  }else{
    transformObject = Object.entries(this.questionnaireForm.value).map(([key, value]:any) => ({
      question_id:parseInt(key.split('question_')[1]) , 
      answer_id:parseInt(value.answer_id),
      answer:value.answer,
      admin_answer_id: parseInt(value.admin_answer_id),
      category_id: value.category_id,
      property_id : this.data.propertyId,
      resolved_status: value.resolved_status? true : false
    }));      
  }
}    
    if(this.filterSaveDone)
    transformObject = transformObject.filter((item:any) => item.question_id !== 88 && item.question_id !== 89)
      

    transformObject.forEach((item:any)=>{
      if(item.answer_id){
        this.finalSaveObject.push(item)
      }    
    })
    console.log(this.finalSaveObject, "finalSaveObject")
    //remove duplicate questionnaire
    let filteredArray = this.finalSaveObject.filter((v: { question_id: any; },i: any,a: any[])=>a.findIndex(t=>(t.question_id===v.question_id))===i)
   
    if(this.data.categoryType == 'EU_Taxonomy')
      this.saveAnsweredQuestions({body:this.finalSaveObject, category_type:this.data.categoryType,
      property_type: this.data.propertyType}, dialogClose);
    // else
    //   this.saveAnsweredQuestions({body:filteredArray, category_type:this.data.categoryType,
    // property_type: this.data.propertyType}, dialogClose);
    
  }

  saveAnsweredQuestions(data:any, dialogFlag:boolean){
    this.questionnaireService.saveAnsweredQuestionnaire(data).subscribe((res:any) => {
    //  this.showNotification();
    this.getAnswersOfQuestionnaire();
    if(dialogFlag)
      this.closeFormData();
      this.propertiesListing.saveQuestionnaire.next(true);
    })
  }

  closeFormData(){
    this.quesDialogRef.close();
    this.selectdialogRef.close();
  }

  disableQuestionnaireForm(){
    if(this.data.questionnaire_status == 'Submitted' || this.userRoleAccess?.user?.role == 'Viewer'){
      this.disableFullForm = true;
    }
    this.projectService.currentProjectData.subscribe((res:any)=>{
      if(res.project_role == 'Viewer')
        this.disableFullForm = true;
      if(res.project_role == 'Contributor' && (this.data.review_status == 'UnderReview' || this.data.review_status == 'Reviewed'))
        this.disableFullForm = true;
    })
    if(this.data.review_status == 'UnderReview' || this.data.review_status == 'Reviewed' || this.data.questionnaire_status == 'Submitted' || this.projectData.project_role == 'Viewer')
      this.filterDisable = true;
    /*if(this.userRoleAccess?.user?.role == 'Admin' || this.userRoleAccess?.user?.role == 'SuperAdmin')
      this.disableFullForm = false;*/
    
  }

  getUserDetails(){
    this.userService.currentUserDetails.subscribe((res:any) =>{
      this.userRoleAccess = res;            
    });
  }

  flushAnswers(data:any){
    this.questionnaireService.flushQuestionnaireAnswers(data).subscribe((res:any) =>{
      
    })
  }

  approveQuestionnaire(qId:any, adminAnswer:any){
    this.questionnaireForm.get('question_'+qId)?.patchValue({answer_id:adminAnswer.answer_id,admin_answer_id:adminAnswer.answer_id,  category_id:this.currentObject.category_id, resolved_status:true});
    this.answersOfQuestionnaire.questionnaires.forEach((answerObj:any) =>{
      if(answerObj.question_id == qId){
        answerObj.answer_id = parseInt(adminAnswer.answer_id);
        answerObj.resolved_status  = true;
      }
    });
    this.mapThePreselectedAnswers();
  }
  revertQuestionnaire(qId:any, adminAnswer:any){       
    let tempAnswer=  this.answersOfQuestionnaire.questionnaires.find((item:any)=> item.question_id == qId).answer_id;   
    this.questionnaireForm.get('question_'+qId)?.patchValue({answer_id: tempAnswer,admin_answer_id:adminAnswer.answer_id,  category_id:this.currentObject.category_id, resolved_status:true});
    this.answersOfQuestionnaire.questionnaires.forEach((answerObj:any) =>{
      if(answerObj.question_id == qId){
        answerObj.resolved_status  = true;
      }
    });
  }
} 