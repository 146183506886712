<div class="select-querstionnaire-wrapper">
    <div class="select-q-header bg-dark text-white">
        <label class="h6"> Property  {{data.property.asset_reference_name}}</label>
        <button [mat-dialog-close]="true" class="fa fa-times fa-lg bg-dark text-white close-button"></button>
    </div>
    <div class="select-q-content">
        <p class="h6 text-dark fw-bolder">Select questionnaire</p>
        <hr class="hr-first">
        <div class="row">
            <div class="col-4">
                <input type="checkbox" [checked]="data.property.breeam_select_status" (change)="breeamSelect($event)"
                [disabled]="disableBreeamCheckbox">
                <label class="questionnaire-label" for="breeam"> BREEAM</label>
            </div>

            <label class="col-4">{{breeamPercentage}}%</label>
            <div class="col-4">
                <button type="button" 
                class="text-white select-button"                                   
                [style.background]="breeamQuestionnaireFlag ? '#3F88C5' : '' "
                (click)="openDialog('BREEAM')"
                [disabled]="questionEditFlag">{{breeamActionText}}
                </button>
                <i class="fa fa-check-circle-o fa-lg" [style.color]="breeamTickMark"></i>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-4">
                <input type="checkbox" [checked]="data.property.eu_select_status" (change)="euSelect($event)"
                [disabled]="disableEUCheckbox">
                <label class="questionnaire-label" for="eu taxonomy"> EU Taxonomy</label>
            </div>
            <label class="col-4">{{euPercentage}}%</label>
            <div class="col-4">
                <button type="button" 
                class="text-white select-button"                                    
                [style.background]="euQuestionnaireFlag ? '#3F88C5' : '' " 
                (click)="openDialogEu('EU_Taxonomy')"
                [disabled]="questionEditFlagEU">{{euActionText}}
                </button>
                <i class="fa fa-check-circle-o fa-lg" [style.color]="euTickMark"></i>
            </div>
        </div>  
    </div>
</div>