import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, _closeDialogVia } from '@angular/material/dialog';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { QuestionnairesService } from 'src/app/core/services/questionnaires.service';

@Component({
  selector: 'app-questionnaire-edit-dialog',
  templateUrl: './questionnaire-edit-dialog.component.html',
  styleUrls: ['./questionnaire-edit-dialog.component.scss']
})
export class QuestionnaireEditDialogComponent implements OnInit {
  answerCreatedArray: any;

  constructor(private questionnaireService: QuestionnairesService, public dialogRef: MatDialogRef<any>, public quesEditDialogRef: MatDialogRef<QuestionnaireEditDialogComponent>, @Inject(MAT_DIALOG_DATA) public data:any, private fb:FormBuilder) { }
  questionnairesList:any;
  displayedColumns: string[] = ['code', 'question', 'answers', 'score', 'sorting', 'potential_improvement', 'action'];
  dataSource:any;
  questionnaireForm: FormGroup= this.fb.group({});
  answerForm: FormGroup= this.fb.group({});
  categoryForm: FormGroup = this.fb.group({});
  currentObject:any;
  updateQuestionObject:any = {questionArray:[]};
  categoryClickFlag:boolean = false;
  categoryModifiedArray:any;
  questionModifiedArray:any;
  answeredModifiedArray:any;
  newQuestionCount: number = 0;
  newAnswerCount: number = 0;
  addAnswerFlag:boolean = false;
  addQuestionFlag:boolean = false;
  addCategoryFlag:boolean = false;
  total_weightage: number = 0;
  subCategoryValues = ['Building', 'Occupier', 'Location'];
  private _snackBar: any;
  @ViewChild('scrollMe') private myScrollContainer: any;
  ngOnInit(): void {
    this.dialogRef.updateSize('95%', '90%');
    this.getAllQuestionnaires();
    console.log(this.data);
    if(this.data.categoryType == 'EU_Taxonomy')
    this.displayedColumns = ['code', 'question', 'answers', 'score', 'sorting','action']
  }

  getAllQuestionnaires(){
    this.questionnaireService.getAllQuestionnaires(this.data.categoryType, this.data.propertyType, true).subscribe((res: any) =>{
      this.questionnairesList = res;
      this.questionnairesList[0].selectedItem = true;
      this.currentObject = this.questionnairesList[0];
      if(!this.categoryClickFlag){this.dataSource = this.questionnairesList[0].questions;}
      this.setSubCategoryValue(this.questionnairesList);
      console.log(this.questionnairesList, "ANS")
      this.categoryForm = this.toCategoryFormGroup(this.questionnairesList)
      this.questionnaireForm = this.toFormGroup(this.dataSource);
      this.answerForm = this.toAnswerFormGroup(this.dataSource);   
      //this.total_weightage = this.questionnairesList.reduce((a:any, b:any) => a.weightage + b.weightage, 0) 
      this.total_weightage = this.questionnairesList.map((item:any) => item.weightage).reduce((a:any, b:any) => a + b, 0);
      console.log(this.total_weightage);
    })
  }



  saveQuestionnaire(closeDialog:boolean){
    if(this.addCategoryFlag)
      this.createCategoryFormat();
    if(this.addQuestionFlag)
      this.createNewQuestionnaire();
    if(this.addAnswerFlag)
      this.createNewAnswer()
            
    this.formatAnswerData();
    this.formatQuestionData();
    this.formatCategoryData();
    this.updateQuestions({questionArray:this.questionModifiedArray, answerArray: this.answeredModifiedArray, 
            categoryArray: this.categoryModifiedArray}, closeDialog);                  
  }

  closeDialog(){
    this.quesEditDialogRef.close();
  }    

  createNewQuestionnaire(){
    this.formatQuestionData()
    this.formatAnswerData();
    this.answeredModifiedArray.forEach((item:any)=>{
      this.questionModifiedArray.find((q:any) => q.question_id == item.question_id).answerArray.push(item)
      this.questionModifiedArray.find((q:any) => q.question_id == item.question_id).property_type = this.data.propertyType;
    });
    this.questionModifiedArray.forEach((item:any)=>{
      if(typeof item.question_id !== "number")
        delete item.question_id;
      item.answerArray.forEach((ans:any)=>{
        if(typeof ans.answer_id !== "number")
          delete ans.answer_id;
        if(typeof ans.question_id !== "number")
          delete ans.question_id
      })      
    })
    this.questionModifiedArray = this.questionModifiedArray.filter((item:any) => !item.question_id)
    let questionArray = this.questionModifiedArray
    let data={"createBody": [
    {
      "category_id" : this.currentObject.category_id,
      "questionArray" : questionArray
    }
  ]};
  console.log(data);
    this.questionnaireService.createNewQuestionnaire(data).subscribe((res:any) =>{
      console.log("questionnaire edit Success");
    })
  }

  createNewAnswer(){
    this.formatQuestionData()
    this.formatNewAnswerData();
    this.answerCreatedArray.forEach((item:any) =>{
      this.questionModifiedArray.find((q:any) => q.question_id == item.question_id).answerArray.push(item)
      this.questionModifiedArray.find((q:any) => q.question_id == item.question_id).property_type = this.data.propertyType;
      delete item.answer_id;      
    });
    this.questionModifiedArray.forEach((item:any) => {
      item.answerArray = item.answerArray.filter((ans:any)=> !ans.answer_id)
      item.answerArray = item.answerArray.filter((v: { answer: any; },i: any,a: any[])=>a.findIndex(t=>(t.answer===v.answer))===i)
      item.answers=[];
    })    
    let questionArray = this.questionModifiedArray
    let data={"createBody": [
    {
      "category_id" : this.currentObject.category_id,
      "questionArray" : questionArray
    }
  ]};
  console.log(data);
    this.questionnaireService.createNewQuestionnaire(data).subscribe((res:any) =>{
      console.log("questionnaire edit Success");
      this.dialogRef.close();
    }, (err:any)=>{
      alert(err.error.message[0])
    })
  }


  formatAnswers(){
    console.log(this.answerForm.controls, "ansform");
  }

  toAnswerFormGroup(questions:any){
    const group: any = {};
    questions.forEach((question:any) => {
      question.answers.forEach((answer:any) =>{
        group['answer_'+answer.answer_id] = new FormControl(answer.answer || '' , Validators.required);
        group['score_'+answer.answer_id] = new FormControl(answer.score || '' , Validators.required);
        group['sorting_order_'+answer.answer_id] = new FormControl(answer.score || '' , Validators.required);
        group['potential_improvement_'+answer.answer_id] = new FormControl(answer.weightage || '' , Validators.required);
        group['delete_status_'+answer.answer_id] = new FormControl(false,  Validators.required);
      })
    });
    return this.fb.group(group);
  }
  toFormGroup(questions:any) {
    const group: any = {};
    var formArray:any;
    questions.forEach((question:any) => {
      group['question_item_'+question.question_id] = new FormControl(question.question_item || '', Validators.required);
      group['question_code_'+question.question_id] = new FormControl(question.question_code || '', Validators.required);
      group['question_score_'+question.question_id] = new FormControl(question.question_item || '', Validators.required);
      group['question_info_'+question.question_id] = new FormControl(question.question_code || '', Validators.required);
      group['criteria_'+question.question_id] = new FormControl(question.criteria || '', Validators.required);
      group['question_delete_status_'+question.question_id] = new FormControl(question.question_delete_status || '', Validators.required);
      group['potential_improvement_status_'+question.question_id] = new FormControl(question.potential_improvement_status || '', Validators.required);
      group['optional_status_'+question.question_id] = new FormControl(question.optional_status || '');
    });
    return this.fb.group(group);
  }

  toCategoryFormGroup(categories:any){
    const group: any = {};
    categories.forEach((category:any) => {
        group['category_weight_'+category.category_id] = new FormControl(category.weightage || '' , Validators.required);
        group['category_name_'+category.category_id] = new FormControl(category.category_name || '' , Validators.required);
        group['sub_category_'+category.category_id] = new FormControl(category.sub_category || '' , Validators.required);
        
    });
    return this.fb.group(group);
  }

  setSubCategoryValue(categories:any){
    categories.forEach((category:any) => {
      category.sub_categories = [{name : 'Building'},{name :  'Occupier'}, {name : 'Location'}];
      category.sub_categories.forEach((sub:any)=>{
        if(sub.name == category.sub_category)
          sub.selected = true;
      })
    })
  }
  categoryClick(event:any){  
    this.categoryClickFlag = true;
    if(this.questionnaireForm.dirty)
      this.saveQuestionnaire(false)
    this.filterQuestionnaire(parseInt(event.currentTarget.id));
    this.questionnairesList.forEach((element:any) => {
      if(element.category_id == event.currentTarget.id){
        element.selectedItem = true;
      }else{
        element.selectedItem = false;
      }
      if(element.category_id == event.currentTarget.id){
        element.visited = true;
      }
     // this.total_weightage = + element.weightage;
    });
    
    this.questionnaireForm = this.toFormGroup(this.dataSource);
    this.answerForm = this.toAnswerFormGroup(this.dataSource);
    if(this.currentObject)
      this.dataSource = this.currentObject.questions;
    
  }

  filterQuestionnaire(id:Number){
     this.currentObject =  this.questionnairesList.filter(function (fil: any){
      return fil.category_id == id;
    })[0];
  }

  nextCategoryAction(){
    let indexNum:number = 0;
    this.questionnairesList.forEach((element:any, index:number)=>{
       if(element.category_id == this.currentObject.category_id){
         this.dataSource = this.questionnairesList[index +1].questions;
         indexNum = index;
       } 
    });

    this.currentObject = this.questionnairesList[indexNum +1];
    this.questionnairesList.forEach((element:any) => {
      if(element.category_id == this.currentObject.category_id){
        element.selectedItem = true;
      }else{
        element.selectedItem = false;
      }
    });  
  }

  previousCategoryAction(){
    let indexNum:number = 0;
    this.questionnairesList.forEach((element:any, index:number)=>{
       if(element.category_id == this.currentObject.category_id){
         this.dataSource = this.questionnairesList[index - 1].questions;
         indexNum = index;
       }
    });
    this.currentObject = this.questionnairesList[indexNum - 1];
    this.questionnairesList.forEach((element:any) => {
      if(element.category_id == this.currentObject.category_id){
        element.selectedItem = true;
      }else{
        element.selectedItem = false;
      }
    }); 
  }
  onSubmit(){

  }

  categoryEditEvent(event:any){
    this.categoryForm.get(event.target.formControlName)?.patchValue(event.target.value);
    this.categoryForm.get(event.target.formControlName)?.markAsDirty();
  }

  questionEditEvent(event:any){
    console.log(this.dataSource)
    this.questionnaireForm.get(event.target.formControlName)?.patchValue(event.target.value);
    this.questionnaireForm.get(event.target.formControlName)?.markAsDirty();
  }

  piCheckEvent(event:any, qId:any){
    if(event.checked)
      this.questionnaireForm.get('potential_improvement_status_'+qId)?.patchValue(event.source.value);
    else
      this.questionnaireForm.get('potential_improvement_status_'+qId)?.patchValue(false);
    this.questionnaireForm.get('potential_improvement_status_'+qId)?.markAsDirty();   
  }

  optionalCheckEvent(event:any, qId:any){
    if(event.checked)
      this.questionnaireForm.get('optional_status_'+qId)?.patchValue(event.source.value);
    else
      this.questionnaireForm.get('optional_status_'+qId)?.patchValue(false);
    this.questionnaireForm.get('optional_status_'+qId)?.markAsDirty();   
  }

  deleteQuestionEvent(event:any, deleteQuestion:boolean){
    this.questionnaireForm.get(event.currentTarget.formControlName)?.patchValue(deleteQuestion);
    this.questionnaireForm.get(event.currentTarget.formControlName)?.markAsDirty();
    this.saveQuestionnaire(false);
  }

  showNotification(){
    this._snackBar.open('Questionnaire Saved Successfully', 'Success', {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: ['success']
    });
}
  

  answerEditEvent(event:any, question:any, answer:any){
    console.log(this.questionnaireForm.get('question_score_'+question.question_id)?.value)
    this.answerForm.get(event.target.formControlName)?.patchValue(event.target.value);
    this.answerForm.get(event.target.formControlName)?.markAsDirty();
    this.questionnaireForm.get('question_score_'+question.question_id)?.markAsDirty();
    let currentAns:any = [];
      question.answers.forEach((ans:any)=>{
        if(this.answerForm.get('score_' + ans.answer_id)?.value)
          currentAns.push(parseInt(this.answerForm.get('score_' + ans.answer_id)?.value))
      })
    // this.currentObject.find((q:any)=> question.question_id == q.question_id).score = Math.max(...currentAns)
    if(currentAns.length)
      this.dataSource.find((q:any)=> question.question_id == q.question_id).score = Math.max(...currentAns)
    else
      this.dataSource.find((q:any)=> question.question_id == q.question_id).score = 0
    this.questionEditEvent({target:{formControlName : 'question_score_'+question.question_id, value : currentAns.length? Math.max(...currentAns):0}})
  }

  updateQuestions(data:any, closeDialog:boolean){ 
    this.questionnaireService.updateQuestionnaire(data).subscribe(res=>{      
      this.addQuestionFlag = false;
      if(closeDialog)
        this.dialogRef.close();
    });
  }

  formatNewAnswerData(){
    let tempArray:any=[]
    Object.entries(this.answerForm.controls).filter((value:any) => {
      if(value[1].dirty){
        console.log(value[1].value)
        value.id = value[0].split('answer_')[1] || value[0].split('score_')[1] || value[0].split('sorting_order_')[1] || value[0].split('potential_improvement_')[1]
        || value[0].split('delete_status_')[1]
        tempArray.push(value)
      }
    });

    console.log("New Answers only :", tempArray)

    let dirtyAnswers:any = [];
    console.log(this.currentObject);
    this.currentObject.questions.forEach((question:any) =>{
      question.answers.forEach((answer:any)=>{
        tempArray.forEach((item:any)=>{
          if(answer.answer_id == item.id && answer.answer_status == 'new'){
            if(item[0].split(item.id)[0] == 'answer_')
              answer.answer = item[1].value;
            if(item[0].split(item.id)[0] == 'score_')
              answer.score = parseInt(item[1].value);
            if(item[0].split(item.id)[0] == 'sorting_order_')
              answer.sorting_order = parseInt(item[1].value);
            if(item[0].split(item.id)[0] == 'potential_improvement_')
              answer.potential_improvement = item[1].value;
            if(item[0].split(item.id)[0] == 'delete_status_')
              answer.delete_status = false;
            delete question.created_date;
            delete question.updated_date;
            dirtyAnswers.push(answer)
          }
        })        
      })
      question.answerArray = question.answers
    });    
    let filteredArray = dirtyAnswers.filter((v: { answer_id: any; },i: any,a: any[])=>a.findIndex(t=>(t.answer_id===v.answer_id))===i)
    this.answerCreatedArray = filteredArray;
  }

  formatAnswerData(){
    let tempArray:any=[]
    Object.entries(this.answerForm.controls).filter((value:any) => {
      if(value[1].dirty){
        console.log(value[1].value)
        value.id = value[0].split('answer_')[1] || value[0].split('score_')[1] || value[0].split('sorting_order_')[1] || value[0].split('potential_improvement_')[1]
        || value[0].split('delete_status_')[1]
        tempArray.push(value)
      }
    });

    console.log(tempArray)

    let dirtyAnswers:any = [];
    console.log(this.currentObject);
    if(this.currentObject)
    this.currentObject.questions.forEach((question:any) =>{
      question.answers.forEach((answer:any)=>{
        tempArray.forEach((item:any)=>{
          if(answer.answer_id == item.id){
            if(item[0].split(item.id)[0] == 'answer_')
              answer.answer = item[1].value;
            if(item[0].split(item.id)[0] == 'score_')
              answer.score = parseInt(item[1].value);
            if(item[0].split(item.id)[0] == 'sorting_order_')
              answer.sorting_order = parseInt(item[1].value);
            if(item[0].split(item.id)[0] == 'potential_improvement_')
              answer.potential_improvement = item[1].value;
            if(item[0].split(item.id)[0] == 'delete_status_')
              answer.delete_status = item[1].value;
            delete question.created_date;
            delete question.updated_date;
            dirtyAnswers.push(answer)
          }
        })
      })
    });    
    let filteredArray = dirtyAnswers.filter((v: { answer_id: any; },i: any,a: any[])=>a.findIndex(t=>(t.answer_id===v.answer_id))===i)
    this.answeredModifiedArray = filteredArray;
  }

  formatQuestionData(){
    let tempArray:any=[]
    Object.entries(this.questionnaireForm.controls).filter((value:any) => {
      if(value[1].dirty){
        value.id = value[0].split('question_item_')[1] || value[0].split('question_code_')[1] || value[0].split('question_score_')[1] ||
        value[0].split('question_info_')[1] || value[0].split('criteria_')[1] || value[0].split('question_delete_status_')[1] || 
        value[0].split('potential_improvement_status_')[1] || value[0].split('optional_status_')[1];        
        tempArray.push(value)
      }
    });
    let dirtyQuestion:any = []
    this.dataSource.forEach((question:any) =>{
      tempArray.forEach((item:any)=>{
        if(question.question_id == item.id){
          if(item[0].split(item.id)[0] == 'question_code_')
            question.question_code = item[1].value;
          if(item[0].split(item.id)[0] == 'question_item_')
            question.question_item = item[1].value;
          if(item[0].split(item.id)[0] == 'question_score_')
            question.score = parseInt(item[1].value);
          if(item[0].split(item.id)[0] == 'question_info_')
            question.question_info = item[1].value;
          if(item[0].split(item.id)[0] == 'criteria_')
            question.criteria = item[1].value;
          if(item[0].split(item.id)[0] == 'question_delete_status_')
            question.question_delete_status = item[1].value;
          if(item[0].split(item.id)[0] == 'potential_improvement_status_')
            question.potential_improvement_status = item[1].value;
          if(item[0].split(item.id)[0] == 'optional_status_')
            question.optional_status = item[1].value;
          if(question.category_id == 53)
            question.control_type = 'input';
          else
            question.control_type = 'radiobutton';     
          question.answerArray = []
          delete question.created_date;
          delete question.updated_date;
          dirtyQuestion.push(question)
        }
      })
    });
    let filteredArray = dirtyQuestion.filter((v: { question_id: any; },i: any,a: any[])=>a.findIndex(t=>(t.question_id===v.question_id))===i)
    this.questionModifiedArray = filteredArray;
  }

  formatCategoryData(){
    let tempArray:any=[]
    Object.entries(this.categoryForm.controls).filter((value:any) => {
      if(value[1].dirty){
        value.id = value[0].split('category_weight_')[1] || value[0].split('category_name_')[1] || value[0].split('sub_category_')[1];
        tempArray.push(value)
      }
    });
    let dirtyCategory:any = []
    this.questionnairesList.forEach((category:any) =>{
      tempArray.forEach((item:any)=>{
        if(category.category_id == item.id){
          if(item[0].split(item.id)[0] == 'category_weight_')
            category.weightage = parseInt(item[1].value);
          if(item[0].split(item.id)[0] == 'category_name_')
            category.category_name = item[1].value;
          if(item[0].split(item.id)[0] == 'sub_category_')
            category.sub_category = item[1].value;
          category.category_type = this.data.categoryType;
          dirtyCategory.push(category)
        }
      })
    });
    console.log(dirtyCategory)
    let filteredArray = dirtyCategory.filter((v: { question_id: any; },i: any,a: any[])=>a.findIndex(t=>(t.question_id===v.question_id))===i)
    this.categoryModifiedArray = filteredArray;
  }

  addNewCategory(){
    this.addCategoryFlag = true;
    this.questionnairesList.push({category_id: 'new'});
    this.dataSource = [];
    this.categoryForm = this.toCategoryFormGroup(this.questionnairesList)
    this.addNewQuestionnaire();
  }

  addNewQuestionnaire(){
    this.addQuestionFlag = true;
     this.newQuestionCount++;
    const blankQues = {
      "question_id" : "new_"+this.newQuestionCount,
      "answers": [
        {
          "answer_id": "new_"+ this.newAnswerCount,
          "answer_status": "new",
          "question_id" : "new_"+this.newQuestionCount,
          "delete_status": false
        }
      ]
    }
    this.dataSource = [...this.dataSource, blankQues];
    this.currentObject.questions = this.dataSource;
    this.answerForm = this.toAnswerFormGroup(this.dataSource);
    this.questionnaireForm = this.toFormGroup(this.dataSource);
    this.scrollToElement(this.myScrollContainer.nativeElement)    
    console.log(this.questionnaireForm, "questionnaireForm obj");
  }
  addAnswersRow(event:any, question:any){
    this.questionnaireForm.get('question_item_'+question.question_id)?.markAsDirty();
    let currentQuesIndex = this.dataSource.findIndex((ques:any) =>ques.question_id ==  question.question_id);
    this.newAnswerCount++;
    this.dataSource[currentQuesIndex].answers.push({
      "answer_id": "new_"+this.newAnswerCount, "answer_status": "new", "question_id" : question.question_id,
      "delete_status": false
    });
    console.log(this.dataSource)
    this.answerForm.addControl('answer_new_'+this.newAnswerCount, new FormControl('', Validators.required))
    this.answerForm.addControl('score_new_'+this.newAnswerCount, new FormControl('', Validators.required))
    this.answerForm.addControl('sorting_order_new_'+this.newAnswerCount, new FormControl('', Validators.required))
    this.answerForm.addControl('delete_status_new_'+this.newAnswerCount, new FormControl(false))
    this.answerForm.addControl('potential_improvement_new_'+this.newAnswerCount, new FormControl('', Validators.required))
    this.addAnswerFlag = true;    
    console.log(this.answerForm);
  }

  removeAnswerRow(question:any,answer:any, event:any){
    this.answerForm.get('answer_'+answer.answer_id)?.markAsDirty();
    let currentQuesIndex = this.dataSource.findIndex((ques:any) =>ques.question_id ==  question.question_id);
    let ansIndex = this.dataSource[currentQuesIndex].answers.findIndex((ans:any) => ans.answer_id == answer.answer_id)
    this.dataSource[currentQuesIndex].answers[ansIndex].delete_status = true;
    this.answerForm.get(event.target.formControlName)?.patchValue(true);
    this.answerForm.get(event.target.formControlName)?.markAsDirty();
    // this.addAnswerFlag = false;
  }


  createCategoryFormat(){
    let tempQuestionArray:any=[];
    
    Object.entries(this.questionnaireForm.controls).filter((value:any) => {
      if(value[1].dirty){
        value.id = value[0].split('question_item_')[1] || value[0].split('question_code_')[1] || value[0].split('question_score_')[1] ||
        value[0].split('question_info_')[1] || value[0].split('criteria_')[1] || value[0].split('question_delete_status_')[1]
        || value[0].split('potential_improvement_status_')[1] || value[0].split('optional_status_')[1];
        tempQuestionArray.push(value)
      }
    });

    let tempAnswerArray:any=[]
    Object.entries(this.answerForm.controls).filter((value:any) => {
      if(value[1].dirty){
        value.id = value[0].split('answer_')[1] || value[0].split('score_')[1] || value[0].split('sorting_order_')[1] || value[0].split('potential_improvement_')[1] 
        || value[0].split('delete_status_')[1]
        tempAnswerArray.push(value[1])
      }
    });

    this.formatCategoryData();
    this.categoryModifiedArray[0].questionArray = [];
    delete this.categoryModifiedArray[0].category_id;
    let questionObj:any = {};
    let finalObj:any = {createBody: []}
    finalObj.createBody.push(this.categoryModifiedArray[0])
    tempQuestionArray.forEach((question:any)=>{
        if(question[0].split('new')[0] == 'question_code_')
          questionObj.question_code = question[1].value;
        if(question[0].split('new')[0] == 'question_item_')
          questionObj.question_item = question[1].value;
        if(question[0].split('new')[0] == 'question_score_')
          questionObj.score = parseInt(question[1].value);
        if(question[0].split('new')[0] == 'question_info_')
          questionObj.question_info = question[1].value;
        if(question[0].split('new')[0] == 'criteria_')
          questionObj.criteria = question[1].value;
        if(question[0].split('new')[0] == 'potential_improvement_status_')
          questionObj.potential_improvement_status = question[1].value;
        if(question[0].split('new')[0] == 'optional_status_')
          questionObj.optional_status = question[1].value;
    });
    questionObj.property_type = this.data.propertyType    
    questionObj.answerArray = []
    console.log(questionObj);
    finalObj.createBody[0].questionArray.push(questionObj);
    finalObj.createBody[0].property_type = this.data.propertyType    
    let score:any = [];
    let answers : any = []
    let sorting_order:any = []
    tempAnswerArray.forEach((item:any, index:number)=>{
      if((index) % 3 == 0)
        answers.push(item.value)
      if((index+1) % 3 == 0)
        score.push(parseInt(item.value))
      if((index+2) % 3 == 0)
        sorting_order.push(parseInt(item.value))
    });
    console.log(answers, score)

    let answerFinalArr:any = []
    answers.forEach((item:any, index:number) => {
      answerFinalArr.push({answer:item, score : score[index], sorting_order : sorting_order[index]})
    })
    questionObj.answerArray = answerFinalArr
    console.log(finalObj,"finalObj");
    this.questionnaireService.createNewQuestionnaire(finalObj).subscribe((res:any) =>{
      console.log("edit Success");
    })
  }

  scrollToElement($element:any): void {
    setTimeout(()=> {
      $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
      this.questionnaireForm.disable();
      this.answerForm.disable();
    },100)  

  }
}



