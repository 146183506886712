import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProjectService } from 'src/app/core/services/project.service';
import { QuestionnaireEditDialogComponent } from 'src/app/shared/components/questionnaire-edit-dialog/questionnaire-edit-dialog.component';
import { EditQuestionnaireAlertComponent } from 'src/app/shared/components/edit-questionnaire-alert/edit-questionnaire-alert.component';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';
import { ReopenWarningComponent } from '../reopen-warning/reopen-warning.component';
import { LoaderService } from 'src/app/shared/services/loader.service';

@Component({
  selector: 'app-project-dashboard',
  templateUrl: './project-dashboard.component.html',
  styleUrls: ['./project-dashboard.component.scss']
})
export class ProjectDashboardComponent implements OnInit {
  displayedColumns: string[] = ['name', 'owner', 'engagementPartner', 'engagementManager', 'startDate', 'endDate', 'lastSaved', 'status' , 'open', 'manage'];
  dataSource : any ;
  p: number = 1;
  userRoleAccess:any;
  noProjectFlag:boolean = false;
  archiveBtn: boolean = false;
  selectedProject: any = [];
  constructor(private projectService: ProjectService, public dialog: MatDialog, private route : Router, private userService:UserService, private loaderService: LoaderService) { }

  ngOnInit(): void {
    this.loaderService.subTitle.next('Admin');
    this.getAllProject();
    this.getUserDetails();
      
    // this.projectService.currentProjectData.subscribe((res:any)=>{
    //   if(res.project_role == 'Admin' || res.project_role == 'SuperAdmin')
    //     this.displayedColumns = ['name', 'owner', 'client', 'date', 'lastSaved', 'status' , 'open', 'manage'];
    //   });
    }

  filterProjects(event: any, archiveStatus: boolean){
    if(event.key.length)
      this.searchProject(event.target.value, archiveStatus)
    else
      this.getAllProject();
  }

  getAllProject(){
    this.projectService.getAllProjectsOfUser(false).subscribe(res => {
      this.dataSource = res;
      this.noProjectFlag = true;
      console.log(this.dataSource);
    } 
    )
    console.log(this.dataSource);
  }

  openQuestionnaireDialog() {
    this.dialog.open(QuestionnaireEditDialogComponent);
  }

  openNewProject(){
    console.log("Create new project");
  }

  openEditQuestDialog(): void{
    const dialogRef = this.dialog.open(EditQuestionnaireAlertComponent, {
      width: '450px',
      height: '250px'
    });
  }

  searchProject(searchText: String, archiveStatus: boolean){
    console.log(searchText);
    this.projectService.getSearchResults(searchText, archiveStatus).subscribe(res => {
      this.dataSource = res;
    } 
    )
  }

  selectProject(event:any,project:any){
    if(event.checked){
      this.archiveBtn = true;
      this.selectedProject.push(project)
    }else{
      this.archiveBtn = false;
      this.selectedProject = this.selectedProject.filter((item:any) => item.project_id !== project.project_id);
    }    
    console.log(this.selectedProject, "Selecte pro")
  }

  archiveProperty(){
    this.selectedProject.forEach((item:any)=>{
      item.archive_status = true;
      this.projectService.archiveProject(item).subscribe((res:any)=>{
        this.getAllProject();
      });
    })
  }

  pageChanged(event:any){
    console.log(event)
  }

  navigateToEdit(project:any){
    this.route.navigate(['/edit-project' , project.project_id])
  }
  
  getUserDetails(){
    this.userService.currentUserDetails.subscribe((res:any) =>{
      this.userRoleAccess = res;   
      if(sessionStorage.getItem('role') === 'Admin' || sessionStorage.getItem('role') === 'SuperAdmin')
        this.displayedColumns = ['archive','name', 'owner','engagementPartner', 'engagementManager', 'startDate', 'endDate', 'lastSaved', 'status' , 'open', 'manage'];
      else
        this.displayedColumns = ['name', 'owner', 'engagementPartner', 'engagementManager', 'startDate', 'endDate', 'lastSaved', 'status' , 'open'];         
    });
  }

  navigateToNewProject(){
    this.route.navigate(['/create-project'])
  }
  navigateToProperties(element:any){
    sessionStorage.setItem('project_id', element.project_id);    
    this.projectService.currentProjectId.next(element.project_id);
    this.projectService.currentProjectData.next(element);
    this.route.navigate(['/dashboard', element.project_id])
  }
}
