import {
  IPublicClientApplication,
  PublicClientApplication,
  BrowserCacheLocation,
  LogLevel,
  InteractionType
} from '@azure/msal-browser';
import {
  MsalInterceptorConfiguration,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import { loggerCallback } from './log-lovel';
import { environment } from 'src/environments/environment';
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.azureADClientId,
      authority: `${environment.azureADAuthority}/${environment.azureTenantId}`, // PPE testing environment.
      redirectUri: environment.azureADRedirectUri,
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(`${environment.azureADGraphEndPoint}/me`, ['User.Read'] ); // Prod environment. Uncomment to use.
  // protectedResourceMap.set(environment.azureADClientId, ['access_as_user'] );
  // protectedResourceMap.set(environment.azureADClientId, [environment.azureADServiceScope]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['User.Read']  
      // scopes: [environment.azureADServiceScope]
    }
  };
}