import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PropertyListingComponent } from './components/property-listing/property-listing.component';
import { PropertySubmissionOverlayComponent } from './components/property-submission-overlay/property-submission-overlay.component';
import { CustomSliderComponent } from './components/custom-slider/custom-slider.component';
import { CustomProgressNavigationComponent } from './components/custom-progress-navigation/custom-progress-navigation.component';



@NgModule({
  declarations: [
    PropertyListingComponent,
    PropertySubmissionOverlayComponent,
    CustomSliderComponent,
    CustomProgressNavigationComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    PropertySubmissionOverlayComponent, CustomSliderComponent, CustomProgressNavigationComponent
  ],
  providers:[DatePipe]
})
export class CoreModule { }
