<div class="duplicate-wrapper">
    <div class="duplicate-header bg-dark text-white row">
        <label class="h6 col-10"> Property  {{data.property.asset_reference_name}} | Duplicate</label>
        <button (click)="closeDialog()" class="fa fa-times fa-lg bg-dark text-white close-button col-2"></button>
    </div>
    <form formGroup="duplicateForm" >
        <div class="row asset-reference-wrapper">
            <label for="" class="col-6">Asset Reference Name</label>
            <input type="text" class="col-6" (change)="assetNameEvent($event)" [value]="data.property.asset_reference_name" [(ngModel)]="assetReferenceName" formControlName="asset_reference_name">
            <label class="col-6"></label>
            <small class="text-danger col-6" *ngIf="data.property.asset_reference_name == assetReferenceName">Name should be changed</small>
        </div>        
        <div class="duplicate-content">
            <p class="h6 text-dark fw-bolder">Select questionnaire</p>
            <hr style="border: 1px solid; color: #E0301E;">
            <div class="row">
                <div class="col-4">
                    <label for="breeam"> BREEAM</label>
                </div>

                <div class="col-4"> 
                    <select formControlName="breeam_select_year" [disabled]="!data.property.breeam_select_status"  [(ngModel)]="breeamYearSelected" (change)="selectBreeamYear($event)">
                        <option value="" disabled selected hidden>Select year</option>
                        <option *ngFor="let year of data.propertyList" [value]="year.year_of_submission">{{year.year_of_submission}}</option>
                    </select> 
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-4">
                    <label for="eu taxonomy"> EU Taxonomy</label>
                </div>
                
                <div class="col-4">
                    <select formControlName="eu_select_year" [disabled]="!data.property.eu_select_status" [(ngModel)]="euYearSelected" (change)="selectEuYear($event)">
                        <option value="" disabled selected hidden>Select year</option>
                        <option *ngFor="let year of data.propertyList" [value]="year.year_of_submission">{{year.year_of_submission}}</option>
                    </select> 
                </div>
            </div>
            <hr>  
        </div>
    </form>

    <div>
       <button class="apply-btn" [disabled]="duplicateFlag" (change)="assetNameEvent($event)" (click)="addNewProperty()" [style.background]="duplicateFlag ? '#7d7d7d' : '#E0301E'">Apply</button>
    </div>
</div>