<div class="esg-header-wrapper">
    <div class="row">
        <div class="col-4 esg-title-wrapper">
            <div class="">
                <span>{{titleName}}</span>
                <div class="header-subtitle-name">
                    {{subTitleName}}
                </div>
            </div>
        </div>
        <div class="col-5 nav-wrapper">
            <div class="nav-bar">
                <a class="nav-item" *ngIf="archievedProjects" routerLink="/archieved-projects">Archived Projects</a>
                <a class="nav-item" *ngIf="adminLink" routerLink="/admin-project-results">Admin</a>
                <a class="nav-item" href="/project-dashboard">My projects</a>
                <!-- <a class="nav-item" href="#GetHelp">Get Help</a> -->
                <div class="user-profile">
                    <img src="assets\icons\user-profile-icon.svg" alt="User" class="user-logo" />
                    <span class="nav-user-name">{{userName}}</span>
                </div>
           </div>
        </div>
        
        <div class="col-3 logo-wrapper">
            <div class="col"></div>
            <div class="logo col">
                <img src="assets\icons\pwc-logo.png" alt="PwC Logo" class="pwc-logo">
                <span class="deals">Deals Accelerators</span>
                <div class="user-logout">
                    <a (click)="logout()" class="logout-link">Logout</a>
                </div>
            </div>
        </div>
    </div>
</div>