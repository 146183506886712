<mat-drawer-container class="example-container" autosize>
    <mat-drawer #drawer opened class="filter-sidenav" mode="side">
        <a class="back-btn" (click)="backNavigation()"><i class="fa fa-angle-left back-icon"></i>Back to projects</a>
        <label class="project-name">{{projectDetails?.project_name}}</label>
        <label class="settings-label">Settings</label>
        <!-- <div class="questionnaire-type-wrapper">
            <p>Type of questionnaire</p>
        </div>        
        <select class="select-type">Select Type
            <option>Commercial</option>
            <option>Residential</option>
        </select> -->
        <div class="client-visibility-wrapper">
            <p>Clients visiblility of results</p>
        </div>
        <div class="results-filter-wrapper">
            <!-- <mat-checkbox  value="true" [checked]="true">BREEAM</mat-checkbox>
            <div class="breeam-category">
                <mat-checkbox  value="true" [checked]="true">Assessment</mat-checkbox>
                <mat-checkbox  value="true" [checked]="true">Summary</mat-checkbox>
            </div> -->
            <section class="breeam-filter-section">
                <span>
                  <mat-checkbox [checked]="allComplete"
                                
                                [indeterminate]="someComplete()"
                                (change)="setAll($event.checked)"
                                [value]="task.name">
                    {{task.name}}
                  </mat-checkbox>
                </span>
                <span>
                  <ul>
                    <li *ngFor="let subtask of task.subtasks">
                      <mat-checkbox [(ngModel)]="subtask.checked"
                                    
                                    (ngModelChange)="updateAllComplete()">
                        {{subtask.name}}
                      </mat-checkbox>
                    </li>
                  </ul>
                </span>
              </section>
            <mat-checkbox  value="true" [checked]="euCheckFlag" (change)="euFilterEvent($event)">EU Taxonomy</mat-checkbox>
        </div>

        <div class="results-visible-toggle-wrapper">
            <mat-slide-toggle [(ngModel)]="resultsChecked" (change)="resultsVisibility($event)"><p class="result-visibility-text">Results visible to clients</p></mat-slide-toggle>
        </div>


        
    </mat-drawer>
    <div class="results-wrapper">
        <app-custom-progress-navigation></app-custom-progress-navigation> 
        <table mat-table [dataSource]="propertiesList?.properties | paginate: { itemsPerPage: 8, currentPage: p }" class="border-top border-danger">
            <ng-container matColumnDef="property_id">
                <th *matHeaderCellDef class="header header-border-bottom">
                </th>
                <td *matCellDef="let element" class="font-w-600"><mat-checkbox [checked]="element.published_status" [disabled]="element.questionnaire_status !== 'Submitted'"  (change)="propertySelect(element,$event)"></mat-checkbox></td>
            </ng-container>
            <ng-container matColumnDef="asset_reference_name">
                <th *matHeaderCellDef class="header header-border-bottom">Asset Reference Name 
                    <i class="fa fa-ellipsis-v"></i>
                </th>
                <td *matCellDef="let element" class="font-w-600"> {{element.asset_reference_name}} </td>
            </ng-container>
        
            <ng-container matColumnDef="country">
            <th *matHeaderCellDef class="header header-border-bottom">Country
                <i class="fa fa-ellipsis-v"></i>
            </th>
            <td  *matCellDef="let element"> {{element.country}} </td>
            </ng-container>
        
            <ng-container matColumnDef="city">
            <th *matHeaderCellDef class="header header-border-bottom">City
                <i class="fa fa-ellipsis-v"></i>
            </th>
            <td  *matCellDef="let element"> {{element.city}} </td>
            </ng-container>
        
            <ng-container matColumnDef="zip_code">
            <th  *matHeaderCellDef class="header header-border-bottom">Zipcode
                <i class="fa fa-ellipsis-v"></i>
            </th>
            <td  *matCellDef="let element"> {{element.zip_code}} </td>
            </ng-container>

            <ng-container matColumnDef="address">
                <th  *matHeaderCellDef class="header header-border-bottom">Address
                    <i class="fa fa-ellipsis-v"></i>
                </th>
                <td  *matCellDef="let element"> {{element.address }} </td>
            </ng-container>

            <ng-container matColumnDef="property_type">
                <th  *matHeaderCellDef class="header header-border-bottom">Property Type 
                    <i class="fa fa-ellipsis-v"></i>
                </th>
                <td  *matCellDef="let element"> {{element.property_type}} </td>
            </ng-container>

            <ng-container matColumnDef="size_of_property">
            <th></th>
            <td></td>
                <th  *matHeaderCellDef class="header header-border-bottom">Size Of Property 
                    <i class="fa fa-ellipsis-v"></i>
                </th>
                <td *matCellDef="let element"> {{element.size_of_property| number}} </td>
            </ng-container>
            <ng-container matColumnDef="current_market_value">
                <th  *matHeaderCellDef class="header header-border-bottom">Current Market Value 
                    <i class="fa fa-ellipsis-v"></i>
                </th>
                <td  *matCellDef="let element">{{element.currency}} {{element.current_market_value| number}} </td>
            </ng-container>
            <ng-container matColumnDef="breeam_select_status">
                <th  *matHeaderCellDef class="header header-border-bottom">Selected Questionnaire
                    <i class="fa fa-ellipsis-v"></i>
                </th>
                <td  *matCellDef="let element"> {{element.breeam_select_status? 'BREEAM' : ''}} {{element.eu_select_status && element.breeam_select_status ? ',': ''}} {{element.eu_select_status? 'EU Taxonomy' : ''}} </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th  *matHeaderCellDef class="header header-border-bottom">Action
                    <i class="fa fa-ellipsis-v"></i>
                </th>
                <td  *matCellDef="let element">
                    <button class="reopen-btn" (click)="reopenAction(element)">Reopen</button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <pagination-controls class="my-pagination" (pageChange)="p = $event" (pageChange)="pageChanged($event)" previousLabel="Previous" nextLabel="Next"></pagination-controls>
    </div>
  
  </mat-drawer-container>