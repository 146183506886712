import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, RedirectRequest, EventMessage, EventType, AuthError } from '@azure/msal-browser';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { UserIdleService } from 'angular-user-idle';
import { environment } from 'src/environments/environment';
import { UserService } from './core/services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'ESG-Asset-Tool';
  loginDisplay = false;
  idTokenClaims: any;
  private readonly _destroying$ = new Subject<void>();
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private userService : UserService,
    private router: Router,
    private userIdle: UserIdleService
  ) {}

  ngOnInit(): void {
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(() => this.showSessionTimerModal());
        
    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => this.logout(false));

    // Logout after 12hrs max session
    // this.userIdle.ping$.subscribe(() => this.logout(false));

    this.setLoginDisplay();
    this.msalService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe((result: EventMessage) => {
        if (this.msalService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          this.setLoginDisplay();
          this.loginRedirect();
        }
      });

    // Login Failure
    this.msalBroadcastService.msalSubject$
     .pipe(
       filter(
         (msg: EventMessage) =>
           msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        if (result.error instanceof AuthError) {
          console.warn('LOGIN_FAILURE App-Shell', result, result.error);
          this.router.navigate(['/login-failed']);
        }
      });

    
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
        this.getTokenSilent();
      });

  }

  setLoginDisplay() {
    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
  }

  showSessionTimerModal() {
    const result = confirm(`Your session is about to expire in ${environment.userIdleTime} secs. Click OK to extend the session.`);
    if(result) {
      this.userIdle.resetTimer();
    }
    return false;
  }

  checkAndSetActiveAccount(){
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest){
      this.msalService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }
  logout(popup?: boolean) {
    if (popup) {
      this.msalService.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    } else {
      this.msalService.logoutRedirect();
    }
  }

  getTokenSilent() {
    let activeAccount = this.msalService.instance.getAllAccounts();
      this.msalService.acquireTokenSilent({
        account: activeAccount[0], // get the current user's account
        // scopes: [environment.azureADServiceScope]
        scopes: ['User.Read']   
    }).subscribe({
      next: (result: AuthenticationResult) => {
        console.log(result);
        sessionStorage.setItem("id_token", result.accessToken);
        sessionStorage.setItem("idTokenClaims", JSON.stringify(result.idTokenClaims));
        this.idTokenClaims = result.idTokenClaims;
        this.getUserDetails();

        //Start watching for user inactivity.
        this.userIdle.startWatching();
      }, 
      error: (error) => {
        // this.msalService.acquireTokenRedirect();
        this.msalService.acquireTokenRedirect({
          scopes: ['User.Read'] 
          // scopes: [environment.azureADServiceScope]    
        });
      }
    });
  }

  getUserDetails(){
    // let sessionKeys:any = Object.keys(sessionStorage);
    // sessionKeys.forEach((element:any, index:any) => {
    //   let temp = sessionStorage.getItem(sessionKeys[index]) || '';
    //   if (temp.charAt(0) == '{')
    //     if(JSON.parse(sessionStorage.getItem(sessionKeys[index]) || '')?.username)
    //       sessionStorage.setItem('email',JSON.parse(sessionStorage.getItem(sessionKeys[index]) || '')?.username)
    // });
    // let email =  sessionStorage.getItem('email');
    const email = this.idTokenClaims.preferred_username;
    if(email) {
      this.userService.getCurrentUserDetails(email).subscribe((res:any) =>{
        sessionStorage.setItem('role',res.user.role)
        sessionStorage.setItem('user_name',res.user.user_name)
        this.userService.currentUserDetails.next(res);
      });
    } else {
        this.router.navigate(['/login-failed']);
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
