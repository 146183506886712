import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { FormBuilder, Validators } from '@angular/forms';
import { PropertiesListingService } from 'src/app/core/services/properties-listing.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { UserService } from 'src/app/core/services/user.service';
import { CategoryService } from 'src/app/core/services/category.service';
import { QuestionnairesService } from 'src/app/core/services/questionnaires.service';
import { compileDeclareClassMetadata, identifierModuleUrl } from '@angular/compiler';
import {MatDialog} from '@angular/material/dialog';
import { QuestionnaireDialogComponent } from 'src/app/shared/components/questionnaire-dialog/questionnaire-dialog.component';
import { DeleteAlertComponent } from 'src/app/shared/components/delete-alert/delete-alert.component';
import { SelectQuestionnaireDialogComponent } from 'src/app/shared/components/select-questionnaire-dialog/select-questionnaire-dialog.component';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar} from '@angular/material/snack-bar';
import { DuplicateDialogComponent } from 'src/app/shared/components/duplicate-dialog/duplicate-dialog.component';
import { ProjectService } from 'src/app/core/services/project.service';
import { DuplicateEditDialogComponent } from 'src/app/shared/components/duplicate-edit-dialog/duplicate-edit-dialog.component';
import { timeStamp } from 'console';
import { ActivatedRoute, Router } from '@angular/router';
import { ImportAlertComponent } from 'src/app/shared/components/import-alert/import-alert.component';
import { DownloadPropertyTemplateService } from 'src/app/core/services/download-property-template.service';
@Component({
  selector: 'app-add-properties',
  templateUrl: './add-properties.component.html',
  styleUrls: ['./add-properties.component.scss']
})
export class AddPropertiesComponent implements OnInit {
  propertiesList:any = {};
  noPropertyFlag:boolean = false;
  questionnairesList:any = [];
  currentQuestion:any;
  currentObject:any;
  currentCategoryColorState: boolean = false;
  submitView:boolean = false;  
  dataSource : any ;
  edit_submit: boolean = false;
  userRoleAccess:any;
  routeProjectId:any;
  authorized: boolean = true;
  constructor(private fb: FormBuilder, private propertiesListing: PropertiesListingService, private loaderService: LoaderService, 
    public dialog: MatDialog, private questionnaireService: QuestionnairesService, private _liveAnnouncer: LiveAnnouncer, 
    private _snackBar: MatSnackBar, private projectService: ProjectService, private userService: UserService, private route: ActivatedRoute,
    private downloadPropertyTemplateService :DownloadPropertyTemplateService, private router: Router) {
  }
    propertyForm = this.fb.group({
      asset_reference_name: ['', Validators.required],
      country: ['', Validators.required],
      city: ['', Validators.required],
      zip_code: ['', Validators.required],
      address: ['', Validators.required],
      property_type: ['', Validators.required],
      size_of_property: ['', Validators.required],
      currency: ['', Validators.required],
      current_market_value: ['' , Validators.required]
    })
  selectedProperty:any;
  selectedPropertiesArray:any = [];
  selectedPropertyFlag:boolean = false;
  duplicatePropertyFlag:boolean = false;
  editStatusFlag: boolean = true;
  deleteStatusFlag:boolean = true;
  questionnaireForm = this.fb.group({
    optionText: ['', Validators.required]
  })
  //categoryId: any = this.questionnairesList.filter(this.questionnairesList.categoryId == 1) ;
  p: number = 1;
  propertyType = [{
    value:'Residential'
  },{
    value: 'Commercial'
  }]
  progressData:any = [
    {name:"Properties", status:"active", leftVal:"4%", visible : true },
    {name:"Publish", status:"inactive", leftVal:"17%", visible : true },
    {name:"Results", status:"inactive", leftVal:"17%", visible : true },
    {name:"BREEAM", status:"inactive", leftVal:"17%", visible : false },
    {name:"EU Taxonomy", status:"inactive", leftVal:"17%", visible : false }
  ];
  contributorProgressData:any = [
    {name:"Properties", status:"active", leftVal:"4%", visible : true },    
    {name:"Results", status:"inactive", leftVal:"17%", visible : true },
    {name:"BREEAM", status:"inactive", leftVal:"17%", visible : false },
    {name:"EU Taxonomy", status:"inactive", leftVal:"17%", visible : false},
    {name:"", status:"inactive", leftVal:"17%", visible : false },
  ];
  projectId:any;
  projectDetails:any;
  reviewStatus:boolean=true;
  finalSubmissionBtn:boolean = false;
  ngOnInit(): void {
    this.loaderService.subTitle.next('Properties');
    this.routeProjectId = this.route.snapshot.paramMap.get('projectId')
    this.loaderService.progressStage.next(this.progressData);
    this.projectService.currentProjectId.subscribe((res:any)=>{
      this.projectId = parseInt(res, 10) || parseInt(this.routeProjectId, 10);
      this.getUserDetails();
      this.getAllProperties(this.projectId);
      this.refreshProperties();            
    });
    // this.getProjectDetails(this.routeProjectId)   
  }

  getProjectDetails(projectId:any){
    this.projectService.getProjectDetails(projectId).subscribe((res:any)=>{
      this.projectDetails = res.projectDetails
      this.projectService.currentProjectId.next(res.projectDetails.project_id)
      this.projectService.currentProjectData.next(res.projectDetails);
      if(this.projectDetails.archive_status)
        this.authorized = false
      this.actionValue();
    }, (err:any) =>{
      if(err.status == 401){
        alert("Unauthorized");
        this.router.navigate(['/project-dashboard']);
        this.authorized = false
      }
    })
  }

  validatePublish(){
    this.projectService.currentProjectData.subscribe((res:any)=>{      
      this.projectDetails = res;
      if(this.projectDetails.archive_status)
        this.authorized = false
      console.log(this.projectDetails.project_role, "projectDetails?.project_role")
      if(!res)
        this.getProjectDetails(this.routeProjectId);
      else
        this.actionValue();
      if(res.project_role == 'Contributor' || res.project_role == 'Viewer'){
        let publistedItems = this.propertiesList.properties.filter((item:any)=> item.questionnaire_status == 'Submitted')
        if(publistedItems.length == 0)
          this.loaderService.progressStage.next(
            [{name:"Properties", status:"active", leftVal:"4%", visible : true },           
            {name:"Results", status:"inactive", leftVal:"17%", visible : false },
            {name:"BREEAM", status:"inactive", leftVal:"17%", visible : false },
            {name:"EU Taxonomy", status:"inactive", leftVal:"17%", visible : false },
            {name:"", status:"inactive", leftVal:"17%", visible : false },])
      }

    });
  }
  public primaryConfig = {
    isPrimary: true
  }
  public secondaryConfig = {
      isSecondary: true
  }
  @ViewChild(MatSort) sort: MatSort = new MatSort;
  baseValue!: string;
  baseLgValue!: string;

  openImportPropertiesAlert(){
    const dialogRef = this.dialog.open(ImportAlertComponent, {
      width: '450px',
      height: '150px'
    });
    dialogRef.afterClosed().subscribe(res =>{
      this.getAllProperties(this.projectId);
    });
  }

  filterBase(event: any) {
    console.log(event)
    if(event.key.length > 2){
      this.propertiesList = {};
      this.p = 1;
      this.searchProperties(event.target.value, this.projectId);
    }
  }

  refreshProperties(){
    this.propertiesListing.submissionView.subscribe((res:any) =>{
      if(!res)
        this.getAllProperties(this.projectId);
    });
    this.propertiesListing.saveQuestionnaire.subscribe((res:any) =>{
      if(res)
        this.getAllProperties(this.projectId);
    })
  }

  getUserDetails(){
    this.userService.currentUserDetails.subscribe((res:any) =>{
      this.userRoleAccess = res;  
      console.log(this.userRoleAccess);          
    });
  }

  addNewProperty(){   
    let data = this.propertyForm.value;
    data.project_id = this.projectId;
    data.current_market_value =  parseInt(data.current_market_value.replace(/,/g, ''));
    data.size_of_property =  parseInt(data.size_of_property.replace(/,/g, ''))   
      this.propertiesListing.addProperty(data).subscribe(res=>{
      this.showNotification();
      this.getAllProperties(this.projectId);
      this.propertyForm.patchValue({
        asset_reference_name:'',
        country: '',
        city: '',
        zip_code: '',
        address: '',
        property_type: '',
        size_of_property: '',
        currency: '',
        current_market_value: ''
      });
    }, (err:any) =>{
      alert(err.error.error)
    });
  }

  showNotification(){
      this._snackBar.open('Property added Successfully', 'Success', {
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: ['success']
      });
  }

  getAllProperties(id:any){
    this.propertiesListing.getAllProperties(id).subscribe((res: any) =>{
      this.propertiesList = res;
      this.noPropertyFlag = true;
      //this.propertiesList.data = res.properties;
      //this.propertiesList.sort = this.sort;
      console.log(this.propertiesList);     
      this.validatePublish();   
      // this.actionValue();  
    })
    console.log(this.propertiesList);
  }

  sortTableData(action:string, column:string){
    this.propertiesList.properties = sortData(action, this.propertiesList.properties, column);
  }
  actionValue(){
    //this.propertiesList.data.forEach((property:any) => {
    this.propertiesList.properties.forEach((property:any) => {      
      if(property.breeam_answers > 0 || property.eu_answers > 0){
        property.actionText = 'Continue';
        property.actionColor = '#3F88C5';
        property.tickMark = '#ffb600';
      }
      if(property.breeam_answers == 0 && property.eu_answers == 0){
        property.actionText = 'Open';
        property.actionColor = '#3F88C5';
        property.tickMark = '#adadad'
      }
      
      if(property.breeam_answers == property.breeam_total_questions || property.eu_answers == property.eu_total_questions && (property.breeam_select_status || property.eu_select_status)){
        property.actionText = 'Review';
        property.actionColor = '#3F88C5';
        property.tickMark = '#299d8f';
      }
      if((property.breeam_answers > 0  &&  property.breeam_answers < property.breeam_total_questions) ||  (property.eu_answers > 0 && property.eu_answers < property.eu_total_questions)){
        property.actionText = 'Continue';
        property.actionColor = '#3F88C5';
        property.tickMark = '#ffb600';
      }
      if(property.reopen_status){
        property.actionText = 'Reopen';
        property.actionColor = '#3F88C5';
        property.tickMark = '#ffb600';
      }
      if(property.review_status == 'UnderReview' && this.projectDetails.project_role == 'Contributor'){
        property.actionText = 'Under Review';
        property.actionColor = '#25B4E8';
        property.tickMark = '#dedede';
      }

      if(property.review_status == 'Reviewed'){
        property.actionText = 'Reviewed';
        property.actionColor = '#3F88C5';
        property.tickMark = '#dedede';
      }
      if(property.review_status == 'UnderReview' && (this.projectDetails.project_role == 'Admin' || this.projectDetails.project_role == 'SuperAdmin')){
        property.actionText = 'To Be Reviewed';
        property.actionColor = '#ffb600';
        property.tickMark = '#dedede';
      }
      if(property.questionnaire_status == 'Submitted'){
        property.actionText = 'View';
        property.actionColor = '#dedede';
        property.tickMark = '#24987B';
        this.selectedPropertiesArray = [];
      }
    });
    if(this.projectDetails.project_role == "Contributor" || this.projectDetails.project_role == "Viewer")
      this.loaderService.progressStage.next(this.contributorProgressData);
  }

  searchProperties(query:any, projectId:any){
    this.propertiesListing.searchProperty(query, projectId).subscribe((res: any)=>{
      //this.propertiesList = res.properties;
      this.propertiesList = res;
      this.actionValue();
    })
  }
  openSelectDialog(property:any){
    const dialogRef = this.dialog.open(SelectQuestionnaireDialogComponent, {
      width: '450px',
      height: '250px',
      data: {
        property,
        project : this.projectDetails
      }
    });
  }

  deleteProperty(){
    let payload = this.selectedProperty.property_id;
    /*this.propertiesListing.deleteProperty(payload).subscribe(res=>{
      this.selectedPropertyFlag = true
      this.getAllProperties(this.projectId);
    })*/
  }

  openDuplicateDialog(){
    const dialogRef = this.dialog.open(DuplicateDialogComponent,{
      width: '450px',
      height: '350px',
      data: {
        property: this.selectedProperty,
        propertyList: this.propertiesList.properties.filter((fil: any) => fil.asset_reference_name == this.selectedProperty.asset_reference_name).filter((v: { year_of_submission: any; },i: any,a: any[])=>a.findIndex(t=>(t.year_of_submission===v.year_of_submission))===i),
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.duplicatePropertyFlag = false;
      // this.editDuplicateFlag = false;
      this.selectedPropertyFlag = false;
      this.selectedPropertiesArray= [];
      this.deleteStatusFlag = true;
      this.editStatusFlag = true;
      this.getAllProperties(this.projectId);
    });
  }

  openEditDialog(){
    const dialogRef = this.dialog.open(DuplicateEditDialogComponent,{
      width: '450px',
      height: '350px',
      data: {
        property: this.selectedProperty,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.editDuplicateFlag = false;
      this.selectedPropertyFlag = false;
      this.duplicatePropertyFlag = false;
      this.getAllProperties(this.projectId);
      this.selectedPropertiesArray = [];
    });
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  propertySelect(property:any, event:any){
    if(event.srcElement.checked){
      this.selectedProperty = property;
      /*if(this.selectedProperty.questionnaire_status == 'Submitted'){
        this.selectedPropertiesArray = [];
      }*/
      this.selectedPropertyFlag = true;
      this.duplicatePropertyFlag = true;      
      if(!this.selectedPropertiesArray.find((prop:any) => prop.property_id == property.property_id))
        this.selectedPropertiesArray.push(property)
      if(this.selectedPropertiesArray.every((item:any) => item.questionnaire_status == 'Submitted') || this.selectedPropertiesArray.length != 1)
        this.editStatusFlag = true;
      else
        this.editStatusFlag = false;
    } else{
      if(this.selectedPropertiesArray.find((prop:any) => prop.property_id == property.property_id)){
        let index = this.selectedPropertiesArray.findIndex((prop:any) => prop.property_id == property.property_id);
        this.selectedPropertiesArray.splice(index, 1)
      }
      this.selectedProperty = null;
      this.selectedPropertyFlag = false;
      this.duplicatePropertyFlag = false;
      // this.editDuplicateFlag = false; 
      if(this.selectedPropertiesArray.every((item:any) => item.questionnaire_status == 'Submitted') || this.selectedPropertiesArray.length != 1)
        this.editStatusFlag = true;
      else
        this.editStatusFlag = false;         
    }
    if(this.selectedPropertiesArray.length)
      this.deleteStatusFlag = false
    else
      this.deleteStatusFlag = true
    this.selectedPropertyStatusCheck();  
    this.reviewStatusCheck();
  }

  submitAnswersForSelectedProperties(){
    this.submitView = true;
    this.propertiesListing.submissionView.next(true);        
  }

  reviewStatusCheck(){
    if(this.projectDetails.project_role == 'Admin' || this.projectDetails.project_role == 'SuperAdmin'){
      let UnderReview = (this.selectedPropertiesArray.every((item:any) => (item.review_status == 'UnderReview')) &&
      this.selectedPropertiesArray.length > 0);   
      if(!UnderReview)
        this.reviewStatus = true;
      else
        this.reviewStatus = false;
    }else{
      let nonSubmitted = (this.selectedPropertiesArray.every((item:any) => (item.actionText == 'Review')) &&
                         this.selectedPropertiesArray.length > 0);      
      if(!nonSubmitted)
        this.reviewStatus = true;
      else
        this.reviewStatus = false;
    }
  }

  selectedPropertyStatusCheck(){    
    this.finalSubmissionBtn = this.selectedPropertiesArray.every((item:any) => (item.actionText == 'Reviewed' && item.conflicts_pending == 0))   
    let sendForSubmission:boolean = this.selectedPropertiesArray.every((item:any) => (item.actionText == 'To Be Reviewed' || item.actionText == 'Reviewed' || item.actionText == 'Review')) 
    if((this.projectDetails.project_role == 'Admin' || this.projectDetails.project_role == 'SuperAdmin') && sendForSubmission)
      this.finalSubmissionBtn = true; 
    if(this.selectedPropertiesArray.length == 0)
      this.finalSubmissionBtn = false;   
  }

  sendForReiview(){
    let payload;
    if(this.projectDetails.project_role == 'Admin' ||this.projectDetails.project_role == 'SuperAdmin')
      payload = {property_id : this.selectedProperty.property_id, review_status: 'Reviewed'}
    else
     payload = {property_id : this.selectedProperty.property_id, review_status: 'UnderReview'}
    this.propertiesListing.questionnaireSelection(payload).subscribe((res:any) =>{
      this.getAllProperties(this.projectId);
    })
  }

   deletePropertyAction() {
    if (confirm("Are you sure to delete the selected property") == true) {
      //this.deleteProperty(this.selectedProperty.property_id)
      this.deleteProperty();
    } else {
      
    }
  }

  getAllQuestionnaires(category: string, propertyType:string){
    this.questionnaireService.getAllQuestionnaires(category, propertyType, false).subscribe(res =>{
      this.questionnairesList = res;
      this.questionnairesList[0].selectedItem = true;
      this.questionnairesList[0].visited = true;
      this.currentQuestion = this.questionnairesList[0].questions; 
    })
  }

  categoryClick(event:any){
    
    this.filterQuestionnaire(parseInt(event.currentTarget.id));
    this.questionnairesList.forEach((element:any) => {
      if(element.category_id == event.currentTarget.id){
        element.selectedItem = true;
      }else{
        element.selectedItem = false;
      }
      if(element.category_id == event.currentTarget.id){
        element.visited = true;
      }
    });
    this.currentQuestion = this.currentObject[0].questions;
    this.currentCategoryColorState = true;
    //console.log(this.currentQuestion);
  }

  filterQuestionnaire(id:Number){
     this.currentObject =  this.questionnairesList.filter(function (fil: any){
      return fil.category_id == id;
    });
  }

  pageChanged(event:any){
    console.log(event)
  }
  
  openDeleteDialog() {
    let poperties = this.selectedPropertiesArray.map((item: any) => item.property_id);
    console.log(poperties);
    const dialogRef = this.dialog.open(DeleteAlertComponent,{
      data: {id: poperties},
    });
    dialogRef.afterClosed().subscribe(result => {
      this.selectedPropertyFlag = false;
      this.duplicatePropertyFlag = false;
      this.deleteStatusFlag = true;
      // this.editDuplicateFlag = false;
      this.getAllProperties(this.projectId);
      this.selectedPropertiesArray = [];
    });
  }

  currencySelect(event: any){
    if(event.target.value){
      this.selectedProperty = event.target.value;
    }
  }

  commaFormattedCurrency(event:any) {
    if(event.which >= 37 && event.which <= 40) return;

    if (event.target.value) {
      this.propertyForm.get('current_market_value')?.patchValue(event.target.value.replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    }}

    commaFormattedArea(event:any) {
      if(event.which >= 37 && event.which <= 40) return;
  
      if (event.target.value) {
        this.propertyForm.get('size_of_property')?.patchValue(event.target.value.replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","));
      }}
   
   numberCheck (args:any) {
   if (args.key === 'e' || args.key === '+' || args.key === '-') {
     return false;
   } else {
     return true;
   }}

   downloadPropertyTemplate(){
    if(this.selectedPropertiesArray[0].property_type == "Commercial"){
      this.downloadPropertyTemplateService.getAllQuestionnaires(this.selectedPropertiesArray)
    }
    if(this.selectedPropertiesArray[0].property_type == "Residential"){
      this.downloadPropertyTemplateService.getResidentialQuestionnaire(this.selectedPropertiesArray)
    }
   }
}

function sortData(type:String, data:any, column:any){
  if(type == 'asc')
    return data.sort((firstItem:any, secondItem:any) => (firstItem[column] < secondItem[column] ? -1: 1));
  if(type== 'desc')
    return data.sort((firstItem:any, secondItem:any) => (firstItem[column] > secondItem[column] ? -1: 1));
}