<mat-drawer-container class="example-container" autosize>
    <mat-drawer #drawer opened class="filter-sidenav" mode="side">
      <a class="back-btn" (click)="backNavigation()"><i class="fa fa-angle-left back-icon"></i>Back to projects</a>
        <label class="project-name">{{projectDetails?.project_name}}</label>
        <!-- <label class="settings-label">Settings</label> -->
        <!-- <div class="questionnaire-type-wrapper">
            <p>Type of questionnaire</p>
        </div>        
        <select class="select-type">Select Type
            <option>Commercial</option>
            <option>Residential</option>
        </select> -->
        <!-- <div class="client-visibility-wrapper">
            <p>Clients visiblility of results</p>
        </div> -->
        <!-- <div class="results-filter-wrapper"> -->
            <!-- <mat-checkbox  value="true" [checked]="true">BREEAM</mat-checkbox>
            <div class="breeam-category">
                <mat-checkbox  value="true" [checked]="true">Assessment</mat-checkbox>
                <mat-checkbox  value="true" [checked]="true">Summary</mat-checkbox>
            </div> -->
            <!-- <section class="breeam-filter-section">
                <span>
                  <mat-checkbox [checked]="allComplete"
                                
                                [indeterminate]="someComplete()"
                                (change)="setAll($event.checked)"
                                [value]="task.name">
                    {{task.name}}
                  </mat-checkbox>
                </span>
                <span>
                  <ul>
                    <li *ngFor="let subtask of task.subtasks">
                      <mat-checkbox [(ngModel)]="subtask.checked"
                                    
                                    (ngModelChange)="updateAllComplete()">
                        {{subtask.name}}
                      </mat-checkbox>
                    </li>
                  </ul>
                </span>
              </section> -->
            <!-- <mat-checkbox  value="true" [checked]="euCheckFlag" (change)="euFilterEvent($event)">EU Taxonomy</mat-checkbox> -->
        <!-- </div> -->

        <!-- <div class="results-visible-toggle-wrapper">
            <mat-slide-toggle [(ngModel)]="resultsChecked" (change)="resultsVisibility()">Results visible to clients</mat-slide-toggle>
        </div> -->


        
    </mat-drawer>
    <mat-icon (click)="drawer.toggle()" class="menu-btn">compare_arrows</mat-icon>
    <div class="results-wrapper">        
      <app-property-results></app-property-results>
    </div>
  
  </mat-drawer-container>