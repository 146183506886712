import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  constructor(private msalService: MsalService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = sessionStorage.getItem("id_token");
    let headers = new HttpHeaders({
      "Access-Control-Allow-Origin": "*",
      "Authorization": `Bearer ${accessToken}`,
      "Accept": "application/json",            
      "X-Frame-Options": "Deny",
      "X-XSS-Protection": "1",
      "X-Content-Type-Opt": "nosniff"
    });
    const modifiedReq = req.clone({headers});
    return next.handle(modifiedReq);
  }
}
