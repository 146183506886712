export class ProgressStage{
    public static breeamProgress = [
        {name:"Properties", status:"active", leftVal:"4%", visible : true },
        {name:"Results", status:"active", leftVal:"17%", visible : true },
        {name:"BREEAM", status:"active", leftVal:"17%", visible : true },
        {name:"EU Taxonomy", status:"inactive", leftVal:"17%", visible : true },
        {name:"", status:"inactive", leftVal:"17%", visible : false },
      ];
      public static breeamAdminProgress = [
        {name:"Properties", status:"active", leftVal:"4%", visible : true },
        {name:"Publish", status:"active", leftVal:"17%", visible : true },
        {name:"Results", status:"active", leftVal:"17%", visible : true },
        {name:"BREEAM", status:"active", leftVal:"17%", visible : true },
        {name:"EU Taxonomy", status:"inactive", leftVal:"17%", visible : true }
      ];
      public static euTaxonomyProgress = [
        {name:"Properties", status:"active", leftVal:"4%", visible : true },
        {name:"Results", status:"active", leftVal:"17%", visible : true },
        {name:"BREEAM", status:"active", leftVal:"17%", visible : true },
        {name:"EU Taxonomy", status:"active", leftVal:"17%", visible : true },
        {name:"", status:"inactive", leftVal:"17%", visible : false },
      ];
      public static euTaxonomyAdminProgress = [
        {name:"Properties", status:"active", leftVal:"4%", visible : true },
        {name:"Publish", status:"active", leftVal:"17%", visible : true },
        {name:"Results", status:"active", leftVal:"17%", visible : true },
        {name:"BREEAM", status:"active", leftVal:"17%", visible : true },
        {name:"EU Taxonomy", status:"active", leftVal:"17%", visible : true }
      ];
      public static euTaxonomyContributorProgress = [
        {name:"Properties", status:"active", leftVal:"4%", visible : true },       
        {name:"Results", status:"active", leftVal:"17%", visible : true },
        {name:"BREEAM", status:"active", leftVal:"17%", visible : true },
        {name:"EU Taxonomy", status:"active", leftVal:"17%", visible : true },
        {name:"", status:"active", leftVal:"17%", visible : false },
      ];
      public static progressDataBreeam: any =[
        {name:"Properties", status:"active", leftVal:"4%", visible : true },
        {name:"Publish", status:"active", leftVal:"17%", visible : true },
        {name:"Results", status:"active", leftVal:"17%", visible : true },
        {name:"BREEAM", status:"inactive", leftVal:"17%", visible : true },
        {name:"EU Taxonomy", status:"inactive", leftVal:"17%", visible : false }
      ];
      public static progressDataEU: any =[
        {name:"Properties", status:"active", leftVal:"4%", visible : true },
        {name:"Publish", status:"active", leftVal:"17%", visible : true },
        {name:"Results", status:"active", leftVal:"17%", visible : true },
        {name:"BREEAM", status:"inactive", leftVal:"17%", visible : false },
        {name:"EU Taxonomy", status:"inactive", leftVal:"17%", visible : true }
      ];
      public static progressData:any = [
        {name:"Properties", status:"active", leftVal:"4%", visible : true },
        {name:"Publish", status:"active", leftVal:"17%", visible : true },
        {name:"Results", status:"active", leftVal:"17%", visible : true },
        {name:"BREEAM", status:"inactive", leftVal:"17%", visible : true },
        {name:"EU Taxonomy", status:"inactive", leftVal:"17%", visible : true }
      ];
      public static contributorProgressDataBreeam:any = [
        {name:"Properties", status:"active", leftVal:"4%", visible : true },   
        {name:"Results", status:"active", leftVal:"17%", visible : true },
        {name:"BREEAM", status:"inactive", leftVal:"17%", visible : true },
        {name:"EU Taxonomy", status:"inactive", leftVal:"17%", visible : false },
        {name:"", status:"inactive", leftVal:"17%", visible : false },
      ];
      public static contributorProgressDataEU:any = [
        {name:"Properties", status:"active", leftVal:"4%", visible : true },   
        {name:"Results", status:"active", leftVal:"17%", visible : true },
        {name:"BREEAM", status:"inactive", leftVal:"17%", visible : false },
        {name:"EU Taxonomy", status:"inactive", leftVal:"17%", visible : true },
        {name:"", status:"inactive", leftVal:"17%", visible : false },
      ];
      public static contributorProgressDataEUBreeam:any = [
        {name:"Properties", status:"active", leftVal:"4%", visible : true },   
        {name:"Results", status:"active", leftVal:"17%", visible : true },
        {name:"BREEAM", status:"active", leftVal:"17%", visible : true },
        {name:"EU Taxonomy", status:"inactive", leftVal:"17%", visible : true },
        {name:"", status:"inactive", leftVal:"17%", visible : false },
      ];
      public static contributorExternalNoResults:any = [
        {name:"Properties", status:"active", leftVal:"4%", visible : true },   
        {name:"Results", status:"active", leftVal:"17%", visible : true },
        {name:"BREEAM", status:"inactive", leftVal:"17%", visible : false },
        {name:"EU Taxonomy", status:"inactive", leftVal:"17%", visible : false },
        {name:"", status:"inactive", leftVal:"17%", visible : false },
      ];
      public static progressProjectCreate = [
        {name:"Enter Project Details", status:"active", leftVal:"4%", visible : true },
        {name:"Add Users & Assign Role", status:"inactive", leftVal:"17%", visible : true },
        {name:"Preview", status:"inactive", leftVal:"17%", visible : true },
        {name:"Submit", status:"inactive", leftVal:"17%", visible : false },
        {name:"", status:"inactive", leftVal:"17%", visible : false },
      ];
      public static addUsersProject = [
        {name:"Enter Project Details", status:"active", leftVal:"4%", visible : true },
        {name:"Add Users & Assign Role", status:"active", leftVal:"17%", visible : true },
        {name:"Preview", status:"inactive", leftVal:"17%", visible : true },
        {name:"Submit", status:"inactive", leftVal:"17%", visible : false },
        {name:"", status:"inactive", leftVal:"17%", visible : false },
      ];
      public static previewPorject = [
        {name:"Enter Project Details", status:"active", leftVal:"4%", visible : true },
        {name:"Add Users & Assign Role", status:"active", leftVal:"17%", visible : true },
        {name:"Preview", status:"active", leftVal:"17%", visible : true },
        {name:"Submit", status:"inactive", leftVal:"17%", visible : false },
        {name:"", status:"inactive", leftVal:"17%", visible : false },
      ];

}