import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectService } from 'src/app/core/services/project.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss']
})
export class CreateProjectComponent implements OnInit {
  questionnaireSelect: boolean = false;

  constructor( private loaderService: LoaderService, private fb: FormBuilder, private projectService: ProjectService, private router: Router,
    private userService: UserService) { }
  progressProjectCreate = [
    {name:"Enter Project Details", status:"active", leftVal:"4%", visible : true, disabled: false },
    {name:"Add Users & Assign Role", status:"inactive", leftVal:"17%", visible : true, disabled: true },
    {name:"Preview", status:"inactive", leftVal:"17%", visible : true, disabled: true },
    {name:"Submit", status:"inactive", leftVal:"17%", visible : false, disabled: true  },
    {name:"", status:"inactive", leftVal:"17%", visible : false, disabled: true  }
  ];

  progressAddUser = [
    {name:"Enter Project Details", status:"active", leftVal:"4%", visible : true, disabled: true , completed: true},
    {name:"Add Users & Assign Role", status:"active", leftVal:"17%", visible : true, disabled: false },
    {name:"Preview", status:"inactive", leftVal:"17%", visible : true, disabled: true },
    {name:"Submit", status:"inactive", leftVal:"17%", visible : false, disabled: true },
    {name:"", status:"inactive", leftVal:"17%", visible : false, disabled: true  }
  ];

  progressPreview = [
    {name:"Enter Project Details", status:"active", leftVal:"4%", visible : true ,disabled: true , completed: true},
    {name:"Add Users & Assign Role", status:"active", leftVal:"17%", visible : true, disabled: true , completed: true },
    {name:"Preview", status:"active", leftVal:"17%", visible : true , disabled: true },
    {name:"Submit", status:"inactive", leftVal:"17%", visible : false, disabled: true  },
    {name:"", status:"inactive", leftVal:"17%", visible : false, disabled: true  }
  ];
  usersArray:any = [];
  internalUserArray:any=[];
  clientUserArray:any = [];
  selectedNavIndex:number = 1;
  projectDetails:any = {userArray:[]};
  finalObjectToPreview:any;
  engagementDetails:any = [];
  userValidationFlag: boolean = false;  
  currentUser:any;
  maxExpectedStartDate:any;
  projectForm = this.fb.group({
    project_name :  new FormControl(this.projectDetails.project_name || '', Validators.required),
    engagement_partner: new FormControl(this.projectDetails.engagement_partner || ''),   
    engagement_partner_email: new FormControl('',[Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,5}$') ]),
    engagement_manager : new FormControl(''),
    engagement_manager_email : new FormControl('', [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,5}$') ]),
    expected_start_date: new FormControl('', Validators.required),
    expected_end_date: new FormControl('', Validators.required),
    eu_status: new FormControl(false),
    breeam_status: new FormControl(false)
  });

  internalUserForm = this.fb.group({
    user_name : new FormControl(this.internalUserArray[0] || '', Validators.required),
    email: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,5}$')]),
    role: new FormControl(this.internalUserArray[2] || '', Validators.required)
  })

  clientUserForm =  this.fb.group({
    user_name : new FormControl(this.clientUserArray[0] || '', Validators.required),
    email: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,5}$') ]),
    role: new FormControl(this.clientUserArray[2] || '', Validators.required)
  })
  ngOnInit(): void {
    this.loaderService.subTitle.next('Admin');
    this.loaderService.progressStage.next(this.progressProjectCreate);
    this.navigationEvent();
    console.log(this.projectDetails);
    this.currentUserDetails();
  }

  customEngagementPartnerValidator(){
    const engagement_partner_email = this.projectForm.get('engagement_partner_email');
    this.projectForm.get('engagement_partner')?.valueChanges.subscribe( (formItem:any) =>{ 
      if(formItem)
        engagement_partner_email?.setValidators([Validators.required]);
      else
        engagement_partner_email?.setValidators(null);      
    }); 
    engagement_partner_email?.updateValueAndValidity();

    const engagement_partner = this.projectForm.get('engagement_partner');
    this.projectForm.get('engagement_partner_email')?.valueChanges.subscribe( (formItem:any) =>{ 
      if(formItem)
        engagement_partner?.setValidators([Validators.required]);
      else
        engagement_partner?.setValidators(null);
    });
    engagement_partner?.updateValueAndValidity();
  }

  customEngagementManagerValidator(){
    const engagement_manager_email = this.projectForm.get('engagement_manager_email');
    this.projectForm.get('engagement_manager')?.valueChanges.subscribe( (formItem:any) =>{ 
      if(formItem)
        engagement_manager_email?.setValidators([Validators.required]);
      else
        engagement_manager_email?.setValidators(null);
      engagement_manager_email?.updateValueAndValidity();
    }); 

    const engagement_manager = this.projectForm.get('engagement_manager');
    this.projectForm.get('engagement_manager_email')?.valueChanges.subscribe( (formItem:any) =>{ 
      if(formItem)
        engagement_manager?.setValidators([Validators.required]);
      else
        engagement_manager?.setValidators(null);
      engagement_manager?.updateValueAndValidity();
    });
  }

  navigationEvent(){
    this.loaderService.progressStage.subscribe((res:any) =>{
      if(res[0].status =='active'){
        this.selectedNavIndex = 1;
      }
      if(res[0].status =='active' && res[1].status =='active'){
        this.selectedNavIndex = 2;
        this.projectDetails = this.projectForm.value;
        this.projectDetails.project_status = 'New';
      }
      if(res[0].status =='active' && res[1].status =='active' && res[2].status =='active'){
        this.selectedNavIndex = 3;
        this.submitUsers()
      }
    });
  }

  createProject(){

   /* let ar = this.projectDetails.userArray.filter((item:any) => item.user_name == '' || item.email == '');
    console.log(ar);
    if(ar.length > 1){
      this.projectDetails = this.projectDetails.userArray.slice(-2);
    }*/
      
    this.projectService.createProject(this.projectDetails).subscribe((res:any) =>{
      console.log("project created");
      this.router.navigate(['/project-dashboard']);
    })
  }

  partnerEmailValidation(event:any){    
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(!event.target.value.match(mailformat))
      this.projectForm.get('engagement_partner_email')?.setErrors({pattern:true})
    if(event.target.value == this.currentUser.user.email)
      this.projectForm.get('engagement_partner_email')?.setErrors({SuperAdmin:true})
    if(!this.projectForm.get('engagement_partner')?.value.length)
      this.projectForm.get('engagement_partner')?.setErrors({required:true})
    if(!this.projectForm.get('engagement_partner')?.value.length && !this.projectForm.get('engagement_partner_email')?.value.length){
      this.projectForm.get('engagement_partner')?.setErrors({required:null})
      this.projectForm.get('engagement_partner_email')?.setErrors({required:null})
      this.projectForm.get('engagement_partner')?.updateValueAndValidity();
      this.projectForm.get('engagement_partner_email')?.updateValueAndValidity();
    }
  }

  partnerValidation(event:any){    
    if(!this.projectForm.get('engagement_partner_email')?.value.length)
      this.projectForm.get('engagement_partner_email')?.setErrors({required:true})
    if(!this.projectForm.get('engagement_partner')?.value.length)
      this.projectForm.get('engagement_partner')?.setErrors({required:true})
    if(!this.projectForm.get('engagement_partner')?.value.length && !this.projectForm.get('engagement_partner_email')?.value.length){
      this.projectForm.get('engagement_partner')?.setErrors({required:null})
      this.projectForm.get('engagement_partner_email')?.setErrors({required:null})
      this.projectForm.get('engagement_partner')?.updateValueAndValidity();
      this.projectForm.get('engagement_partner_email')?.updateValueAndValidity();
    }
  }

  managerEmailValidation(event:any){
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(!event.target.value.match(mailformat))
      this.projectForm.get('engagement_manager_email')?.setErrors({pattern:true})
    if(event.target.value == this.currentUser.user.email)
      this.projectForm.get('engagement_manager_email')?.setErrors({SuperAdmin:true})
    if(!this.projectForm.get('engagement_manager')?.value.length)
      this.projectForm.get('engagement_manager')?.setErrors({required:true})
    if(!this.projectForm.get('engagement_manager')?.value.length && !this.projectForm.get('engagement_manager_email')?.value.length){
      this.projectForm.get('engagement_manager')?.setErrors({required:null})
      this.projectForm.get('engagement_manager_email')?.setErrors({required:null})
      this.projectForm.get('engagement_manager')?.updateValueAndValidity();
      this.projectForm.get('engagement_manager_email')?.updateValueAndValidity();
    }
  }

  managerValidation(event:any){    
    if(!this.projectForm.get('engagement_manager_email')?.value.length)
      this.projectForm.get('engagement_manager_email')?.setErrors({required:true})
    if(!this.projectForm.get('engagement_manager')?.value.length)
      this.projectForm.get('engagement_manager')?.setErrors({required:true})
    if(!this.projectForm.get('engagement_manager')?.value.length && !this.projectForm.get('engagement_manager_email')?.value.length){
      this.projectForm.get('engagement_manager')?.setErrors({required:null})
      this.projectForm.get('engagement_manager_email')?.setErrors({required:null})
      this.projectForm.get('engagement_manager')?.updateValueAndValidity();
      this.projectForm.get('engagement_manager_email')?.updateValueAndValidity();
    }
  }

  internalUserEmailValidation(event:any){
    var mailformat = /^[a-z0-9._%+-]+@pwc\.com$/;
    if(!event.target.value.match(mailformat)){
      this.internalUserForm.get('email')?.setErrors({pattern:true});
    }
    if(event.target.value == this.currentUser.user.email)
      this.internalUserForm.get('email')?.setErrors({SuperAdmin:true})
    if(this.internalUserArray.find((item:any) => item.email == event.target.value))
      this.internalUserForm.get('email')?.setErrors({duplicate:true})
  }

  externalUserEmailValidation(event:any){
    var mailformat = /^[a-z0-9._%+-]+@pwc\.com$/;
    if(event.target.value.match(mailformat)){
      this.clientUserForm.get('email')?.setErrors({pattern:true});
    }
    if(event.target.value == this.currentUser.user.email)
      this.clientUserForm.get('email')?.setErrors({SuperAdmin:true})
    if(this.clientUserArray.find((item:any) => item.email == event.target.value))
      this.clientUserForm.get('email')?.setErrors({duplicate:true})
  }

  currentUserDetails(){
    this.userService.currentUserDetails.subscribe((res:any) =>{
      this.currentUser = res;
      console.log(res, "User details")
    });
  }

  projectDetailsHold(){
    this.projectDetails = this.projectForm.value;
    this.projectDetails.project_status = 'New';
    if(this.engagementDetails.length == 0){
      if(this.projectForm.get('engagement_partner_email')?.value && this.projectForm.get('engagement_partner')?.value){
        this.engagementDetails.push({email: this.projectForm.get('engagement_partner_email')?.value,role: 'Viewer',
        user_name: this.projectForm.get('engagement_partner')?.value, user_type: "Internal", designation: 'EngagementPartner'});
      }
      if(this.projectForm.get('engagement_manager_email')?.value && this.projectForm.get('engagement_manager')?.value){
        this.engagementDetails.push({email: this.projectForm.get('engagement_manager_email')?.value,role: 'Admin',
        user_name: this.projectForm.get('engagement_manager')?.value, user_type: "Internal", designation: 'EngagementManager'});
      }
    }
    this.loaderService.progressStage.next(this.progressAddUser);
    this.selectedNavIndex = 2;
    console.log(this.projectDetails, "projectDetails")
  }
  
  openProjectDashboard(){
    this.router.navigate(['/project-dashboard']);
  }
  openCreateProjectDetails(){
    this.loaderService.progressStage.next(this.progressProjectCreate);
    this.selectedNavIndex = 1;
  }
  openUserRole(){
    this.loaderService.progressStage.next(this.progressAddUser);
    this.selectedNavIndex = 2;
  }

  addInternalUsers(){
    let user = this.internalUserForm.value;
    user.user_type = 'Internal';
    this.internalUserArray.push(user);
    this.internalUserForm.reset();
    this.userValidationFlag = true;
  }

  addClientUsers(){
    let user = this.clientUserForm.value;
    user.user_type = 'External';
    // user.role = 'Client';
    this.clientUserArray.push(user);
    this.clientUserForm.reset();
    this.userValidationFlag = true;
  }

  submitUsers(){
    this.usersArray = this.internalUserArray.concat(this.clientUserArray)
    if(this.engagementDetails){
      this.usersArray = this.usersArray.concat(this.engagementDetails);
      /*let engagementRole = this.engagementDetails.filter((item: any) => item.role == "Viewer");
      if(engagementRole[0].role == "Viewer"){
        let userDesignation = this.usersArray.filter((item: any) => item.designation = "EngagementPartner");
        this.usersArray.push(userDesignation);
      }*/
    }
    this.projectDetails.userArray = this.usersArray;
    this.loaderService.progressStage.next(this.progressPreview);
    this.projectDetails.client_name = "XYZ";
    this.selectedNavIndex = 3;
  }

  deleteInternalUser(email:string){
    this.internalUserArray = this.internalUserArray.filter(function(obj:any) {
      return obj.email !== email;
    });
  }

  deleteClientUser(email:string){
    this.clientUserArray = this.clientUserArray.filter(function(obj:any) {
      return obj.email !== email;
    });
  }

  expectedEndDateEvent(event:any){
    this.maxExpectedStartDate = event.target.value
  }

  breeamChange(event: any){
    if(this.projectForm.get('breeam_status')?.value || this.projectForm.get('eu_status')?.value)
      this.questionnaireSelect = true
    else
      this.questionnaireSelect = false
    console.log(this.projectForm.invalid , this.questionnaireSelect)
  }

  euChange(event: any){
    if(this.projectForm.get('breeam_status')?.value || this.projectForm.get('eu_status')?.value)
      this.questionnaireSelect = true
    else
      this.questionnaireSelect = false
  }
}