import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PropertiesListingService } from 'src/app/core/services/properties-listing.service';

@Component({
  selector: 'app-delete-alert',
  templateUrl: './delete-alert.component.html',
  styleUrls: ['./delete-alert.component.scss']
})
export class DeleteAlertComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private propertiesListing: PropertiesListingService,
  public dialogRef: MatDialogRef<DeleteAlertComponent>,) { }

  ngOnInit(): void {
    console.log(this.data);
  }
  deleteProperty(){
    //let payload: any = [];
    //payload.property_id = this.data.id;
    let payload: any = {};
    payload.property_id = this.data.id;
    console.log(payload, "payloadpayload")
    this.propertiesListing.deleteProperty(payload).subscribe(res=>{
      this.closeDialog();
    })
  }

  closeDialog(){
    this.dialogRef.close();
  }
}
