import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PropertiesListingService } from 'src/app/core/services/properties-listing.service';

@Component({
  selector: 'app-duplicate-edit-dialog',
  templateUrl: './duplicate-edit-dialog.component.html',
  styleUrls: ['./duplicate-edit-dialog.component.scss'],
})
export class DuplicateEditDialogComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DuplicateEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private propertiesListing: PropertiesListingService
  ) {}
  editForm: FormGroup = this.fb.group({
    property_id: ['', Validators.required],
    asset_reference_name: ['', Validators.required],
    country: ['', Validators.required],
    city: ['', Validators.required],
    zip_code: ['', Validators.required],
    address: ['', Validators.required],
    size_of_property: ['', Validators.required],
    currency: ['', Validators.required],
    current_market_value: ['', Validators.required],
  });

  ngOnInit(): void {
    console.log(this.data);
    this.editForm.get('currency')?.patchValue(this.data.property.currency);
  }

  edit(event: any) {
    this.editForm
      .get(event.currentTarget.attributes.formcontrolname.value)
      ?.patchValue(event.target.value);
    this.editForm
      .get(event.currentTarget.attributes.formcontrolname.value)
      ?.markAsDirty();
  }
  showNotification() {
    this._snackBar.open('Edit Successfully', 'Success', {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: ['success'],
    });
  }
  editDuplicateProperty() {
    let payload = this.editForm.value;
    payload.property_id = this.data.property.property_id;
    payload.project_id = this.data.property.project_id;
    payload.current_market_value = parseInt(payload.current_market_value);
    for (const prop in payload) {
      if (!payload[prop]) {
        delete payload[prop];
      }
    }
    this.propertiesListing.updateProperty(payload).subscribe((res) => {});
    this.showNotification();
    this.closeDialog();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
