import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ProjectService } from 'src/app/core/services/project.service';
import { PropertiesListingService } from 'src/app/core/services/properties-listing.service';
import { QuestionnairesService } from 'src/app/core/services/questionnaires.service';
import { UserService } from 'src/app/core/services/user.service';
import { EuQuestionnaireService } from '../../services/euQuestionnaire.service';
import { SelectQuestionnaireDialogComponent } from '../select-questionnaire-dialog/select-questionnaire-dialog.component';
@Component({
  selector: 'app-questionnaire-dialog',
  templateUrl: './questionnaire-dialog.component.html',
  styleUrls: ['./questionnaire-dialog.component.scss']
})
export class QuestionnaireDialogComponent implements OnInit {
  private _snackBar: any;
  userRoleAccess: any;
  projectData: any;
  currentAnswersCount: number = 0;

  constructor(private fb: FormBuilder, private questionnaireService: QuestionnairesService, 
    @Inject(MAT_DIALOG_DATA) public data:any, public dialog: MatDialog, public quesDialogRef: MatDialogRef<QuestionnaireDialogComponent>,
    public selectdialogRef: MatDialogRef<SelectQuestionnaireDialogComponent>, public euService: EuQuestionnaireService,
    private propertiesListing: PropertiesListingService, private userService: UserService, private projectService: ProjectService) { }

  questionnairesList: any = [];
  currentObject:any;
  currentQuestion:any = [];
  public firstselectDate: string = "Select date";
  displayDateFlag: boolean = false;
  selectedDateFlag: boolean = false;
  selectedDate: any;
  finalSaveObject:any = [];
  euResult: boolean = false;
  answersOfQuestionnaire:any = [];
  disableFullForm:boolean=false;
  questionnaireForm = this.fb.group({
  });
  eufilters:any = [];
  previousBtnStatus:boolean = false;
  nextBtnStatus:boolean = false;
  ngOnInit(): void {
    this.quesDialogRef.updateSize('90%', '90%');
    this.getProjectRole();
    console.log(this.data, "this.data")
    if(this.data.actionText == 'Continue' || this.data.actionText == 'Review' || this.data.actionText == 'Open')
      this.getAllQuestionnaires();
    else
      this.getAllSubmittedQuestionnaires();
    this.getUserDetails();
    this.disableQuestionnaireForm();
    console.log(this.data)    
  }


  getProjectRole(){
    this.projectService.currentProjectData.subscribe((res:any)=>{
      this.projectData = res;      
    });
  }

  updatePosition(){
    document.getElementsByClassName('question-wrapper')[0].scroll(0,0)
  }
  getAllQuestionnaires(){
    this.questionnaireService.getAllQuestionnaires(this.data.categoryType, this.data.propertyType, false).subscribe(res =>{  
      this.questionnairesList = res;      
      this.questionnairesList[0].selectedItem = true;
      this.questionnairesList[0].visited = true;
      this.currentObject = this.questionnairesList[0];
      this.currentQuestion = this.questionnairesList[0].questions;
      this.getAnswersOfQuestionnaire();  
      this.previousBtnStatus = true;    
    })
  }

  getAllSubmittedQuestionnaires(){
    this.questionnaireService.getAllSubmittedQuestionnaires(this.data.propertyId, this.data.categoryType, this.data.propertyType).subscribe(res =>{  
      this.questionnairesList = res;      
      this.questionnairesList[0].selectedItem = true;
      this.questionnairesList[0].visited = true;
      this.currentObject = this.questionnairesList[0];
      this.currentQuestion = this.questionnairesList[0].questions;
      this.getAnswersOfQuestionnaire();  
      this.previousBtnStatus = true;    
    })
  }

  initilizeForm(){
    this.currentQuestion.forEach((question:any) =>{
      if(!this.questionnaireForm.get('question_'+question.question_id)){
        this.questionnaireForm.addControl('question_'+question.question_id, new FormControl('', Validators.required))
      }else{
        question.answers.forEach((answer:any)=>{
          if(answer.answer_id == this.questionnaireForm.value['question_'+question.question_id].answer_id)  
            answer.checked=true
        })
      }
    if(this.currentObject.category_name == 'Taxonomy Compliance'){
      this.currentQuestion[0].answers.forEach((answer:any) =>{
        if(!this.questionnaireForm.get('question_'+question.question_id+'_'+answer.answer_id)){
          this.questionnaireForm.addControl('question_'+question.question_id+'_'+answer.answer_id, new FormControl('', Validators.required))
        }
      });
      }
    })
  }

  getAnswersOfQuestionnaire(){
    this.questionnaireService.getAnswersOfQuestionnaire(this.data.propertyId).subscribe((res:any) =>{
      this.answersOfQuestionnaire = res;
      this.initilizeForm();
      this.mapThePreselectedAnswers();
      this.bottomNavigationStatus();
      //this.categoryStatusMapping();
      this.questionnairesList.forEach((element:any) => {
        this.menuIconVisibility(element);
      });
    });
  }

  bottomNavigationStatus(){
    if(this.currentAnswersCount !== this.currentQuestion.length)
      this.nextBtnStatus = true;
    else
      this.nextBtnStatus = false;        
  }

  bottonNavigationLeft(){
    let currentIndex = this.questionnairesList.findIndex((item:any)=> item.category_id == this.currentObject.category_id);   
    if(!this.questionnairesList[currentIndex - 1])  
      this.previousBtnStatus = true
    else
      this.previousBtnStatus = false
  }

  mapThePreselectedAnswers(){
    let answersCount = 0;
    this.currentAnswersCount = 0
    this.answersOfQuestionnaire.questionnaires.forEach((answerObj:any) =>{
      this.currentQuestion.forEach((question:any) =>{
        question.answers.forEach((answer:any) =>{
          if(question.question_id == answerObj.question_id){
            if(question.question_code !== '1 TAXONOMY COMPLIANCE'){
              if(answerObj.answer_id == answer.answer_id){
                answer.checked = true;
                answersCount++
                this.currentAnswersCount = answersCount;
              }
              else
                answer.checked = false
            }
            else{
              if(answerObj.answer_id == answer.answer_id)
                answer.checked = true;

            }

          }
          if(answerObj.answer_id == answer.answer_id && question.question_id == answerObj.question_id && question.control_type == 'input')
            answer.inputAnswer = answerObj.answer
        })
      })
    });       
  }

  menuIconVisibility(category:any){
    this.projectService.currentProjectData.subscribe((res:any)=>{
      if(res.project_role !== 'Admin' || res.project_role !=='SuperAdmin' && this.data.actionText == 'Reviewed')
        category.questions.forEach((question:any) =>{
          this.answersOfQuestionnaire.questionnaires.forEach((answerObj:any) =>{
            if(question.question_id ==answerObj.question_id && answerObj.resolved_status == false){
              question.menu_icon = true;
              question.admin_answer = question.answers.find((item:any)=>item.answer_id == answerObj.admin_answer_id)
            }
          });
        });
  });
    console.log(this.currentQuestion, "Icon visisbility")
    this.categoryStatusMapping()
  }
  
  categoryStatusMapping(){    
    this.questionnairesList.forEach((category:any)=>{
      let answersForCategory= this.answersOfQuestionnaire.questionnaires.filter((ans:any) => ans.category_id == category.category_id)
      // if(category.questions.length == answersForCategory.length)
      //   category.status = "completed";
      category.questions.forEach((q:any) => {
        if(answersForCategory.find((ans:any) => q.question_id == ans.question_id))
          category.status = "completed";
        else
          category.status = "";
      })
      let questionWithConflicts = category.questions.filter((item:any)=> item.menu_icon == true)
        category.conflictNo = questionWithConflicts.length
    });
  }

  categoryStatusMappingFormLevel(){
    let transformObject = Object.entries(this.questionnaireForm.value).map(([key, value]:any) => ({
      question_id:parseInt(key.split('question_')[1]) , 
      answer_id:parseInt(value.answer_id),
      category_id: value.category_id,
      property_id : this.data.propertyId
    }));
    this.questionnairesList.forEach((category:any)=>{
      let answersForCategory= transformObject.filter((ans:any) => ans.category_id == category.category_id)
      if(category.questions.length == answersForCategory.length)
        category.status = "completed";
    });
  }
  categoryClick(event:any){
    this.filterQuestionnaire(parseInt(event.currentTarget.id));
    this.questionnairesList.forEach((element:any) => {
      if(element.category_id == event.currentTarget.id){
        element.selectedItem = true;
      }else{
        element.selectedItem = false;
      }
      if(element.category_id == event.currentTarget.id){
        element.visited = true;
      }
    });
    //this.currentQuestion[0].answers.filter((item:any) => item.checked == true );
    this.currentQuestion = this.currentObject.questions;
    this.mapThePreselectedAnswers();    
    this.initilizeForm();
    this.categoryStatusMappingFormLevel();    
    this.updatePosition();
    this.saveFormData(false);
  }

  filterQuestionnaire(id:Number){
     this.currentObject =  this.questionnairesList.filter(function (fil: any){
      return fil.category_id == id;
    })[0];
  }

  selectDate(event: any){
    if(event.source.checked){
      this.selectedDate = event.value;
    } else{
      this.selectedDate = null;
    }
  }

  // showQuestion(){
  //   this.currentQuestion = [];
  //   if(parseInt(this.selectedDate) <= 2020){
  //     this.questionnairesList.forEach((cat:any)=>{
  //       cat.questions = cat.questions.filter((fil: any) =>fil.date_of_building <= parseInt(this.selectedDate))
  //     })
  //   }    
  //   if(parseInt(this.selectedDate) >= 2021){
  //     this.questionnairesList.forEach((cat:any)=>{
  //       cat.questions = cat.questions.filter((fil: any) =>fil.date_of_building >= parseInt(this.selectedDate))
  //     })
  //   }
  //   this.questionnairesList[0].questions.splice(1,1);
  //   this.questionnaireService.allQuestionnaireList.next(JSON.stringify(this.questionnairesList));
  //   if(this.data.euQuestionnaireDate)
  //     this.selectedDate = this.data.euQuestionnaireDate;
  //   let payload: any = {};
  //   payload.property_id = this.data.propertyId;
  //   payload.eu_questionnaire_date = parseInt(this.selectedDate);
  //   if(!this.data.euQuestionnaireDate)
  //     this.propertiesListing.updateProperty(payload).subscribe(res=>{ });
  //   this.displayDateFlag = true;
  //   this.selectedDateFlag = true;
  //   this.currentObject = this.questionnairesList[0];
  //   let firstCategory = document.getElementsByClassName('prog-label')[0] as HTMLElement;
  //   firstCategory.click();
  // }

  patchFormValue(event:any, id:any, answerId?:any, event2?:any){    
    let hasAnswer = this.answersOfQuestionnaire.questionnaires.find((item:any) => id == item.question_id)
    if(this.currentAnswersCount < this.currentQuestion.length && !hasAnswer && !this.questionnaireForm.get('question_'+id)?.value)
      this.currentAnswersCount++;
    this.bottomNavigationStatus();
    var tempAnswer;
    //if(this.answersOfQuestionnaire.questionnaires.length && this.data.review_status == 'UnderReview')
    if(this.data.review_status)
      tempAnswer=  this.answersOfQuestionnaire.questionnaires.find((item:any)=> item.question_id == id).answer_id;   
    this.answersOfQuestionnaire.questionnaires.forEach((answerObj:any) =>{
      if(answerObj.question_id == id)
        answerObj.answer_id = parseInt(event.value);
    });
    if(answerId)
      this.questionnaireForm.get('question_'+id+'_'+answerId)?.patchValue({answer_id:event.source.value, category_id:this.currentObject.category_id});
    if((this.projectData.project_role == 'Admin' || this.projectData.project_role == 'SuperAdmin') && this.data.review_status == 'UnderReview'){           
      this.questionnaireForm.get('question_'+id)?.patchValue({answer_id:tempAnswer, admin_answer_id: event.value, category_id:this.currentObject.category_id});  
    }      
    else
      this.questionnaireForm.get('question_'+id)?.patchValue({answer_id:event.value, category_id:this.currentObject.category_id});
    if(event2)
      this.questionnaireForm.get('question_'+id)?.patchValue({answer_id:answerId, category_id:this.currentObject.category_id,
        answer:event2.target.value});
    this.questionnaireForm.get('question_'+id)?.markAsDirty();
  }  

  showNotification(){
    this._snackBar.open('Questionnaire Saved Successfully', 'Success', {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: ['success']
    });
}

  saveFormData(dialogClose:boolean){
    let transformObject;
    if((this.projectData.project_role == 'Admin' || this.projectData.project_role == 'SuperAdmin') && this.data.review_status == 'UnderReview'){
      transformObject = Object.entries(this.questionnaireForm.value).map(([key, value]:any) => ({
        question_id:parseInt(key.split('question_')[1]) , 
        answer_id:parseInt(value.answer_id),
        answer:value.answer,
        admin_answer_id: parseInt(value.admin_answer_id),
        resolved_status:false,
        category_id: value.category_id,
        property_id : this.data.propertyId
      }));
    }else{
      if(!this.data.review_status){
        transformObject = Object.entries(this.questionnaireForm.value).map(([key, value]:any) => ({
          question_id:parseInt(key.split('question_')[1]) , 
          answer_id:parseInt(value.answer_id),
          answer:value.answer,
          category_id: value.category_id,
          property_id : this.data.propertyId    
        })); 
      }else{
        transformObject = Object.entries(this.questionnaireForm.value).map(([key, value]:any) => ({
          question_id:parseInt(key.split('question_')[1]) , 
          answer_id:parseInt(value.answer_id),
          answer:value.answer,
          admin_answer_id: parseInt(value.admin_answer_id),
          category_id: value.category_id,
          property_id : this.data.propertyId,
          resolved_status: value.resolved_status? true : false
        }));      
      }
    }    

    transformObject.forEach((item:any)=>{
      if(item.answer_id){
        this.finalSaveObject.push(item)
      }    
    })
    //remove duplicate questionnaire
    let filteredArray = this.finalSaveObject.filter((v: { question_id: any; },i: any,a: any[])=>a.findIndex(t=>(t.question_id===v.question_id))===i)
   
    this.saveAnsweredQuestions({body:filteredArray, category_type:this.data.categoryType,
      property_type: this.data.propertyType}, dialogClose);
    
  }

  saveAnsweredQuestions(data:any, dialogFlag:boolean){
    this.questionnaireService.saveAnsweredQuestionnaire(data).subscribe((res:any) => {
    //  this.showNotification();
    this.getAnswersOfQuestionnaire();
    if(dialogFlag)
      this.closeFormData();
      this.propertiesListing.saveQuestionnaire.next(true);
    })
  }

  closeFormData(){
    this.quesDialogRef.close();
    this.selectdialogRef.close();
  }

  disableQuestionnaireForm(){
    if(this.data.questionnaire_status == 'Submitted' || this.userRoleAccess?.user?.role == 'Viewer'){
      this.disableFullForm = true;
    }
    this.projectService.currentProjectData.subscribe((res:any)=>{
      if(res.project_role == 'Viewer')
        this.disableFullForm = true;
      if(res.project_role == 'Contributor' && this.data.review_status == "UnderReview")
        this.disableFullForm = true;
      if(res.archive_status)
        this.disableFullForm = true;
    })
    if(this.data.review_status == "Reviewed")
      this.disableFullForm = true;
    

    /*if(this.userRoleAccess?.user?.role == 'Admin' || this.userRoleAccess?.user?.role == 'SuperAdmin')
      this.disableFullForm = false;*/
  }

  getUserDetails(){
    this.userService.currentUserDetails.subscribe((res:any) =>{
      this.userRoleAccess = res;            
    });
  }

  approveQuestionnaire(qId:any, adminAnswer:any){
    this.questionnaireForm.get('question_'+qId)?.patchValue({answer_id:adminAnswer.answer_id,admin_answer_id:adminAnswer.answer_id,  category_id:this.currentObject.category_id, resolved_status:true});
    this.answersOfQuestionnaire.questionnaires.forEach((answerObj:any) =>{
      if(answerObj.question_id == qId){
        answerObj.answer_id = parseInt(adminAnswer.answer_id);
        answerObj.resolved_status  = true;
      }
    });
    this.mapThePreselectedAnswers();
  }

  revertQuestionnaire(qId:any, adminAnswer:any){       
    let tempAnswer=  this.answersOfQuestionnaire.questionnaires.find((item:any)=> item.question_id == qId).answer_id;   
    this.questionnaireForm.get('question_'+qId)?.patchValue({answer_id: tempAnswer,admin_answer_id:adminAnswer.answer_id,  category_id:this.currentObject.category_id, resolved_status:true});
    this.answersOfQuestionnaire.questionnaires.forEach((answerObj:any) =>{
      if(answerObj.question_id == qId){
        answerObj.resolved_status  = true;
      }
    });
  }

  nextCategoryAction(){
    let indexNum:number = 0;
    this.questionnairesList.forEach((element:any, index:number)=>{
       if(element.category_id == this.currentObject.category_id && this.questionnairesList[index +1]){
         this.currentObject = this.questionnairesList[index +1].questions;
         indexNum = index;
       } 
    });

    this.currentObject = this.questionnairesList[indexNum +1];
    this.currentQuestion = this.currentObject.questions;
    this.questionnairesList.forEach((element:any) => {
      if(element.category_id == this.currentObject.category_id){
        element.selectedItem = true;
      }else{
        element.selectedItem = false;
      }
    });  
    
    this.mapThePreselectedAnswers();
    this.bottonNavigationLeft()
    this.bottomNavigationStatus();
    this.saveFormData(false)
  }

  previousCategoryAction(){
    let indexNum:number = 0;
    this.questionnairesList.forEach((element:any, index:number)=>{
       if(element.category_id == this.currentObject.category_id && this.questionnairesList[index - 1]){
         this.currentObject = this.questionnairesList[index - 1].questions;
         indexNum = index;
       }
    });
    if(this.questionnairesList[indexNum - 1]){
      this.currentObject = this.questionnairesList[indexNum - 1];
      this.currentQuestion = this.currentObject.questions;
      this.questionnairesList.forEach((element:any) => {
        if(element.category_id == this.currentObject.category_id){
          element.selectedItem = true;
        }else{
          element.selectedItem = false;
        }
      }); 
    }
    this.mapThePreselectedAnswers();
    this.bottomNavigationStatus();
    this.bottonNavigationLeft()
  }
} 