import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class QuestionnairesService {

  constructor(private http: HttpClient) { }
  public allQuestionnaireList = new BehaviorSubject('');
  getAllQuestionnaires(category: any, propertType:any, qStatus:boolean){
    return this.http.get(`${environment.apiUrl}/questionnaire?type=${category}&category_type=${propertType}&questionStatus=${qStatus}`);
  }

  getAllSubmittedQuestionnaires(propertyId: any, categoryType:any,propertType:any){
    return this.http.get(`${environment.apiUrl}/questionnaire/submitted?property_id=${propertyId}&category_type=${categoryType}&property_type=${propertType}`);
  }

  getAnswersOfQuestionnaire(id:any){
    return this.http.get(`${environment.apiUrl}/questionnaire/${id}`)
  }
  
  updateQuestionnaire(data:any){
    return this.http.put(`${environment.apiUrl}/questionnaire`, data)
  }

  createNewQuestionnaire(data:any){
    return this.http.post(`${environment.apiUrl}/questionnaire`, data);
  }

  saveAnsweredQuestionnaire(data:any){
    return this.http.post(`${environment.apiUrl}/questionnaire/save`, data);
  }

  flushQuestionnaireAnswers(data:any){
    return this.http.post(`${environment.apiUrl}/questionnaire/flush`, data)
  }
}