import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressIndicationComponent } from './components/progress-indication/progress-indication.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { MaterialModule } from './material/material.module';
import { QuestionnaireDialogComponent } from './components/questionnaire-dialog/questionnaire-dialog.component';
import { DeleteAlertComponent } from './components/delete-alert/delete-alert.component';
import { SelectQuestionnaireDialogComponent } from './components/select-questionnaire-dialog/select-questionnaire-dialog.component';
import { QuestionnaireEditDialogComponent } from './components/questionnaire-edit-dialog/questionnaire-edit-dialog.component';
import { DuplicateDialogComponent } from './components/duplicate-dialog/duplicate-dialog.component';
import { DuplicateEditDialogComponent } from './components/duplicate-edit-dialog/duplicate-edit-dialog.component';
import { OrderByPipe } from './pipes/order-by.pipe';
import { ImportAlertComponent } from './components/import-alert/import-alert.component';
import { QuestionnaireDialogEuComponent } from './components/questionnaire-dialog-eu/questionnaire-dialog-eu.component';

@NgModule({
  declarations: [
    ProgressIndicationComponent,
    LoadingIndicatorComponent,
    QuestionnaireDialogComponent,
    DeleteAlertComponent,
    SelectQuestionnaireDialogComponent,
    QuestionnaireEditDialogComponent,
    DuplicateDialogComponent,
    DuplicateEditDialogComponent,
    OrderByPipe,
    ImportAlertComponent,
    QuestionnaireDialogEuComponent
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    ProgressIndicationComponent,
    LoadingIndicatorComponent,
    SelectQuestionnaireDialogComponent,
    QuestionnaireDialogComponent,
    MaterialModule
  ],
  entryComponents: [
    
  ],
  schemas: [ NO_ERRORS_SCHEMA]
})
export class SharedModule { }
