import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { DatePipe } from '@angular/common';
import { PropertiesListingService } from './properties-listing.service';
import { Subscription } from 'rxjs';
@Injectable({
  providedIn: 'root'
})

@Injectable({
  providedIn: 'root'
})
export class DownloadEuResultsService {

  tableDisplayData:any = [];
  sumOfCategories:any;
  portfolioTable:any= [];
  downloadSubscription:Subscription | undefined;
  constructor(private datePipe: DatePipe, private propertyListing: PropertiesListingService) {
  }
  generateExcel(dataUrl:any) {    
    const title = 'Results of properties';
    const header = ["Project",	"Year", "Property Type",	"Country",	"Asset",	"Category",	"Score"]
    //Create workbook and worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Results Data');
    //Add Row and formatting
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true }
    worksheet.addRow([]);
    let subTitleRow = worksheet.addRow(['Date : ' + this.datePipe.transform(new Date(), 'medium')])
    //Add Image
    let logo = workbook.addImage({
      base64: dataUrl,
      extension: 'png',
    });
    worksheet.addImage(logo, 'I5:S34');
    worksheet.mergeCells('A1:D2');    
    worksheet.addRow([]);
    this.portfolioTable.forEach((d:any) => {
      worksheet.addRow(d);
    });

    // let avgRes = worksheet.addRow(['Average Result', this.sumOfCategories])
    // avgRes.font= {size: 12, bold: true };
    worksheet.addRow([]);
    let allPropTitle = worksheet.addRow(['Selected properties Results']);
    allPropTitle.font = {size: 14, bold: true };
    let headerRow = worksheet.addRow(header);    
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })
    // worksheet.addRows(data);
    // Add Data and Conditional Formatting
    this.tableDisplayData.forEach((d:any) => {
      let row = worksheet.addRow(d);
      let qty:any = row.getCell(5);
      let color = 'FF99FF99';
      if (+qty.value < 500) {
        color = 'FF9999'
      }
      qty.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color }
      }
      let score:any = row.getCell(7);
      let colored = 'FF99FF99'
      if (+score.value < 25) {
        colored = 'E0301E'
      }
      score.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: colored }
      }
    }
    );
    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 30;
    worksheet.addRow([]);
    //Footer Row
    let footerRow = worksheet.addRow(['This is system generated excel sheet.']);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFCCFFE5' }
    };
    footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    //Merge Cells
    worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);
    //Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Results.xlsx');
    });
    this.downloadSubscription?.unsubscribe();
  }

  getResultsDataToDownload(dataUrl:any, propertySelect:any, projectName:any){
    this.downloadSubscription = this.propertyListing.propertResultsForEU.subscribe((res:any) => {
      this.tableDisplayData = []
      let resultData = JSON.parse(res);
      console.log(resultData, "propertResultsForEU")
      let singleItem:any= [];
      if(propertySelect){
        resultData.properties.propertyResult = resultData.properties.propertyResult.filter((el:any) => {
          return propertySelect.some((f:any) => {
            return f.asset_reference_name === el.asset_reference_name;
          });
        });
      }
      resultData.properties.propertyResult.forEach((item:any) =>{
        item.results.forEach((res:any, index:number) =>{
          singleItem = [projectName,res.year_of_submission, res.property_type, res.country, item.asset_reference_name,res.category_name, res.result]        
          this.tableDisplayData.push(singleItem);
          if(item.results.length == index + 1){
            singleItem = [projectName,res.year_of_submission, res.property_type, res.country, item.asset_reference_name, 'All Categories', item.sumOfCategories]     
            this.tableDisplayData.push(singleItem);
          }
        });        
      });
      this.generateExcel(dataUrl)
      
    })
  }

  getPortfolioResults(dataUrl:any){
    this.propertyListing.portfolioResults.subscribe((res:any) =>{      
      let pRes = JSON.parse(res);
      this.sumOfCategories = pRes.sumOfCategories;
      if(this.portfolioTable.length == 0){
        pRes.averageResults.forEach((item:any)=>{
          this.portfolioTable.push([item.category_name, item.total_results]);
        })
      }
    });
    console.log(this.portfolioTable, "portfolioTable");
    
  }
}
