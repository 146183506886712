import { NgModule, CUSTOM_ELEMENTS_SCHEMA , NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddPropertiesComponent } from './add-properties/add-properties.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';
import { MaterialModule } from '../shared/material/material.module';
import { ProjectDashboardComponent } from './admin/project-dashboard/project-dashboard.component';
import { PropertyResultsComponent } from './property-results/property-results.component';
import { NgChartsModule } from 'ng2-charts';
import { CoreModule } from '../core/core.module';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { AdminResultsComponent } from './admin-results/admin-results.component';
import { AdminProjectResultsVisibilityComponent } from './admin/admin-project-results-visibility/admin-project-results-visibility.component';
import { CreateProjectComponent } from './admin/create-project/create-project.component';
import { EditProjectComponent } from './admin/edit-project/edit-project.component';
import { EditUsersComponent } from './admin/edit-users/edit-users.component';
import { ReopenWarningComponent } from './admin/reopen-warning/reopen-warning.component';
import { ProjectArchiveComponent } from './admin/project-archive/project-archive.component';
@NgModule({
  declarations: [
    AddPropertiesComponent,
    ProjectDashboardComponent,
    PropertyResultsComponent,
    AdminResultsComponent,
    AdminProjectResultsVisibilityComponent,
    CreateProjectComponent,
    EditProjectComponent,
    EditUsersComponent,
    ReopenWarningComponent,
    ProjectArchiveComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    CoreModule,
    NgxPaginationModule,
    MaterialModule,
    NgChartsModule,
    FormsModule             
  ],
  exports: [
    AddPropertiesComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ,NO_ERRORS_SCHEMA],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },      
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, 
      useValue: {duration: 5000}
    }
  ]
})
export class ViewsModule { }
